import React, { useContext, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  IoHomeOutline,
  IoCalendarClearOutline,
  IoSettingsOutline,
  IoPersonOutline,
  IoLogOutOutline,
} from 'react-icons/io5';
import styles from './Sidebar.module.css';
import { AuthContext } from '../contexts/AuthContext';
import { useSidebar } from '../contexts/SidebarContext';
import { trackNavigation, trackInteraction } from '../utils/analytics';

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useContext(AuthContext);
  const { shouldAnimateSidebar, disableAnimation } = useSidebar();
  const sidebarRef = useRef(null);

  useEffect(() => {
    if (shouldAnimateSidebar && sidebarRef.current) {
      sidebarRef.current.classList.add(styles.sidebarAnimate);
      disableAnimation();
    }
  }, [shouldAnimateSidebar, disableAnimation]);

  const navItems = [
    { 
      path: '/home', 
      icon: <IoHomeOutline />,
      text: 'Home',
      ariaLabel: 'Navigate to Home'
    },
    { 
      path: '/timetable', 
      icon: <IoCalendarClearOutline />,
      text: 'Timetable',
      ariaLabel: 'Navigate to Timetable'
    },
    { 
      path: '/settings', 
      icon: <IoSettingsOutline />,
      text: 'Settings',
      ariaLabel: 'Navigate to Settings'
    },
    { 
      path: '/profile', 
      icon: <IoPersonOutline />,
      text: 'Profile',
      ariaLabel: 'Navigate to Profile'
    },
  ];

  const handleNavigation = (path) => {
    trackNavigation.pageView(path); // Call the correct method to track navigation
    navigate(path);
  };

  const handleLogout = async () => {
    try {
      trackInteraction.buttonClick('Sidebar: Logout');
      await logout();
      window.location.href = '/';
    } catch (error) {
      console.error('Logout failed:', error);
      window.location.href = '/';
    }
  };

  return (
    <aside ref={sidebarRef} className={styles.sidebar}>
      <div className={styles.sidebarHeader}>
        <img 
          src="/2Slogo.png" 
          alt="Two Stopper Logo" 
          className={styles.logoIcon} 
        />
        <div className={styles.titleContainer}>
          <h1 className={styles.title}>
            Two Stopper
            <span className={styles.betaTag}>BETA</span>
          </h1>
          <p className={styles.slogan}>Your journey planner</p>
        </div>
      </div>

      <nav className={styles.navigation}>
        {navItems.map((item) => (
          <button
            key={item.path}
            className={`${styles.navItem} ${location.pathname === item.path ? styles.active : ''}`}
            onClick={() => handleNavigation(item.path)}
            aria-label={item.ariaLabel}
            aria-current={location.pathname === item.path ? 'page' : undefined}
          >
            {item.icon}
            <span>{item.text}</span>
          </button>
        ))}
      </nav>

      <button 
        className={styles.logoutButton}
        onClick={handleLogout}
        aria-label="Logout from application"
      >
        <IoLogOutOutline />
        <span>Logout</span>
      </button>
    </aside>
  );
}

export default Sidebar;
