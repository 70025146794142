import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FaBus } from 'react-icons/fa';
import PublicFooter from '../components/PublicFooter';
import PublicHero from '../components/PublicHero';
import styles from './PublicPricing.module.css';
import animations from '../styles/animations.module.css';
import classNames from 'classnames';

function PublicPricing() {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "PriceSpecification",
    "name": "Two Stopper Pricing Plans",
    "description": "Subscription plans for Two Stopper bus tracking app",
    "publisher": {
      "@type": "Organization",
      "name": "Two Stopper",
      "url": "https://twostopper.co.uk"
    },
    "offers": {
      "@type": "Offer",
      "priceCurrency": "GBP",
      "price": "2.99",
      "description": "Monthly subscription to Two Stopper bus tracking service",
      "availability": "https://schema.org/InStock"
    },
    "dateModified": "2025-01-12T18:15:54Z"
  };

  const bristolZonePrices = [
    { type: "Single", price: "£2.16", notes: "Valid for one journey" },
    { type: "Day", price: "£5.90", notes: "Valid 4:30am to 4:29am next day" },
    { type: "Week", price: "£23.85", notes: "7 consecutive days" },
    { type: "Month", price: "£82.80", notes: "Valid for calendar month" },
    { type: "Uni Year", price: "£460", notes: "14 Sept 2024 - 25 May 2025" }
  ];

  const westOfEnglandPrices = [
    { type: "Single", price: "£2.70 - £3.00", notes: "Based on distance" },
    { type: "Day", price: "£6.30", notes: "Valid 4:30am to 4:29am next day" },
    { type: "Week", price: "£27.00", notes: "7 consecutive days" },
    { type: "Month", price: "£85.50", notes: "Valid for calendar month" },
    { type: "Uni Year", price: "£515", notes: "14 Sept 2024 - 25 May 2025" }
  ];

  return (
    <div className={styles.pageContainer}>
      <Helmet>
        <title>Two Stopper Pricing | Bristol Student Bus App Subscription Plans</title>
        <meta name="description" content="View Two Stopper's affordable pricing plans for Bristol students. Get real-time bus tracking and journey planning features at student-friendly rates." />
        <meta name="keywords" content="two stopper pricing, bristol bus app cost, student transport subscription, bus tracking app plans" />
        <link rel="canonical" href="https://twostopper.co.uk/pricing" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <PublicHero 
        title="Bristol Bus Prices"
        description="Two Stopper is completely free for Bristol students. Here are the standard First Bus prices for reference."
      />

      <div className={styles.contentContainer}>
        <div className={classNames(styles.studentBanner, animations.fadeUp, animations.delay1)}>
          <FaBus className={styles.busIcon} />
          <div className={styles.bannerContent}>
            <h2>Free for Bristol Students!</h2>
            <p>Two Stopper is completely free for all University of Bristol and UWE students. Just sign up with your university email to get started.</p>
          </div>
        </div>

        <div className={classNames(styles.priceSection, animations.fadeUp, animations.delay2)}>
          <h2>Bristol Zone Prices</h2>
          <p className={styles.zoneDescription}>Valid on all First Bus and metrobus services in Bristol*</p>
          <div className={styles.priceGrid}>
            {bristolZonePrices.map((ticket) => (
              <div key={ticket.type} className={styles.priceCard}>
                <div className={styles.priceHeader}>
                  <h3>{ticket.type}</h3>
                  <div className={styles.price}>{ticket.price}</div>
                </div>
                <p className={styles.priceNotes}>{ticket.notes}</p>
              </div>
            ))}
          </div>
          <p className={styles.priceFootnote}>*Excludes Airport Flyer services</p>
        </div>

        <div className={classNames(styles.priceSection, animations.fadeUp, animations.delay3)}>
          <h2>West of England Zone Prices</h2>
          <p className={styles.zoneDescription}>Valid on all First Bus and metrobus services in Bristol, Bath, and surrounding areas*</p>
          <div className={styles.priceGrid}>
            {westOfEnglandPrices.map((ticket) => (
              <div key={ticket.type} className={styles.priceCard}>
                <div className={styles.priceHeader}>
                  <h3>{ticket.type}</h3>
                  <div className={styles.price}>{ticket.price}</div>
                </div>
                <p className={styles.priceNotes}>{ticket.notes}</p>
              </div>
            ))}
          </div>
          <p className={styles.priceFootnote}>*Excludes Airport Flyer services</p>
        </div>

        <div className={classNames(styles.infoSection, animations.fadeUp, animations.delay4)}>
          <h2>Additional Information</h2>
          <ul className={styles.infoList}>
            <li>All prices shown are standard First Bus fares</li>
            <li>Student ID required for student discounts</li>
            <li>Prices may vary during special events</li>
            <li>Night bus services may have different fares</li>
          </ul>
        </div>
      </div>

      <PublicFooter />
    </div>
  );
}

export default PublicPricing;
