import { toast, Slide } from 'react-toastify';
import '../styles/toast.module.css';

const DEFAULT_DURATION = 3000;

export const toastConfig = {
  position: "top-right",
  autoClose: DEFAULT_DURATION,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored',
  transition: Slide
};

export const showToast = {
  success: (message) => {
    toast.success(message, toastConfig);
  },
  error: (message) => {
    toast.error(message, toastConfig);
  },
  info: (message) => {
    toast.info(message, toastConfig);
  },
  warning: (message) => {
    toast.warning(message, toastConfig);
  },
};

// Helper function to handle API errors
export const handleApiError = (error) => {
  const message = error.response?.data?.message || error.message || 'An error occurred';
  showToast.error(message);
  return message;
};
