import React from 'react';
import { Helmet } from 'react-helmet-async';
import styles from './Cookies.module.css';
import PublicNavbar from '../../components/PublicNavbar';
import PublicFooter from '../../components/PublicFooter';

export default function Cookies() {
  return (
    <div className={styles.pageContainer}>
      <Helmet>
        <title>Cookie Policy - Two Stopper</title>
        <meta name="description" content="Learn about how Two Stopper uses cookies and similar technologies." />
      </Helmet>

      <PublicNavbar />

      <div className={styles.cookiesHero}>
        <div className={styles.cookiesHeroContent}>
          <h1 className={styles.cookiesHeroTitle}>Cookie Policy</h1>
          <p className={styles.cookiesHeroSubtitle}>
            Learn about how we use cookies and similar technologies
          </p>
        </div>
      </div>

      <main className={styles.mainContent}>
        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>What Are Cookies?</h2>
          <div className={styles.sectionContent}>
            <p>Cookies are small text files that help us:</p>
            <ul>
              <li>Remember your preferences</li>
              <li>Keep you logged in</li>
              <li>Improve app performance</li>
            </ul>
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>Types of Cookies We Use</h2>
          <div className={styles.sectionContent}>
            <p>We use different types of cookies:</p>
            <ul>
              <li>Essential cookies for app functionality</li>
              <li>Analytics cookies to improve service</li>
              <li>Preference cookies to remember your settings</li>
            </ul>
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>Managing Cookies</h2>
          <div className={styles.sectionContent}>
            <p>You can manage cookies by:</p>
            <ul>
              <li>Adjusting browser settings</li>
              <li>Using our cookie preferences center</li>
              <li>Contacting our support team</li>
            </ul>
          </div>
        </section>

        <p className={styles.lastUpdated}>Last updated: January 12, 2025</p>
      </main>

      <PublicFooter />
    </div>
  );
}
