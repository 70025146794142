import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { FaEnvelope, FaLock, FaEye, FaEyeSlash, FaArrowLeft, FaCheck, FaTimes } from 'react-icons/fa';
import 'react-phone-input-2/lib/style.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { toast } from 'react-toastify';
import axiosInstance from '../utils/axiosInstance';
import styles from './ForgotPassword.module.css';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [phoneValid, setPhoneValid] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({ phoneNumber: '', password: '' });
  const [activeField, setActiveField] = useState('');

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    } else {
      navigate('/login');
    }
  };

  const handleEmailPhoneSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const formattedPhone = phoneNumber.replace(/\D/g, '');
      const response = await axiosInstance.post('/api/user/send-reset-code', {
        email,
        phoneNumber: formattedPhone
      });

      if (response.data.success) {
        toast.success('Verification code sent to your phone');
        setStep(2);
      }
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Failed to send verification code';
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCodeVerification = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const formattedPhone = phoneNumber.replace(/\D/g, '');
      const code = verificationCode.join('');
      const response = await axiosInstance.post('/api/user/verify-reset-code', {
        email,
        phoneNumber: formattedPhone,
        code
      });

      if (response.data.success) {
        toast.success('Code verified successfully');
        setStep(3);
      }
    } catch (err) {
      const errorMessage = err.response?.data?.msg || 'Invalid verification code';
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setError('');

    if (!newPassword || newPassword.length < 8) {
      setError('Password must be at least 8 characters long');
      toast.error('Password must be at least 8 characters long');
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      toast.error('Passwords do not match');
      return;
    }

    setIsLoading(true);
    try {
      const formattedPhone = phoneNumber.replace(/\D/g, '');
      const response = await axiosInstance.post('/api/user/reset-password', {
        email,
        phoneNumber: formattedPhone,
        verificationCode: verificationCode.join(''),
        newPassword
      });

      if (response.data.success) {
        toast.success('Password reset successfully');
        navigate('/login');
      }
    } catch (err) {
      const errorMessage = err.response?.data?.msg || 'Failed to reset password';
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerificationCodeChange = (index, value) => {
    if (value.length > 1) value = value.slice(-1);
    if (!/^\d*$/.test(value)) return;

    const newCode = [...verificationCode];
    newCode[index] = value;
    setVerificationCode(newCode);

    // Auto-focus next input
    if (value && index < 5) {
      const nextInput = document.querySelector(`input[name="code-${index + 1}"]`);
      if (nextInput) nextInput.focus();
    }
  };

  const handleVerificationCodeKeyDown = (index, e) => {
    if (e.key === 'Backspace' && !verificationCode[index] && index > 0) {
      const prevInput = document.querySelector(`input[name="code-${index - 1}"]`);
      if (prevInput) prevInput.focus();
    }
  };

  return (
    <div className={styles.forgot_password_container}>
      <div className={styles.forgot_password_box}>
        <button 
          className={styles.back_button} 
          onClick={handleBack}
          aria-label="Back"
        >
          <FaArrowLeft />
        </button>

        <div className={styles.logo}>
          <img src="/2Slogo.png" alt="Two Stopper Logo" style={{ width: '80px', height: 'auto' }} />
          <h2 className={styles.logo_title}>Two Stopper</h2>
          <div className={styles.slogan}>Reset Your Password</div>
        </div>

        <div className={styles.progress_steps}>
          <div className={`${styles.step} ${step >= 1 ? styles.active : ''}`}>1</div>
          <div className={`${styles.step_line} ${step >= 2 ? styles.active : ''}`}></div>
          <div className={`${styles.step} ${step >= 2 ? styles.active : ''}`}>2</div>
          <div className={`${styles.step_line} ${step >= 3 ? styles.active : ''}`}></div>
          <div className={`${styles.step} ${step >= 3 ? styles.active : ''}`}>3</div>
        </div>

        {error && <div className={styles.error_message}>{error}</div>}

        <div className={styles.step_wrapper}>
          <TransitionGroup>
            <CSSTransition
              key={step}
              timeout={400}
              classNames={{
                enter: styles.step_transition_enter,
                enterActive: styles.step_transition_enter_active,
                exit: styles.step_transition_exit,
                exitActive: styles.step_transition_exit_active
              }}
            >
              <div className={styles.step_content}>
                {step === 1 && (
                  <div className={styles.form_container}>
                    <form onSubmit={handleEmailPhoneSubmit}>
                      <div className={styles.input_group}>
                        <div className={styles.input_icon}>
                          <FaEnvelope />
                        </div>
                        <div className={styles.input_wrapper}>
                          <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            className={styles.input}
                            required
                          />
                        </div>
                      </div>

                      <div className={styles.input_group}>
                        <div className={styles.phone_input_container}>
                          <PhoneInput
                            country={'gb'}
                            onlyCountries={['gb']}
                            countryCodeEditable={false}
                            disableDropdown={true}
                            enableSearch={false}
                            placeholder="Enter UK mobile number"
                            value={phoneNumber}
                            onChange={phone => {
                              setPhoneNumber(phone);
                              const isValidWithCountryCode = /^44[7][0-9]{9}$/.test(phone);
                              const isValidWithoutCountryCode = /^[7][0-9]{9}$/.test(phone);
                              const isValid = isValidWithCountryCode || isValidWithoutCountryCode;
                              setPhoneValid(isValid);
                              if (isValid) {
                                setFieldErrors(prev => ({ ...prev, phoneNumber: '' }));
                              } else {
                                setFieldErrors(prev => ({
                                  ...prev,
                                  phoneNumber: 'Please enter a valid UK mobile number'
                                }));
                              }
                            }}
                            inputProps={{
                              name: 'phone',
                              required: true,
                              autoFocus: false,
                              className: styles.phone_input
                            }}
                          />
                          {fieldErrors.phoneNumber && 
                            <div className={`${styles.validation_message} ${styles.error}`}>
                              <FaTimes className={styles.validation_icon} />
                              {fieldErrors.phoneNumber}
                            </div>
                          }
                        </div>
                      </div>

                      <button 
                        type="submit" 
                        className={styles.submit_button} 
                        disabled={isLoading || !phoneValid || !email}
                      >
                        {isLoading ? 'Sending...' : 'Send Verification Code'}
                      </button>
                    </form>
                  </div>
                )}

                {step === 2 && (
                  <div className={styles.form_container}>
                    <form onSubmit={handleCodeVerification}>
                      <div className={styles.verification_code_container}>
                        {verificationCode.map((digit, index) => (
                          <input
                            key={index}
                            type="text"
                            name={`code-${index}`}
                            value={digit}
                            onChange={(e) => handleVerificationCodeChange(index, e.target.value)}
                            onKeyDown={(e) => handleVerificationCodeKeyDown(index, e)}
                            maxLength={1}
                            className={styles.verification_digit}
                            required
                          />
                        ))}
                      </div>

                      <button 
                        type="submit" 
                        className={styles.submit_button} 
                        disabled={isLoading || verificationCode.some(digit => !digit)}
                      >
                        {isLoading ? 'Verifying...' : 'Verify Code'}
                      </button>

                      <button 
                        type="button" 
                        className={styles.resend_button}
                        onClick={handleEmailPhoneSubmit}
                        disabled={isLoading}
                      >
                        Resend Code
                      </button>
                    </form>
                  </div>
                )}

                {step === 3 && (
                  <div className={styles.form_container}>
                    <form onSubmit={handlePasswordReset}>
                      <div className={styles.input_group}>
                        <div className={styles.input_icon}>
                          <FaLock />
                        </div>
                        <div className={`${styles.input_wrapper} ${styles.password_input}`}>
                          <input
                            type={showPassword ? 'text' : 'password'}
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            placeholder="New password (min. 8 characters)"
                            className={styles.input}
                            required
                            minLength={8}
                          />
                          <button
                            type="button"
                            className={styles.toggle_password}
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                          </button>
                        </div>
                      </div>

                      <div className={styles.input_group}>
                        <div className={styles.input_icon}>
                          <FaLock />
                        </div>
                        <div className={`${styles.input_wrapper} ${styles.password_input}`}>
                          <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            placeholder="Confirm new password"
                            className={styles.input}
                            required
                          />
                          <button
                            type="button"
                            className={styles.toggle_password}
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          >
                            {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                          </button>
                        </div>
                      </div>

                      <button 
                        type="submit" 
                        className={styles.submit_button} 
                        disabled={isLoading || !newPassword || !confirmPassword || newPassword.length < 8}
                      >
                        {isLoading ? 'Resetting...' : 'Reset Password'}
                      </button>
                    </form>
                  </div>
                )}
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
