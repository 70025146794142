import axios from 'axios';
import { cacheManager } from './cacheUtils';

// Get the API URL from environment variables, defaulting to the proxy in development
const baseURL = process.env.NODE_ENV === 'development' ? '' : process.env.REACT_APP_API_URL;

console.log('Environment:', process.env.NODE_ENV);
console.log('API URL:', baseURL || 'Using proxy configuration');

const axiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  withCredentials: true,
  timeout: 30000, // 30 second timeout
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Log the request details
    console.log('Making request to:', config.url, {
      method: config.method,
      data: config.data,
      headers: config.headers
    });

    // Add token to header if it exists
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Ensure API requests start with /api
    if (!config.url.startsWith('/api/')) {
      config.url = `/api${config.url}`;
    }

    return config;
  },
  (error) => {
    console.error('Request interceptor error:', error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    console.log('Response received:', {
      status: response.status,
      data: response.data,
      headers: response.headers
    });
    
    // Cache successful GET responses
    if (response.config.method.toLowerCase() === 'get') {
      cacheManager.set(response.config, response.data);
    }
    
    return response;
  },
  (error) => {
    console.error('Response error:', {
      message: error.message,
      code: error.code,
      response: error.response?.data,
      status: error.response?.status,
      config: {
        url: error.config?.url,
        method: error.config?.method,
        data: error.config?.data
      }
    });

    // Handle 406 errors by returning cached data if available
    if (error.response?.status === 406) {
      const cachedData = cacheManager.get(error.config);
      if (cachedData) {
        console.log('Using cached data due to 406 error');
        return Promise.resolve({ data: cachedData, fromCache: true });
      }
    }

    // Only handle 401 errors for non-login requests
    if (error.response?.status === 401 && !error.config.url.includes('/auth/login')) {
      localStorage.removeItem('token');
      window.location.href = '/login';
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
