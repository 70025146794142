import React, { useState, useEffect, useContext } from 'react';
import { FiSettings, FiBell, FiKey, FiDownload, FiLogOut, FiTrash2, FiLock, FiNavigation, FiClock } from 'react-icons/fi';
import { IoMdBus } from 'react-icons/io';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './Settings.module.css';
import { AuthContext } from './contexts/AuthContext';
import axiosInstance from './utils/axiosInstance';
import ScreenShatter from './components/ScreenShatter';
import LoadingScreen from './components/LoadingScreen';
import Logger from './utils/logger';
import PageTransition from './components/PageTransition';
import { useModal } from './contexts/ModalContext';
import GoodbyeMessage from './components/GoodbyeMessage';
import { useNavigate } from 'react-router-dom';
import { trackNavigation, trackFeature, trackError, trackInteraction } from './utils/analytics';

const Settings = () => {
  const navigate = useNavigate();
  const { showPasswordChange, showDeleteConfirmation } = useModal();
  const { user, toggleNotifications, updateJourneySettings, logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [passkeysSupported, setPasskeysSupported] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isIOS, setIsIOS] = useState(false);
  const [showDeletionSuccess, setShowDeletionSuccess] = useState(false);
  const [showShatterEffect, setShowShatterEffect] = useState(false);
  const [showGoodbyeMessage, setShowGoodbyeMessage] = useState(false);
  const [directBusesOnly, setDirectBusesOnly] = useState(false);
  const [prioritiseEarliestArrival, setPrioritiseEarliestArrival] = useState(true);
  const [useCurrentLocation, setUseCurrentLocation] = useState(false);

  useEffect(() => {
    trackNavigation.pageView('Settings');
    setPasskeysSupported('credentials' in navigator);
    setIsIOS(/iPad|iPhone|iPod/.test(navigator.userAgent));

    // Add event listener for PWA install prompt
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    });

    const loadSettings = async () => {
      try {
        const response = await axiosInstance.get('/api/settings');
        setDirectBusesOnly(response.data.directBusesOnly || false);
        setPrioritiseEarliestArrival(response.data.prioritiseEarliestArrival !== false);
        setUseCurrentLocation(response.data.useCurrentLocation || false);
        Logger.info('✅ Settings loaded successfully');
        trackFeature.settingsChange('Load Settings');
      } catch (error) {
        Logger.error('❌ Error loading settings:', error);
        trackError.apiError('settings/fetch', error.message);
        toast.error('Failed to load settings', {
          autoClose: 1500
        });
      } finally {
        setLoading(false);
      }
    };

    loadSettings();
  }, []);

  const handleNotificationsToggle = async () => {
    try {
      const success = await toggleNotifications();
      if (success) {
        Logger.info(`✅ Notifications ${!user?.notifications ? 'enabled' : 'disabled'}`);
        toast.success(`Notifications ${!user?.notifications ? 'enabled' : 'disabled'}`, {
          autoClose: 1500
        });
        trackFeature.settingsChange(`Notifications ${!user?.notifications ? 'Enabled' : 'Disabled'}`);
      } else {
        Logger.error('❌ Failed to update notifications');
        trackError.apiError('settings/notifications', 'Failed to update notifications');
        toast.error('Failed to update notifications', {
          autoClose: 1500
        });
      }
    } catch (error) {
      Logger.error('❌ Error updating settings:', error);
      trackError.apiError('settings/notifications', error.message);
      toast.error('Failed to update settings', {
        autoClose: 1500
      });
    }
  };

  const handleTestNotification = async () => {
    try {
      await axiosInstance.post('/api/user/test-notification');
      Logger.info('✅ Test notification sent');
      toast.success('Test notification sent', {
        autoClose: 1500
      });
      trackInteraction.buttonClick('Test Notification');
    } catch (error) {
      Logger.error('❌ Error sending test notification:', error);
      trackError.apiError('settings/test-notification', error.message);
      toast.error('Failed to send test notification', {
        autoClose: 1500
      });
    }
  };

  const handleInstallClick = async () => {
    if (isIOS) {
      Logger.info('📱 Tap the share button and select "Add to Home Screen"');
      toast.info('Tap the share button and select "Add to Home Screen"', {
        autoClose: 1500
      });
      return;
    }

    if (!deferredPrompt) {
      Logger.error('❌ App is already installed or not installable');
      trackError.apiError('settings/install', 'App is already installed or not installable');
      toast.info('App is already installed or not installable', {
        autoClose: 1500
      });
      return;
    }

    try {
      await deferredPrompt.prompt();
      const result = await deferredPrompt.userChoice;
      if (result.outcome === 'accepted') {
        Logger.info('✅ App installed successfully!');
        toast.success('App installed successfully!', {
          autoClose: 1500
        });
        trackFeature.settingsChange('App Installed');
      } else {
        Logger.error('❌ App installation cancelled');
        trackError.apiError('settings/install', 'App installation cancelled');
        toast.error('App installation cancelled', {
          autoClose: 1500
        });
      }
      setDeferredPrompt(null);
    } catch (error) {
      Logger.error('❌ Error installing app:', error);
      trackError.apiError('settings/install', error.message);
      toast.error('Failed to install app', {
        autoClose: 1500
      });
    }
  };

  const handleDeleteAccount = async () => {
    try {
      await axiosInstance.delete('/api/user/delete-account');
      Logger.info('✅ Account deleted successfully');
      setShowGoodbyeMessage(true);
      trackFeature.settingsChange('Account Deleted');
      
      // Delay logout and navigation to ensure goodbye message is shown
      setTimeout(() => {
        logout(); // Clear auth state
        navigate('/'); // Navigate to root instead of /landing
      }, 10000);
    } catch (error) {
      Logger.error('❌ Error deleting account:', error);
      trackError.apiError('settings/delete-account', error.message);
      toast.error('Failed to delete account', {
        autoClose: 1500
      });
    }
  };

  const handleSignOut = async () => {
    try {
      await axiosInstance.post('/api/auth/logout');
      localStorage.clear();
      Logger.info('✅ Signed out successfully');
      trackFeature.settingsChange('Signed Out');
      window.location.href = '/'; // Update to root path
    } catch (error) {
      Logger.error('❌ Error signing out:', error);
      trackError.apiError('settings/sign-out', error.message);
      toast.error('Failed to sign out', {
        autoClose: 1500
      });
    }
  };

  const handleJourneyPlannerSettings = async (setting) => {
    let updatedSettings = {};

    if (setting === 'directBuses') {
      updatedSettings = {
        directBuses: !directBusesOnly
      };
      setDirectBusesOnly(!directBusesOnly);
    } else if (setting === 'earliestArrival') {
      updatedSettings = {
        earliestArrival: !prioritiseEarliestArrival
      };
      setPrioritiseEarliestArrival(!prioritiseEarliestArrival);
    }

    try {
      const response = await axiosInstance.post('/api/settings/journey-planner', updatedSettings);
      Logger.info('✅ Journey planner settings updated successfully:', response.data);
      updateJourneySettings(response.data);
      toast.success('Settings updated successfully!', {
        autoClose: 1500
      });
      trackFeature.settingsChange(`Journey Planner ${setting} ${updatedSettings[setting] ? 'Enabled' : 'Disabled'}`);
    } catch (error) {
      Logger.error('❌ Error updating journey planner settings:', error);
      trackError.apiError('settings/journey-planner', error.message);
      toast.error('Failed to update settings', {
        autoClose: 1500
      });
    }
  };

  const handlePasswordChange = async (passwordData) => {
    try {
      await axiosInstance.post('/api/password/change-password-direct', passwordData);
      toast.success('Password changed successfully');
      trackFeature.settingsChange('Password Changed');
    } catch (error) {
      console.error('Error changing password:', error);
      trackError.apiError('settings/password-change', error.message);
      toast.error(error.response?.data?.message || 'Failed to change password');
      throw error; // This will be caught by the modal's error handling
    }
  };

  if (showGoodbyeMessage) {
    return <GoodbyeMessage />;
  }

  return (
    <PageTransition isAuth={true}>
      <div className={styles.container}>
        <div className={styles.main}>
          {/* Hero Section */}
          <section className={styles.hero}>
            <div className={styles.heroBackground} />
            <div className={styles.heroContent}>
              <div className={styles.welcomeGroup}>
                <h1 className={styles.title}>Settings<span className={styles.highlight}></span></h1>
                <p className={styles.subtitle}>
                  Customise your Two Stopper experience with personalised settings for notifications, security, and journey preferences
                </p>
              </div>
            </div>
          </section>

          <div className={styles.settingsContent}>
            {loading ? (
              <div className={styles.loadingScreen}>
                <LoadingScreen message="Loading settings..." />
              </div>
            ) : (
              <>
                <div className={styles.settingsGroup}>
                  <h2 className={styles.groupHeader}>Security</h2>
                  <div className={`${styles.settingItem} ${styles.disabled}`}>
                    <div className={styles.settingItemContent}>
                      <div className={styles.icon}>
                        <FiKey />
                      </div>
                      <div className={styles.itemDetails}>
                        <div className={styles.itemLabel}>Passkey Authentication</div>
                        <div className={styles.itemValue}>Currently unavailable</div>
                      </div>
                    </div>
                    <FiSettings className={styles.icon} />
                  </div>
                  <div className={styles.settingItem} onClick={() => showPasswordChange(handlePasswordChange)}>
                    <div className={styles.settingItemContent}>
                      <div className={styles.icon}>
                        <FiLock />
                      </div>
                      <div className={styles.itemDetails}>
                        <div className={styles.itemLabel}>Change Password</div>
                        <div className={styles.itemValue}>Update your account password</div>
                      </div>
                    </div>
                    <FiSettings className={styles.icon} />
                  </div>
                </div>

                <div className={styles.settingsGroup}>
                  <h2 className={styles.groupHeader}>Notifications</h2>
                  <div className={styles.settingItem}>
                    <div className={styles.settingItemContent}>
                      <div className={styles.icon}>
                        <FiBell />
                      </div>
                      <div className={styles.itemDetails}>
                        <div className={styles.itemLabel}>Push Notifications</div>
                        <div className={styles.itemValue}>Get notified about important updates</div>
                      </div>
                    </div>
                    <label className={styles.toggleSwitch}>
                      <input
                        type="checkbox"
                        checked={user?.notifications}
                        onChange={handleNotificationsToggle}
                        className={styles.toggleInput}
                      />
                      <span className={styles.toggleSlider}></span>
                    </label>
                  </div>
                  {user?.notifications && (
                    <div className={styles.settingItem} onClick={handleTestNotification}>
                      <div className={styles.settingItemContent}>
                        <div className={styles.icon}>
                          <FiBell />
                        </div>
                        <div className={styles.itemDetails}>
                          <div className={styles.itemLabel}>Test Notification</div>
                          <div className={styles.itemValue}>Send a test notification</div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className={styles.settingsGroup}>
                  <h2 className={styles.groupHeader}>Save Our Contact</h2>
                  <a href="/contact.vcf" download="contact.vcf" style={{ textDecoration: 'none' }}>
                    <div className={styles.settingItem}>
                      <div className={styles.settingItemContent}>
                        <div className={styles.icon}>
                          <FiDownload />
                        </div>
                        <div className={styles.itemDetails}>
                          <div className={styles.itemLabel}>Save Contact</div>
                          <div className={styles.itemValue}>Add our contact details to your address book</div>
                        </div>
                      </div>
                      <FiSettings className={styles.icon} />
                    </div>
                  </a>
                </div>

                <div className={styles.settingsGroup}>
                  <h2 className={styles.groupHeader}>Journey Planner</h2>
                  <div className={`${styles.settingItem} ${styles.disabled}`}>
                    <div className={styles.settingItemContent}>
                      <div className={styles.icon}>
                        <FiNavigation />
                      </div>
                      <div className={styles.itemDetails}>
                        <div className={styles.itemLabel}>Use Current Location</div>
                        <div className={styles.itemValue}>Currently unavailable</div>
                      </div>
                    </div>
                    <label className={styles.toggleSwitch}>
                      <input
                        type="checkbox"
                        disabled
                        checked={false}
                        className={styles.toggleInput}
                      />
                      <span className={styles.toggleSlider}></span>
                    </label>
                  </div>
                  <div className={styles.settingItem}>
                    <div className={styles.settingItemContent}>
                      <div className={styles.icon}>
                        <IoMdBus />
                      </div>
                      <div className={styles.itemDetails}>
                        <div className={styles.itemLabel}>Direct Buses Only</div>
                        <div className={styles.itemValue}>Only show journeys with no changes</div>
                      </div>
                    </div>
                    <label className={styles.toggleSwitch}>
                      <input
                        type="checkbox"
                        checked={directBusesOnly}
                        onChange={() => handleJourneyPlannerSettings('directBuses')}
                        className={styles.toggleInput}
                      />
                      <span className={styles.toggleSlider}></span>
                    </label>
                  </div>
                  <div className={styles.settingItem}>
                    <div className={styles.settingItemContent}>
                      <div className={styles.icon}>
                        <FiClock />
                      </div>
                      <div className={styles.itemDetails}>
                        <div className={styles.itemLabel}>Prioritise Earliest Arrival</div>
                        <div className={styles.itemValue}>Show journeys that get you there soonest</div>
                      </div>
                    </div>
                    <label className={styles.toggleSwitch}>
                      <input
                        type="checkbox"
                        checked={prioritiseEarliestArrival}
                        onChange={() => handleJourneyPlannerSettings('earliestArrival')}
                        className={styles.toggleInput}
                      />
                      <span className={styles.toggleSlider}></span>
                    </label>
                  </div>
                </div>

                <div className={styles.settingsGroup}>
                  <h2 className={styles.groupHeader}>Account</h2>
                  <div className={styles.settingItem} onClick={handleSignOut}>
                    <div className={styles.settingItemContent}>
                      <div className={styles.icon}>
                        <FiLogOut />
                      </div>
                      <div className={styles.itemDetails}>
                        <div className={styles.itemLabel}>Sign Out</div>
                        <div className={styles.itemValue}>Sign out of your account</div>
                      </div>
                    </div>
                    <FiSettings className={styles.icon} />
                  </div>
                </div>

                <div className={`${styles.settingsGroup} ${styles.dangerZone}`}>
                  <h2 className={styles.groupHeader}>Danger Zone</h2>
                  <div 
                    className={styles.settingItem} 
                    onClick={() => showDeleteConfirmation('account', handleDeleteAccount)}
                  >
                    <div className={styles.settingItemContent}>
                      <div className={styles.icon}>
                        <FiTrash2 />
                      </div>
                      <div className={styles.itemDetails}>
                        <div className={styles.itemLabel}>Delete Account</div>
                        <div className={styles.itemValue}>Permanently delete your account</div>
                      </div>
                    </div>
                    <FiSettings className={styles.icon} />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </PageTransition>
  );
};

export default Settings;