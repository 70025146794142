import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { FaRoute, FaBell, FaClock, FaMobile, FaArrowRight } from 'react-icons/fa';
import PublicFooter from './components/PublicFooter';
import { motion } from 'framer-motion';
import styles from './styles/ModernLandingPage.module.css';
import { trackNavigation, trackInteraction, trackFeature } from './utils/analytics';

function ModernLandingPage() {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);

  React.useEffect(() => {
    trackNavigation.pageView('Landing Page');
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fadeUpAnimation = {
    initial: { opacity: 0, y: 30 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.8, ease: [0.25, 0.1, 0, 1] }
  };

  const staggerChildren = {
    animate: {
      transition: {
        delayChildren: 0.4,
        staggerChildren: 0.1
      }
    }
  };

  const heroTextAnimation = {
    initial: { opacity: 0, y: 40 },
    animate: { opacity: 1, y: 0 },
    transition: {
      duration: 1,
      ease: [0.25, 0.1, 0, 1],
      delay: 0.2
    }
  };

  const buttonAnimation = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: {
      duration: 0.8,
      ease: [0.25, 0.1, 0, 1],
      delay: 0.6
    }
  };

  const featureAnimation = {
    initial: { opacity: 0, y: 40 },
    whileInView: { opacity: 1, y: 0 },
    viewport: { once: true },
    transition: {
      duration: 0.8,
      ease: [0.25, 0.1, 0, 1]
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebApplication",
    "name": "Two Stopper",
    "applicationCategory": "TransitApplication",
    "operatingSystem": "Web",
    "description": "Free real-time bus tracking app for Bristol students. Live First Bus updates, smart journey planning, and instant notifications for UWE and University of Bristol routes.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "GBP"
    },
    "audience": {
      "@type": "EducationalAudience",
      "educationalRole": "student"
    },
    "areaServed": {
      "@type": "City",
      "name": "Bristol",
      "containsPlace": [
        {
          "@type": "CollegeOrUniversity",
          "name": "University of Bristol",
          "address": {
            "@type": "PostalAddress",
            "addressLocality": "Bristol",
            "addressRegion": "Bristol",
            "addressCountry": "GB"
          }
        },
        {
          "@type": "CollegeOrUniversity",
          "name": "UWE Bristol",
          "address": {
            "@type": "PostalAddress",
            "addressLocality": "Bristol",
            "addressRegion": "Bristol",
            "addressCountry": "GB"
          }
        }
      ]
    },
    "featureList": [
      "Real-time bus tracking",
      "Smart journey planning",
      "Live notifications",
      "SMS updates"
    ],
    "browserRequirements": "Requires JavaScript. Requires HTML5.",
    "permissions": "geolocation",
    "url": "https://twostopper.com"
  };

  const organizationStructuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Two Stopper",
    "description": "Student-focused bus tracking application for Bristol universities",
    "url": "https://twostopper.com",
    "logo": "https://twostopper.com/logo.png",
    "sameAs": [
      "https://twitter.com/twostopper",
      "https://facebook.com/twostopper"
    ]
  };

  const handleGetStarted = () => {
    trackInteraction.buttonClick('Get Started');
    navigate('/register');
  };

  const handleLearnMore = () => {
    trackInteraction.buttonClick('Learn More');
    navigate('/how-it-works');
  };

  return (
    <>
      <Helmet>
        <title>Two Stopper | Real-time Bristol Bus Tracking for Students</title>
        <meta name="description" content="Free real-time bus tracking app for Bristol students. Live First Bus updates, smart journey planning, and instant notifications for UWE and University of Bristol routes." />
        <meta name="keywords" content="bristol bus tracker, student bus app, first bus bristol, uwe bus times, university of bristol bus, real-time bus tracking, bristol student transport, bus journey planner bristol, student transport bristol" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://twostopper.com/" />
        <meta property="og:title" content="Two Stopper | Bristol Student Bus Tracker" />
        <meta property="og:description" content="Never miss a lecture again with real-time Bristol bus tracking, designed for students by students." />
        <meta property="og:image" content="https://twostopper.com/og-image.jpg" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://twostopper.com/" />
        <meta name="twitter:title" content="Two Stopper | Bristol Student Bus Tracker" />
        <meta name="twitter:description" content="Real-time bus tracking for Bristol students. Never miss a lecture again!" />
        <meta name="twitter:image" content="https://twostopper.com/twitter-card.jpg" />
        
        {/* Additional SEO Meta Tags */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Two Stopper Team" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://twostopper.com/" />
        
        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(organizationStructuredData)}
        </script>
      </Helmet>

      <div className={styles.landingContainer} role="main">
        <motion.section 
          className={styles.heroSection}
          {...(!isMobile && {
            initial: "initial",
            animate: "animate",
            variants: staggerChildren
          })}
        >
          <div className={styles.heroContent}>
            <motion.span 
              className={styles.heroEyebrow}
              {...(!isMobile && {
                variants: fadeUpAnimation
              })}
            >
              Welcome to
            </motion.span>
            <motion.h1 
              className={styles.gradientText}
              {...(!isMobile && {
                variants: heroTextAnimation
              })}
            >
              Two Stopper
            </motion.h1>
            <motion.p 
              className={styles.heroSubtitle}
              {...(!isMobile && {
                variants: heroTextAnimation
              })}
            >
              Bristol's Smart Bus Companion for Students
            </motion.p>
            <motion.div 
              className={styles.ctaButtons}
              {...(!isMobile && {
                variants: buttonAnimation
              })}
            >
              <button 
                onClick={handleGetStarted} 
                className={styles.primaryButton}
              >
                Get Started <FaArrowRight className={styles.buttonIcon} />
              </button>
              <button 
                onClick={handleLearnMore} 
                className={styles.secondaryButton}
              >
                How It Works
              </button>
            </motion.div>
          </div>
        </motion.section>

        <motion.section className={styles.featuresSection}>
          <motion.div 
            className={styles.featuresHeader}
            {...(!isMobile && {
              initial: { opacity: 0, y: 30 },
              whileInView: { opacity: 1, y: 0 },
              viewport: { once: true },
              transition: { duration: 0.8, ease: [0.25, 0.1, 0, 1] }
            })}
          >
            <h2>Designed for Students</h2>
            <p>Everything you need to navigate Bristol's bus system efficiently</p>
          </motion.div>

          <div className={styles.featureGrid}>
            {[
              { icon: <FaRoute />, title: "Smart Routes", description: "AI-optimised bus routes for your schedule" },
              { icon: <FaBell />, title: "Timely Alerts", description: "Get notified when your bus is approaching" },
              { icon: <FaClock />, title: "Real-Time", description: "Live bus tracking and arrival times" },
              { icon: <FaMobile />, title: "SMS Updates", description: "Text notifications for your journey" }
            ].map((feature, index) => (
              <motion.div 
                key={index}
                className={styles.featureItem}
                {...(!isMobile && {
                  variants: featureAnimation
                })}
              >
                <div className={styles.featureIcon}>{feature.icon}</div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </motion.section>

        <motion.section 
          className={styles.universitiesSection}
          {...(!isMobile && {
            initial: { opacity: 0 },
            whileInView: { opacity: 1 },
            viewport: { once: true },
            transition: { duration: 1, ease: [0.25, 0.1, 0, 1] }
          })}
        >
          <div className={styles.universitiesContent}>
            <motion.div 
              className={styles.universitiesText}
              {...(!isMobile && {
                initial: { opacity: 0, y: 30 },
                whileInView: { opacity: 1, y: 0 },
                viewport: { once: true },
                transition: { duration: 0.8, ease: [0.25, 0.1, 0, 1] }
              })}
            >
              <h2>Free for Bristol Students</h2>
              <p className={styles.supportingText}>Currently Supporting Students at:</p>
              <div className={styles.universityCards}>
                <motion.div 
                  className={styles.uniCard}
                  {...(!isMobile && {
                    initial: { opacity: 0, scale: 0.9 },
                    whileInView: { opacity: 1, scale: 1 },
                    viewport: { once: true },
                    transition: { duration: 0.6, ease: [0.25, 0.1, 0, 1] }
                  })}
                >
                  <img 
                    src="/uwe-logo.svg" 
                    alt="UWE Bristol University Logo - University of the West of England, Bristol's premier institution for higher education"
                    className={styles.uniLogo}
                    loading="lazy"
                    width="180"
                    height="auto"
                    title="University of the West of England, Bristol"
                  />
                  <span className={styles.uniName}>UWE Bristol</span>
                </motion.div>
                <motion.div 
                  className={styles.uniCard}
                  {...(!isMobile && {
                    initial: { opacity: 0, scale: 0.9 },
                    whileInView: { opacity: 1, scale: 1 },
                    viewport: { once: true },
                    transition: { duration: 0.6, ease: [0.25, 0.1, 0, 1], delay: 0.1 }
                  })}
                >
                  <img 
                    src="/uob-logo.png" 
                    alt="University of Bristol Logo - One of the UK's leading research universities, founded in 1876"
                    className={styles.uniLogo}
                    loading="lazy"
                    width="180"
                    height="auto"
                    title="University of Bristol"
                  />
                  <span className={styles.uniName}>University of Bristol</span>
                </motion.div>
              </div>
            </motion.div>

            <motion.div 
              className={styles.ctaSection}
              {...(!isMobile && {
                initial: { opacity: 0, y: 30 },
                whileInView: { opacity: 1, y: 0 },
                viewport: { once: true },
                transition: { duration: 0.8, ease: [0.25, 0.1, 0, 1] }
              })}
            >
              <h3>Never Miss a Lecture Again</h3>
              <div className={styles.ctaButtons}>
                <button 
                  onClick={handleGetStarted} 
                  className={styles.ctaButtonPrimary}
                >
                  Get Started <FaArrowRight />
                </button>
                <button 
                  onClick={() => navigate('/login')} 
                  className={styles.ctaButtonSecondary}
                >
                  Sign In
                </button>
              </div>
            </motion.div>
          </div>
        </motion.section>

        <PublicFooter />
      </div>
    </>
  );
}

export default ModernLandingPage;
