import React, { useState } from 'react';
import { IoBusOutline, IoLocationOutline, IoWalkOutline, IoTimeOutline, IoFilterOutline, IoSwapHorizontalOutline } from 'react-icons/io5';
import styles from './BusCard.module.css';

const getTimeDifference = (time) => {
  if (!time) return '';
  
  console.log('Time received:', time, typeof time);
  
  try {
    const [hours, minutes] = time.split(':').map(Number);
    console.log('Hours:', hours, 'Minutes:', minutes);
    
    if (isNaN(hours) || isNaN(minutes)) return '';
    
    const busTime = new Date();
    busTime.setHours(hours);
    busTime.setMinutes(minutes);
    
    const now = new Date();
    const diff = busTime - now;
    const diffMinutes = Math.round(diff / (1000 * 60));
    
    console.log('Diff minutes:', diffMinutes);
    
    // If the bus time is in the past, assume it's for tomorrow
    if (diffMinutes < 0) {
      return `${diffMinutes + 24 * 60}min`;
    }
    
    return `${diffMinutes}min`;
  } catch (error) {
    console.error('Error calculating time difference:', error);
    return '';
  }
};

const BusCard = ({
  title,
  busStopName,
  destination,
  onClick,
  busData,
  settings,
  isLoading,
  busTime,
  walkTime
}) => {
  const MAX_BUSES_TO_SHOW = 6;

  // Show loading only when we have a stop but no data yet
  const showLoading = busStopName && isLoading && (!busData || busData.length === 0);

  const getNoDataMessage = () => {
    if (settings?.directBusesOnly) {
      return (
        <div className={styles.noDataMessage}>
          <span>No direct buses available</span>
          <span className={styles.noDataSubtext}>Try turning off "Direct buses only" in settings to see routes with changes</span>
        </div>
      );
    }
    return <span>No buses available</span>;
  };

  return (
    <div 
      className={`${styles.container} ${!busStopName ? styles.disabled : ''}`}
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      {!busStopName ? (
        <div className={styles.placeholder}>
          <span>Please select a stop</span>
        </div>
      ) : showLoading ? (
        <div className={styles.loadingContainer}>
          <div className={styles.loadingContent}>
            <div className={styles.header}>
              <div className={styles.titleGroup}>
                <IoBusOutline className={styles.icon} />
                <div className={styles.titleContent}>
                  <h3>{title}</h3>
                </div>
              </div>
              {settings && (Object.values(settings).some(setting => setting)) && (
                <div className={styles.settingsBadges}>
                  {settings.directBusesOnly && (
                    <div className={styles.settingBadge} title="Direct buses only">
                      <IoFilterOutline className={styles.settingIcon} />
                      <span>Direct only</span>
                    </div>
                  )}
                  {settings.prioritiseEarliestArrival && (
                    <div className={styles.settingBadge} title="Prioritising earliest arrival">
                      <IoTimeOutline className={styles.settingIcon} />
                      <span>Earliest arrival</span>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className={styles.loadingPlaceholder}>
              <div className={styles.loadingNextBus}>
                <div className={styles.loadingService}></div>
                <div className={styles.loadingTime}></div>
              </div>
              <div className={styles.loadingDetails}>
                <div className={styles.loadingDetail}></div>
                <div className={styles.loadingDetail}></div>
              </div>
              <div className={styles.loadingLaterBuses}>
                <div className={styles.loadingLaterBus}></div>
                <div className={styles.loadingLaterBus}></div>
                <div className={styles.loadingLaterBus}></div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.header}>
            <div className={styles.titleGroup}>
              <IoBusOutline className={styles.icon} />
              <div className={styles.titleContent}>
                <h3>{title}</h3>
              </div>
            </div>
            {settings && (Object.values(settings).some(setting => setting)) && (
              <div className={styles.settingsBadges}>
                {settings.directBusesOnly && (
                  <div className={styles.settingBadge} title="Direct buses only">
                    <IoFilterOutline className={styles.settingIcon} />
                    <span>Direct only</span>
                  </div>
                )}
                {settings.prioritiseEarliestArrival && (
                  <div className={styles.settingBadge} title="Prioritising earliest arrival">
                    <IoTimeOutline className={styles.settingIcon} />
                    <span>Earliest arrival</span>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className={styles.content}>
            <div className={styles.timeDisplay}>
              {busData && busData.length > 0 ? (
                <>
                  {/* Next Bus */}
                  <div className={styles.nextBus}>
                    <div className={styles.busInfo}>
                      <div className={styles.serviceNumber}>{busData[0].service}</div>
                      <div className={styles.timeInfo}>
                        <span className={styles.time}>{busTime || busData[0].time}</span>
                        {busData[0].changes === undefined ? (
                          <span className={styles.badge}>No route</span>
                        ) : busData[0].changes > 0 ? (
                          <span className={styles.badge}>
                            <IoSwapHorizontalOutline />
                            {`${busData[0].changes} change${busData[0].changes > 1 ? 's' : ''}`}
                          </span>
                        ) : (
                          <span className={`${styles.badge} ${styles.direct}`}>Direct</span>
                        )}
                      </div>
                    </div>
                    <div className={styles.details}>
                      <div className={styles.detail}>
                        <IoLocationOutline className={styles.detailIcon} />
                        <span>Bus Stop: {busStopName}</span>
                      </div>
                      <div className={styles.detail}>
                        <IoWalkOutline className={styles.detailIcon} />
                        <span>Walking Distance: {walkTime || 0} mins</span>
                      </div>
                    </div>
                  </div>

                  {/* Later Buses */}
                  {busData.length > 1 && (
                    <div className={styles.laterBuses}>
                      <div className={styles.laterBusesHeader}>
                        <IoTimeOutline className={styles.detailIcon} />
                        <span>Later Buses</span>
                      </div>
                      <div className={styles.laterBusesGrid}>
                        {busData.slice(1, MAX_BUSES_TO_SHOW).map((bus, index) => (
                          <div key={index} className={styles.laterBus}>
                            <div className={styles.laterBusService}>{bus.service}</div>
                            <div className={styles.laterBusInfo}>
                              <div className={styles.laterBusTimeGroup}>
                                <span className={styles.laterBusTime}>{bus.time}</span>
                                <span className={styles.laterBusTimeDiff}>
                                  {bus.time ? getTimeDifference(bus.time) : ''}
                                </span>
                              </div>
                              {bus.changes === undefined ? (
                                <span className={styles.smallBadge}>No route</span>
                              ) : bus.changes > 0 ? (
                                <span className={styles.smallBadge}>
                                  <IoSwapHorizontalOutline />
                                  {`${bus.changes} change${bus.changes > 1 ? 's' : ''}`}
                                </span>
                              ) : (
                                <span className={`${styles.smallBadge} ${styles.direct}`}>Direct</span>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                getNoDataMessage()
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BusCard;
