import React from 'react';
import { Helmet } from 'react-helmet-async';
import styles from './Terms.module.css';
import PublicNavbar from '../../components/PublicNavbar';
import PublicFooter from '../../components/PublicFooter';

export default function Terms() {
  return (
    <div className={styles.pageContainer}>
      <Helmet>
        <title>Terms and Conditions - Two Stopper</title>
        <meta name="description" content="Terms and conditions for using Two Stopper services." />
      </Helmet>

      <PublicNavbar />

      <div className={styles.termsHero}>
        <div className={styles.termsHeroPattern} />
        <div className={styles.termsHeroBackground} />
        <div className={styles.termsHeroContent}>
          <h1 className={styles.termsHeroTitle}>Terms and Conditions</h1>
          <p className={styles.termsHeroSubtitle}>
            Please read these terms carefully before using our services
          </p>
        </div>
      </div>

      <main className={styles.mainContent}>
        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>1. Introduction</h2>
          <div className={styles.sectionContent}>
            <p>Welcome to Two Stopper. By using our services, you agree to be bound by these Terms and Conditions ("Terms"). Please read them carefully before using our platform. These Terms constitute a legally binding agreement between you and Two Stopper regarding your use of our website, mobile application, and services (collectively, the "Services").</p>
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>2. Acceptance of Terms</h2>
          <div className={styles.sectionContent}>
            <p>By accessing or using our Services, you confirm that you accept these Terms and agree to comply with them. If you do not agree to these Terms, you must not use our Services. We recommend that you print a copy of these Terms for future reference.</p>
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>3. Service Description</h2>
          <div className={styles.sectionContent}>
            <p>Two Stopper provides a platform for users to:</p>
            <ul>
              <li>Plan bus journeys with multiple stops</li>
              <li>Access real-time bus timetables and updates</li>
              <li>Save and manage favourite routes</li>
              <li>Receive notifications about service changes</li>
              <li>Share journey details with other users</li>
            </ul>
            <p>Whilst we strive to ensure the accuracy of information provided through our Services, we cannot guarantee that all information will be accurate or up-to-date at all times.</p>
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>4. User Accounts</h2>
          <div className={styles.sectionContent}>
            <p>4.1. Registration Requirements</p>
            <ul>
              <li>You must be at least 13 years old to create an account</li>
              <li>You must provide accurate and complete information</li>
              <li>You are responsible for maintaining the confidentiality of your account</li>
              <li>You must notify us immediately of any unauthorised access</li>
            </ul>
            
            <p>4.2. Account Security</p>
            <ul>
              <li>Choose a strong password and keep it secure</li>
              <li>Do not share your account credentials</li>
              <li>Log out from shared devices</li>
              <li>Enable two-factor authentication when available</li>
            </ul>
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>5. User Conduct</h2>
          <div className={styles.sectionContent}>
            <p>You agree not to:</p>
            <ul>
              <li>Use the Services in any unlawful manner</li>
              <li>Attempt to gain unauthorised access to our systems</li>
              <li>Transmit any harmful code or malware</li>
              <li>Impersonate any person or entity</li>
              <li>Interfere with other users' access to the Services</li>
              <li>Collect user information without consent</li>
              <li>Use the Services for commercial purposes without our consent</li>
            </ul>
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>6. Intellectual Property</h2>
          <div className={styles.sectionContent}>
            <p>6.1. Our Rights</p>
            <p>All intellectual property rights in the Services belong to us or our licensors. You may not use, copy, or modify any content without our permission.</p>
            
            <p>6.2. Your Content</p>
            <p>By submitting content to our Services, you grant us a worldwide, non-exclusive, royalty-free licence to use, store, and display that content in connection with the Services.</p>
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>7. Liability and Disclaimers</h2>
          <div className={styles.sectionContent}>
            <p>7.1. Service Availability</p>
            <p>We do not guarantee that our Services will be:</p>
            <ul>
              <li>Always available or uninterrupted</li>
              <li>Free from errors or omissions</li>
              <li>Free from harmful components</li>
              <li>Suitable for your specific needs</li>
            </ul>
            
            <p>7.2. Limitation of Liability</p>
            <p>We shall not be liable for:</p>
            <ul>
              <li>Any indirect or consequential losses</li>
              <li>Loss of profits, sales, business, or revenue</li>
              <li>Business interruption</li>
              <li>Loss of anticipated savings</li>
              <li>Loss of business opportunity, goodwill, or reputation</li>
            </ul>
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>8. Changes to Terms</h2>
          <div className={styles.sectionContent}>
            <p>We may revise these Terms at any time by amending this page. Please check this page regularly to take notice of any changes we have made, as they are binding on you. We will notify you of significant changes through our Services or via email.</p>
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>9. Governing Law</h2>
          <div className={styles.sectionContent}>
            <p>These Terms are governed by and construed in accordance with the laws of England and Wales. Any disputes relating to these Terms shall be subject to the exclusive jurisdiction of the courts of England and Wales.</p>
          </div>
        </section>

        <p className={styles.lastUpdated}>Last updated: 12 January 2025</p>
      </main>

      <PublicFooter />
    </div>
  );
}
