import React from 'react';
import { Link } from 'react-router-dom';
import { FaBus, FaHome, FaPhone } from 'react-icons/fa';
import { Helmet } from 'react-helmet-async';
import styles from '../styles/NotFound.module.css';

const NotFound = () => {
  return (
    <div className={styles.notFoundContainer}>
      <Helmet>
        <title>Page Not Found - Two Stopper</title>
        <meta name="description" content="Sorry, the page you're looking for doesn't exist. But don't worry, we'll help you get back on track!" />
      </Helmet>

      <div className={styles.content}>
        <FaBus className={styles.busIcon} aria-hidden="true" />
        <h1 className={styles.title}>Oops! Wrong Stop</h1>
        <p className={styles.subtitle}>
          Looks like this bus stop doesn't exist! Don't worry though - we can help you get back on route.
        </p>
        <div>
          <Link to="/" className={styles.button}>
            <FaHome /> Back to Home
          </Link>
          <Link to="/contact" className={`${styles.button} ${styles.buttonSecondary}`}>
            <FaPhone /> Contact Support
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
