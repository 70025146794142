import React from 'react';
import { motion } from 'framer-motion';
import styles from './PageTransition.module.css';

const PageTransition = ({ children, isAuth = false }) => {
  return (
    <motion.div
      className={styles.pageTransition}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ 
        duration: 0.15,
        ease: "easeInOut"
      }}
    >
      {children}
    </motion.div>
  );
};

export default PageTransition;
