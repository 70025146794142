import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './PublicFooter.module.css';

const PublicFooter = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.footerSection}>
          <h3>Quick Links</h3>
          <ul>
            <li>
              <button onClick={() => handleNavigation('/about')} className={styles.footerLink}>
                About Us
              </button>
            </li>
            <li>
              <button onClick={() => handleNavigation('/contact')} className={styles.footerLink}>
                Contact
              </button>
            </li>
            <li>
              <button onClick={() => handleNavigation('/how-it-works')} className={styles.footerLink}>
                How It Works
              </button>
            </li>
            <li>
              <button onClick={() => handleNavigation('/pricing')} className={styles.footerLink}>
                Pricing
              </button>
            </li>
          </ul>
        </div>

        <div className={styles.footerSection}>
          <h3>Legal</h3>
          <ul>
            <li>
              <button onClick={() => handleNavigation('/privacy')} className={styles.footerLink}>
                Privacy Policy
              </button>
            </li>
            <li>
              <button onClick={() => handleNavigation('/terms')} className={styles.footerLink}>
                Terms & Conditions
              </button>
            </li>
            <li>
              <button onClick={() => handleNavigation('/cookies')} className={styles.footerLink}>
                Cookie Policy
              </button>
            </li>
            <li>
              <button onClick={() => handleNavigation('/safety-tips')} className={styles.footerLink}>
                Safety Tips
              </button>
            </li>
          </ul>
        </div>

        <div className={styles.footerSection}>
          <h3>Resources</h3>
          <ul>
            <li>
              <button onClick={() => handleNavigation('/bus-alerts')} className={styles.footerLink}>
                Bus Alerts
              </button>
            </li>
            <li>
              <button onClick={() => handleNavigation('/timetables')} className={styles.footerLink}>
                Timetables
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div className={styles.footerBottom}>
        <p>&copy; 2025 Two Stopper. All rights reserved.</p>
        <p className={styles.poweredBy}>Powered by Joefergraphy Limited</p>
      </div>
    </footer>
  );
};

export default PublicFooter;
