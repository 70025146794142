// Cache duration in milliseconds (5 minutes)
const CACHE_DURATION = 5 * 60 * 1000;

class CacheManager {
    constructor() {
        this.cache = new Map();
    }

    generateKey(config) {
        return `${config.method}-${config.url}-${JSON.stringify(config.params || {})}-${JSON.stringify(config.data || {})}`;
    }

    set(config, response) {
        const key = this.generateKey(config);
        this.cache.set(key, {
            data: response,
            timestamp: Date.now()
        });
    }

    get(config) {
        const key = this.generateKey(config);
        const cached = this.cache.get(key);
        
        if (!cached) return null;
        
        // Check if cache is still valid
        if (Date.now() - cached.timestamp > CACHE_DURATION) {
            this.cache.delete(key);
            return null;
        }
        
        return cached.data;
    }

    clear() {
        this.cache.clear();
    }
}

export const cacheManager = new CacheManager();
