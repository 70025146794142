import React from 'react';
import styles from './GoodbyeMessage.module.css';
import { IoMdBus } from 'react-icons/io';

const GoodbyeMessage = () => {
  return (
    <div className={styles.goodbyeContainer}>
      <div className={styles.content}>
        <IoMdBus className={styles.logo} />
        <h1 className={styles.title}>Sorry to See You Go!</h1>
        <p className={styles.message}>
          Thank you for being part of the Two-Stopper journey. We hope our paths cross again in the future!
        </p>
        <div className={styles.deleteMessage}>
          All your account information and data have been permanently deleted.
        </div>
        <p className={styles.redirectMessage}>
          Redirecting you to our landing page...
        </p>
      </div>
    </div>
  );
};

export default GoodbyeMessage;
