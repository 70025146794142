import React, { useState, useContext, useRef, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FaEnvelope, FaLock, FaEye, FaEyeSlash, FaFingerprint, FaArrowLeft, FaExclamationCircle } from 'react-icons/fa';
import axiosInstance from './utils/axiosInstance';
import { AuthContext } from './contexts/AuthContext';
import { useSidebar } from './contexts/SidebarContext';
import { toast } from 'react-toastify';
import styles from './styles/Login.module.css';
import { trackAuth, trackError } from './utils/analytics';

const Login = () => {
  const navigate = useNavigate();
  const { checkAuth } = useContext(AuthContext);
  const { resetAnimation } = useSidebar();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [passkeysSupported, setPasskeysSupported] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [passkeyChecked, setPasskeyChecked] = useState(false);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  useEffect(() => {
    const checkPasskeySupport = async () => {
      try {
        if (window.PublicKeyCredential) {
          const available = await window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
          setPasskeysSupported(available);
        }
        setPasskeyChecked(true);
      } catch (error) {
        console.error('Error checking passkey support:', error);
        setPasskeyChecked(true);
      }
    };
    checkPasskeySupport();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form submission
    e.stopPropagation(); // Stop event propagation
    if (isLoading) return; // Prevent multiple submissions
    
    setError('');
    setIsLoading(true);

    try {
      const response = await axiosInstance.post('/api/auth/login', {
        email,
        password,
      });
      
      if (response.data?.token) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('lastLoginEmail', email);
        await checkAuth();
        resetAnimation();
        trackAuth.login('email'); // Track successful login
        navigate('/home');
        return;
      }
      
      // If we get here, something went wrong
      setError('Invalid email or password');
      setPassword('');
    } catch (err) {
      console.error('Login error:', err.response?.data || err);
      const errorMessage = err.response?.data?.message || err.response?.data?.msg || 'Invalid email or password';
      trackError.authError('login', errorMessage); // Track login error
      setError(errorMessage);
      setPassword('');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasskeyLogin = async () => {
    if (!emailRef.current?.value) {
      toast.error('Please enter your email first');
      return;
    }

    try {
      const { data: options } = await axiosInstance.post('/api/auth/passkey/generate-options', { 
        email: emailRef.current.value 
      });

      if (!options.allowCredentials?.length) {
        toast.info('No passkey found for this email. Please use password login.');
        return;
      }

      const base64urlToBase64 = str => 
        str.replace(/-/g, '+').replace(/_/g, '/') + '='.repeat((4 - str.length % 4) % 4);

      const challenge = Uint8Array.from(
        atob(base64urlToBase64(options.challenge)),
        c => c.charCodeAt(0)
      );

      const allowCredentials = options.allowCredentials.map(cred => ({
        type: 'public-key',
        id: Uint8Array.from(
          atob(base64urlToBase64(cred.id)),
          c => c.charCodeAt(0)
        ),
        transports: cred.transports
      }));

      const credential = await navigator.credentials.get({
        publicKey: {
          challenge,
          allowCredentials,
          timeout: 60000,
          userVerification: 'preferred',
          rpId: window.location.hostname
        }
      });

      const response = await axiosInstance.post('/api/auth/passkey/verify', {
        email: emailRef.current.value,
        credentialId: credential.id,
        authenticatorData: btoa(String.fromCharCode(...new Uint8Array(credential.response.authenticatorData))),
        clientDataJSON: btoa(String.fromCharCode(...new Uint8Array(credential.response.clientDataJSON))),
        signature: btoa(String.fromCharCode(...new Uint8Array(credential.response.signature)))
      });

      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('lastLoginEmail', emailRef.current.value);
        await checkAuth();
        resetAnimation();
        trackAuth.login('passkey'); // Track successful login
        navigate('/home');
      }
    } catch (error) {
      console.error('Passkey login error:', error);
      trackError.authError('passkey', error.message); // Track login error
      toast.error('Passkey login failed. Please try again or use password.');
    }
  };

  return (
    <div className={`${styles.container} ${isLoading ? styles.loading : ''}`}>
      <div className={`${styles.card} ${styles.fadeIn}`}>
        <button 
          type="button" 
          onClick={() => navigate(-1)} 
          className={styles.backButton}
        >
          <FaArrowLeft />
        </button>
        
        <div className={styles.content}>
          <img src="/2Slogo.png" alt="Two Stopper Logo" className={styles.logo} />
          <h1 className={styles.title}>Two Stopper</h1>
          
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.inputGroup}>
              <FaEnvelope className={styles.icon} />
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                ref={emailRef}
                required
                className={styles.input}
                autoComplete="email"
              />
            </div>
            
            <div className={styles.inputGroup}>
              <FaLock className={styles.icon} />
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                ref={passwordRef}
                required
                className={styles.input}
                autoComplete="current-password"
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className={styles.passwordToggle}
                aria-label={showPassword ? "Hide password" : "Show password"}
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </button>
            </div>

            {error && (
              <div className={styles.error}>
                <FaExclamationCircle />
                {error}
              </div>
            )}
            
            <button 
              type="submit" 
              className={styles.signInButton} 
              disabled={isLoading}
            >
              {isLoading ? 'Signing in...' : 'Sign In'}
            </button>
          </form>

          {passkeyChecked && passkeysSupported && (
            <>
              <div className={styles.divider}>or</div>
              <button
                type="button"
                onClick={handlePasskeyLogin}
                className={styles.passkeyButton}
                disabled={isLoading}
              >
                <FaFingerprint className={styles.passkeyIcon} />
                Sign in with Passkey
              </button>
            </>
          )}

          <div className={styles.footer}>
            <div>
              Don't have an account?
              <Link to="/register" className={styles.link}> Create Account</Link>
            </div>
            
            <Link to="/forgot-password" className={styles.forgotPassword}>
              Forgot Password?
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;