import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { FaGithub, FaLinkedin, FaEnvelope, FaCode, FaUniversity, FaCheckCircle, FaBus, FaClock, FaMobile } from 'react-icons/fa';
import PublicFooter from '../components/PublicFooter';
import PublicHero from '../components/PublicHero';
import styles from './About.module.css';
import animations from '../styles/animations.module.css';
import classNames from 'classnames';

function About() {
  const [userCount, setUserCount] = useState(null);

  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const response = await fetch('/api/user/count');
        const data = await response.json();
        if (data && typeof data.count === 'number') {
          setUserCount(data.count);
        }
      } catch (error) {
        console.error('Error fetching user count:', error);
        setUserCount(0); // Fallback to 0 if there's an error
      }
    };

    fetchUserCount();
  }, []);

  const teamMembers = [
    {
      name: "Fergus Howard-Jones",
      role: "Founder & Developer",
      bio: "Information Technology student at the University of the West of England, passionate about creating solutions that make student life easier.",
      links: {
        linkedin: "https://www.linkedin.com/in/fergushowardjones/",
        email: "mailto:fergus@twostopper.co.uk"
      }
    },
    {
      name: "Joe Pryor",
      role: "Founder & Developer",
      bio: "Cyber Security Specialist",
      links: {
        linkedin: "https://www.linkedin.com/in/joseph-pryor/",
        email: "mailto:joe@twostopper.co.uk"
      }
    }
  ];

  // Format user count with fallback
  const formattedUserCount = userCount !== null 
    ? `${userCount.toLocaleString()}+` 
    : '...';

  return (
    <div className={styles.pageContainer}>
      <Helmet>
        <title>About Two Stopper | Bristol's Student Bus Journey Planner</title>
        <meta name="description" content="Learn about Two Stopper, Bristol's innovative bus journey planner designed for students. Track buses in real-time and plan your journey across Bristol efficiently." />
        <meta name="keywords" content="two stopper bristol, student bus app, bristol bus tracker, university transport bristol, real-time bus tracking" />
        <link rel="canonical" href="https://twostopper.co.uk/about" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "AboutPage",
            "name": "About Two Stopper",
            "description": "Learn about Two Stopper, Bristol's innovative bus journey planner designed for students.",
            "publisher": {
              "@type": "Organization",
              "name": "Two Stopper",
              "url": "https://twostopper.co.uk",
              "description": "Real-time bus tracking and journey planning for Bristol students"
            },
            "dateModified": "2025-01-12T18:15:54Z"
          })}
        </script>
      </Helmet>

      <PublicHero 
        title="About Two Stopper"
        description="Making student transport in Bristol simpler, faster, and more reliable."
      />

      <div className={styles.contentContainer}>
        <section className={classNames(styles.missionSection, animations.fadeUp, animations.delay1)}>
          <h2>Our Mission</h2>
          <p>Two Stopper was born from a simple observation: students in Bristol needed a better way to navigate the city's bus system. We're here to make that happen, combining real-time tracking with smart journey planning to save you time and reduce the stress of your daily commute.</p>
          <div className={styles.statsGrid}>
            <div className={styles.statCard}>
              <FaMobile className={styles.statIcon} />
              <div className={styles.statNumber}>{formattedUserCount}</div>
              <div className={styles.statLabel}>Daily Users</div>
            </div>
            <div className={styles.statCard}>
              <FaBus className={styles.statIcon} />
              <div className={styles.statNumber}>50+</div>
              <div className={styles.statLabel}>Bus Routes</div>
            </div>
            <div className={styles.statCard}>
              <FaClock className={styles.statIcon} />
              <div className={styles.statNumber}>24/7</div>
              <div className={styles.statLabel}>Real-time Updates</div>
            </div>
          </div>
        </section>

        <section className={classNames(styles.featuresSection, animations.fadeUp, animations.delay2)}>
          <h2>What Makes Us Different</h2>
          <div className={styles.featureGrid}>
            <div className={styles.featureCard}>
              <FaCode className={styles.featureIcon} />
              <h3>Student-Built</h3>
              <p>Created by Bristol students who understand your daily transport challenges.</p>
            </div>
            <div className={styles.featureCard}>
              <FaUniversity className={styles.featureIcon} />
              <h3>University Focused</h3>
              <p>Specialised features for The University of Bristol and the University of the West of England routes and campuses.</p>
            </div>
            <div className={styles.featureCard}>
              <FaCheckCircle className={styles.featureIcon} />
              <h3>Always Free</h3>
              <p>No subscriptions, no hidden fees. Just a helpful service for students.</p>
            </div>
          </div>
        </section>

        <section className={classNames(styles.teamSection, animations.fadeUp, animations.delay3)}>
          <h2>Meet the Team</h2>
          <div className={styles.teamGrid}>
            {teamMembers.map((member) => (
              <div key={member.name} className={styles.teamCard}>
                <div className={styles.teamInfo}>
                  <h3>{member.name}</h3>
                  <div className={styles.role}>{member.role}</div>
                  <p>{member.bio}</p>
                </div>
                <div className={styles.socialLinks}>
                  <a href={member.links.linkedin} target="_blank" rel="noopener noreferrer" className={styles.socialLink}>
                    <FaLinkedin />
                  </a>
                  <a href={member.links.email} className={styles.socialLink}>
                    <FaEnvelope />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </section>

        <section className={classNames(styles.contactSection, animations.fadeUp, animations.delay4)}>
          <h2>Get in Touch</h2>
          <p>Have questions, suggestions, or just want to say hello? We'd love to hear from you!</p>
          <a href="/contact" className={styles.contactButton}>Contact Us</a>
        </section>
      </div>

      <PublicFooter />
    </div>
  );
}

export default About;
