import React, { createContext, useContext, useState } from 'react';
import DeleteConfirmationModal from '../components/DeleteConfirmationModal';
import PasswordChangeModal from '../components/PasswordChangeModal';

const ModalContext = createContext();

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

export const ModalProvider = ({ children }) => {
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, itemName: '', onConfirm: null });
  const [passwordModal, setPasswordModal] = useState({ isOpen: false, onSubmit: null });

  const showDeleteConfirmation = (itemName, onConfirm) => {
    setDeleteModal({ isOpen: true, itemName, onConfirm });
  };

  const hideDeleteConfirmation = () => {
    setDeleteModal({ isOpen: false, itemName: '', onConfirm: null });
  };

  const showPasswordChange = (onSubmit) => {
    setPasswordModal({ isOpen: true, onSubmit });
  };

  const hidePasswordChange = () => {
    setPasswordModal({ isOpen: false, onSubmit: null });
  };

  const handleDeleteConfirm = async () => {
    if (deleteModal.onConfirm) {
      await deleteModal.onConfirm();
    }
    hideDeleteConfirmation();
  };

  const handlePasswordSubmit = async (passwordData) => {
    if (passwordModal.onSubmit) {
      await passwordModal.onSubmit(passwordData);
    }
    hidePasswordChange();
  };

  return (
    <ModalContext.Provider value={{ showDeleteConfirmation, showPasswordChange }}>
      {children}
      <DeleteConfirmationModal
        isOpen={deleteModal.isOpen}
        onClose={hideDeleteConfirmation}
        onConfirm={handleDeleteConfirm}
        itemName={deleteModal.itemName}
      />
      <PasswordChangeModal
        isOpen={passwordModal.isOpen}
        onClose={hidePasswordChange}
        onSubmit={handlePasswordSubmit}
      />
    </ModalContext.Provider>
  );
};
