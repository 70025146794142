// src/Timetable.js

import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import axiosInstance from './utils/axiosInstance';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './Timetable.module.css';
import { 
  HiPencil as EditIcon,
  HiTrash as DeleteIcon,
  HiStar as StarIcon,
  HiOutlineStar as StarOutlineIcon,
  HiPlus as PlusIcon,
  HiCalendar as CalendarIcon,
  HiLocationMarker as LocationIcon,
  HiX as CloseIcon,
  HiAcademicCap as LectureIcon,
  HiArrowRight as ArrowRightIcon,
  HiArrowLeft as ArrowLeftIcon,
  HiOutlineAcademicCap,
  HiOutlineClock as IoTimeOutline
} from 'react-icons/hi';
import { IoBus as BusIcon } from 'react-icons/io5';
import { IoClose } from 'react-icons/io5';
import { IoAdd } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import LoadingScreen from './components/LoadingScreen';
import TimetableForm from './components/TimetableForm';
import Sidebar from './components/Sidebar';
import PageTransition from './components/PageTransition';
import TimetableModal from './components/TimetableModal';
import { useDeleteConfirmation } from './App';
import { trackNavigation, trackFeature, trackError, trackInteraction } from './utils/analytics';

function Timetable() {
  const [timetables, setTimetables] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedTimetable, setExpandedTimetable] = useState(null);
  const [selectedTimetable, setSelectedTimetable] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const showDeleteConfirmation = useDeleteConfirmation();
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    trackNavigation.pageView('Timetable');
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (location.state?.openTimetableForm) {
      setIsEditModalOpen(true);
      // Clear the state so it doesn't reopen on refresh
      navigate(location.pathname, { replace: true });
    }
  }, [location.state]);

  const fetchTimetables = async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get('/api/timetable');
      setTimetables(res.data || []);
      trackFeature.timetableView();
      setError(null);
    } catch (err) {
      console.error('Error fetching timetables:', err);
      trackError.apiError('timetable/fetch', err.message);
      if (err.response?.status !== 404) {
        setError('Something went wrong. Please try again later.');
      }
      setTimetables([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTimetables();
  }, []);

  const handleEdit = (e, id) => {
    e.stopPropagation();
    trackInteraction.buttonClick('Edit Timetable');
    trackFeature.timetableEdit();
    setSelectedTimetable(timetables.find(t => t._id === id));
    setIsEditModalOpen(true);
  };

  const handleDeleteClick = (e, timetable) => {
    e.stopPropagation();
    trackInteraction.buttonClick('Delete Timetable');
    showDeleteConfirmation(timetable.name, async () => {
      try {
        await axiosInstance.delete(`/api/timetable/${timetable._id}`);
        setTimetables(timetables.filter(t => t._id !== timetable._id));
        toast.success('Timetable deleted successfully');
      } catch (error) {
        console.error('Error deleting timetable:', error);
        trackError.apiError('timetable/delete', error.message);
        toast.error('Failed to delete timetable');
      }
    });
  };

  const handleSetActive = async (e, id) => {
    e.stopPropagation();
    trackInteraction.buttonClick('Set Active Timetable');
    const timetable = timetables.find(tt => tt._id === id);
    try {
      const response = await axiosInstance.post(`/api/timetable/${id}/set-active`);
      
      if (response.data.success) {
        setTimetables(prevTimetables => 
          prevTimetables.map(tt => ({
            ...tt,
            active: tt._id === id
          }))
        );
        toast.success(`"${timetable?.name || 'Untitled'}" set as active timetable`);
        setError('');
      } else {
        throw new Error(response.data.msg || 'Failed to set timetable as active');
      }
    } catch (error) {
      console.error('Error setting active timetable:', error);
      trackError.apiError('timetable/set-active', error.message);
      const errorMsg = error.response?.data?.msg || 'Failed to set timetable as active';
      toast.error(errorMsg);
      setError(errorMsg);
    }
  };

  const handleExpandTimetable = (event, timetable) => {
    event.stopPropagation();
    setExpandedTimetable(timetable);
  };

  const formatTime = (utcTime, duration) => {
    if (!utcTime || !duration) {
      console.warn('[formatTime] Missing time or duration:', { utcTime, duration });
      return 'Invalid time format';
    }

    try {
      // Create a date object for the UTC time
      const startDate = new Date(`2025-01-12T${utcTime}Z`);
      
      // Calculate end time by adding duration
      const endDate = new Date(startDate.getTime() + duration * 60000);

      // Format times in local timezone
      const formatOptions = {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      };

      const startTimeStr = startDate.toLocaleTimeString('en-US', formatOptions);
      const endTimeStr = endDate.toLocaleTimeString('en-US', formatOptions);

      return `${startTimeStr} - ${endTimeStr}`;
    } catch (error) {
      console.error('[formatTime] Error formatting time:', error);
      return 'Invalid time';
    }
  };

  const handleAddTimetable = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    trackInteraction.buttonClick('Create New Timetable');
    setSelectedTimetable(null);
    setIsEditModalOpen(true);
  };

  const handleTimetableCreated = async (newTimetable) => {
    fetchTimetables();
    setIsEditModalOpen(false);
  };

  const handleCloseModal = () => {
    setExpandedTimetable(null);
  };

  const renderTimetableCard = (timetable, index) => {
    const isActive = timetable.active;
    
    return (
      <div
        key={timetable._id}
        className={`${styles.card} ${isActive ? styles.current : ''}`}
        onClick={(e) => handleExpandTimetable(e, timetable)}
        style={{ '--animation-order': index + 1 }}
      >
        <div className={styles.cardHeader}>
          <div className={styles.info}>
            <h2 className={styles.infoTitle}>
              {timetable.name}
              {timetable?.active && (
                <span className={styles.activeStatus}>Active</span>
              )}
            </h2>
          </div>
          <div className={styles.actions}>
            <button
              className={styles.actionButton}
              onClick={(e) => handleEdit(e, timetable._id)}
              title="Edit timetable"
            >
              <EditIcon />
            </button>
            <button
              className={styles.actionButton}
              onClick={(e) => handleDeleteClick(e, timetable)}
              title="Delete timetable"
            >
              <DeleteIcon />
            </button>
            <button
              className={styles.statusButton}
              onClick={(e) => handleSetActive(e, timetable._id)}
              title="Set as active timetable"
            >
              {timetable?.active ? 'Active' : 'Set Active'}
            </button>
          </div>
        </div>

        <div className={styles.stats}>
          <div className={styles.statItem}>
            <HiOutlineAcademicCap className={styles.statIcon} />
            <span>{timetable.lectures?.length || 0} lectures</span>
          </div>
          <div className={styles.statItem}>
            <LocationIcon className={styles.statIcon} />
            <span>{timetable.university || 'No university'}</span>
          </div>
          {timetable.campusBusStops?.map((busStopConfig, index) => (
            <div key={index} className={styles.statItem}>
              <BusIcon className={styles.statIcon} />
              <span>{busStopConfig.campus || 'No campus'}</span>
            </div>
          ))}
        </div>

        <div className={styles.busStops}>
          {timetable.campusBusStops?.map((busStopConfig, index) => (
            <React.Fragment key={index}>
              <div className={styles.busStopOutbound}>
                <div className={styles.iconGroup}>
                  <ArrowRightIcon className={styles.directionIcon} />
                  <BusIcon className={styles.busIcon} />
                </div>
                <span>{busStopConfig.outboundStop?.name || 'No outbound stop'}</span>
              </div>
              <div className={styles.busStopReturn}>
                <div className={styles.iconGroup}>
                  <ArrowLeftIcon className={styles.directionIcon} />
                  <BusIcon className={styles.busIcon} />
                </div>
                <span>{busStopConfig.inboundStop?.name || 'No return stop'}</span>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <PageTransition isAuth={true}>
      <div className={styles.timetableContainer}>
        <Sidebar />
        <main className={styles.main}>
          <section className={styles.hero}>
            <div className={styles.heroBackground} />
            <div className={styles.heroContent}>
              <div className={styles.heroLeft}>
                <h1 className={styles.heroTitle}>Your Timetables</h1>
                <p className={styles.heroSubtitle}>
                  Manage your university timetables and bus routes all in one place. Create, edit, and optimise your daily commute.
                </p>
                <div className={styles.statsGroup}>
                  <div className={styles.statItem}>
                    <HiOutlineAcademicCap className={styles.statIcon} />
                    <div>
                      <div className={styles.statValue}>{timetables.length}</div>
                      <div className={styles.statLabel}>Total Timetables</div>
                    </div>
                  </div>
                  <div className={styles.statItem}>
                    <CalendarIcon className={styles.statIcon} />
                    <div>
                      <div className={styles.statValue}>
                        {timetables.reduce((acc, curr) => acc + (curr.lectures?.length || 0), 0)}
                      </div>
                      <div className={styles.statLabel}>Total Lectures</div>
                    </div>
                  </div>
                  <div className={styles.statItem}>
                    <BusIcon className={styles.statIcon} />
                    <div>
                      <div className={styles.statValue}>
                        {timetables.reduce((acc, curr) => acc + (curr.campusBusStops?.length || 0), 0)}
                      </div>
                      <div className={styles.statLabel}>Bus Routes</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.heroRight}>
                <button 
                  className={styles.addButton}
                  onClick={handleAddTimetable}
                >
                  <PlusIcon />
                  Create New Timetable
                </button>
              </div>
            </div>
          </section>

          <section className={styles.contentSection}>
            <TransitionGroup 
              className={`${styles.grid} ${
                timetables.length === 1 ? styles.grid1 :
                timetables.length === 2 ? styles.grid2 :
                timetables.length === 3 ? styles.grid3 :
                styles.gridMany
              }`}
            >
              {timetables.map((timetable, index) => (
                timetable && timetable._id ? (
                  <CSSTransition
                    key={timetable._id}
                    timeout={500}
                    classNames={{
                      enter: styles.enter,
                      enterActive: styles.enterActive,
                      exit: styles.exit,
                      exitActive: styles.exitActive
                    }}
                  >
                    {renderTimetableCard(timetable, index)}
                  </CSSTransition>
                ) : null
              ))}
            </TransitionGroup>
          </section>
        </main>

        {/* View Timetable Modal */}
        <TimetableModal
          isOpen={!!expandedTimetable}
          onClose={handleCloseModal}
          timetable={expandedTimetable}
        />

        {/* Edit/Create Timetable Modal */}
        <TimetableForm
          isOpen={isEditModalOpen}
          onClose={() => {
            setIsEditModalOpen(false);
            setSelectedTimetable(null);
          }}
          onSubmit={async (data) => {
            await handleTimetableCreated(data);
            setIsEditModalOpen(false);
            setSelectedTimetable(null);
            fetchTimetables();
          }}
          initialData={selectedTimetable}
          isEditing={!!selectedTimetable}
        />
      </div>
    </PageTransition>
  );
}

export default Timetable;