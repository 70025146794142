import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { 
  FaBus, 
  FaWalking, 
  FaHandshake,
  FaGraduationCap,
  FaMobileAlt,
  FaBell
} from 'react-icons/fa';
import PublicFooter from '../components/PublicFooter';
import PublicHero from '../components/PublicHero';
import styles from './HowItWorks.module.css';
import animations from '../styles/animations.module.css';
import classNames from 'classnames';
import { trackNavigation, trackInteraction, trackFeature } from '../utils/analytics';

function HowItWorks() {
  const navigate = useNavigate();
  const timelineRef = useRef(null);

  useEffect(() => {
    trackNavigation.pageView('How It Works');
    const observerCallback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add(styles.visible);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.2,
      rootMargin: '0px 0px -100px 0px'
    });

    const timelineSteps = document.querySelectorAll(`.${styles.timelineStep}`);
    timelineSteps.forEach(step => observer.observe(step));

    return () => observer.disconnect();
  }, []);

  const IPhoneNotification = () => (
    <div className={styles.iphone}>
      <div className={styles.iphoneNotch}></div>
      <div className={styles.iphoneScreen}>
        <div className={styles.notificationBar}>
          <span className={styles.time}>9:41</span>
          <span>
            <FaWalking style={{ marginRight: '8px' }} />
            <FaBell />
          </span>
        </div>
        <div className={styles.notification}>
          <div className={styles.notificationHeader}>
            <div className={styles.appIcon}>
              <FaBus />
            </div>
            <span className={styles.appName}>Two Stopper</span>
            <span className={styles.notificationTime}>now</span>
          </div>
          <div className={styles.notificationContent}>
            Time to leave! 🚌 Your bus (72 to Frenchay) arrives in 12 minutes. 
            8 minute walk to the stop.
          </div>
          <div className={styles.notificationAction}>
            View Route →
          </div>
        </div>
      </div>
    </div>
  );

  const TimetableAnimation = () => (
    <div className={styles.timetablePreview}>
      <div className={styles.timetableHeader}>
        <span>Your Weekly Schedule</span>
      </div>
      <div className={styles.timetableGrid}>
        {['Mon', 'Tue', 'Wed', 'Thu', 'Fri'].map(day => (
          <div key={day} className={styles.timetableDay}>
            <div className={styles.dayHeader}>{day}</div>
            <div className={styles.lecture}></div>
            <div className={styles.lecture}></div>
          </div>
        ))}
      </div>
    </div>
  );

  const BusTrackingAnimation = () => (
    <div className={styles.computerSystem}>
      <div className={styles.computerHeader}>
        <div className={styles.computerControls}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className={styles.computerTitle}>Bus Tracking System</div>
      </div>
      <div className={styles.computerContent}>
        <div className={styles.mapSection}>
          <div className={styles.mapGrid}>
            {Array(6).fill().map((_, i) => (
              <div key={`grid-${i}`} className={styles.mapGridLine} />
            ))}
          </div>
          <div className={styles.busRoutes}>
            <div className={styles.route72}>
              <div className={styles.routeLine}></div>
            </div>
            <div className={styles.route73}>
              <div className={styles.routeLine}></div>
            </div>
          </div>
          <div className={styles.busMarker}>
            <FaBus />
            <span className={styles.busLabel}>72</span>
          </div>
          <div className={styles.busMarker2}>
            <FaBus />
            <span className={styles.busLabel}>73</span>
          </div>
          <div className={styles.stopMarker}></div>
          <div className={styles.stopMarker2}></div>
        </div>
        <div className={styles.statusSection}>
          <div className={styles.statusLine}>
            <span className={styles.statusDot}></span>
            Tracking Bus 72: On Schedule
          </div>
          <div className={styles.statusLine}>
            <span className={styles.statusDot}></span>
            Tracking Bus 73: 2 min delay
          </div>
        </div>
      </div>
    </div>
  );

  const timelineSteps = [
    {
      icon: <FaGraduationCap />,
      title: "Share Your Timetable",
      description: "Give us your weekly schedule and we'll handle the rest. We'll make sure you get to every lecture on time.",
      customContent: <TimetableAnimation />
    },
    {
      icon: <FaBus />,
      title: "We Track the Buses",
      description: "Our system continuously monitors Bristol's bus network in real-time to ensure you get the most accurate journey information.",
      customContent: <BusTrackingAnimation />
    },
    {
      icon: <FaMobileAlt />,
      title: "Get Notified When to Leave",
      description: "We'll text you when it's time to head out, taking into account real-time bus locations and walking time to your stop.",
      customContent: <IPhoneNotification />
    },
    {
      icon: <FaGraduationCap />,
      title: "Arrive at Uni On Time",
      description: "No more missed lectures or rushing around. Get to your classes stress-free and on time! 🎓"
    }
  ];

  const handleStepClick = (step) => {
    trackInteraction.buttonClick(`How It Works Step: ${step}`);
  };

  const handleGetStarted = () => {
    trackInteraction.buttonClick('Get Started from How It Works');
    navigate('/register');
  };

  return (
    <div className={styles.pageContainer}>
      <Helmet>
        <title>How Two Stopper Works | Bristol Bus Journey Planning Guide</title>
        <meta name="description" content="Learn how to use Two Stopper to plan your bus journeys in Bristol. Get real-time bus tracking, journey planning tips, and make your student commute easier." />
        <meta name="keywords" content="how to use two stopper, bristol bus journey planner guide, student bus tracking tutorial, bristol transport help" />
        <link rel="canonical" href="https://twostopper.co.uk/how-it-works" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "HowTo",
            "name": "How to Use Two Stopper",
            "description": "Guide to using Two Stopper for planning bus journeys in Bristol",
            "publisher": {
              "@type": "Organization",
              "name": "Two Stopper",
              "url": "https://twostopper.co.uk"
            },
            "step": [
              {
                "@type": "HowToStep",
                "name": "Plan Your Journey",
                "text": "Enter your start and destination to get the best bus routes"
              },
              {
                "@type": "HowToStep",
                "name": "Track in Real-Time",
                "text": "Watch your bus move in real-time on the map"
              },
              {
                "@type": "HowToStep",
                "name": "Get Updates",
                "text": "Receive live updates about your bus journey"
              }
            ],
            "dateModified": "2025-01-12T18:15:54Z"
          })}
        </script>
      </Helmet>

      <PublicHero 
        title="Your Journey Made Simple"
        description="See how Two Stopper helps you get to lectures on time, every time."
      />

      <div className={styles.contentContainer}>
        <section className={styles.timelineSection} ref={timelineRef}>
          <div className={styles.timelineLine}></div>
          {timelineSteps.map((step, index) => (
            <div 
              key={index}
              className={classNames(
                styles.timelineStep,
                index % 2 === 0 ? styles.slideInLeft : styles.slideInRight
              )}
              onClick={() => handleStepClick(index)}
            >
              <div className={styles.timelineContent}>
                <div className={styles.stepNumber}>{index + 1}</div>
                <h3 className={styles.stepTitle}>{step.title}</h3>
                <p className={styles.stepDescription}>{step.description}</p>
                {step.customContent}
              </div>
              <div className={styles.timelineIcon}>
                {step.icon}
              </div>
            </div>
          ))}
        </section>

        <section className={classNames(styles.ctaSection, animations.fadeUp, animations.delay4)}>
          <h2 className={styles.ctaTitle}>Ready to Transform Your Commute?</h2>
          <p className={styles.ctaDescription}>Join thousands of Bristol students who are already using Two Stopper to make their journeys easier.</p>
          <button 
            className={styles.ctaButton}
            onClick={handleGetStarted}
          >
            Get Started Now
          </button>
        </section>
      </div>

      <PublicFooter />
    </div>
  );
}

export default HowItWorks;
