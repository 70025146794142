import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FaEnvelope, FaInstagram, FaMapMarkerAlt } from 'react-icons/fa';
import PublicFooter from '../components/PublicFooter';
import PublicHero from '../components/PublicHero';
import styles from './Contact.module.css';
import animations from '../styles/animations.module.css';
import classNames from 'classnames';

function Contact() {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "ContactPage",
    "name": "Contact Two Stopper | Bristol Student Bus App Support",
    "description": "Get in touch with Two Stopper's support team. We're here to help with any questions about our Bristol bus tracking and journey planning service.",
    "mainEntity": {
      "@type": "Organization",
      "name": "Two Stopper",
      "contactPoint": {
        "@type": "ContactPoint",
        "email": "info@twostopper.co.uk",
        "contactType": "customer service"
      }
    }
  };

  return (
    <div className={styles.pageContainer}>
      <Helmet>
        <title>Contact Two Stopper | Bristol Student Bus App Support</title>
        <meta name="description" content="Need help with Two Stopper? Contact our support team for assistance with the Bristol bus tracking app. We're here to help improve your journey planning experience." />
        <meta name="keywords" content="two stopper contact, bristol bus app support, student transport help, bus tracking support, bristol transport contact" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <PublicHero 
        title="Get in Touch"
        description="Have questions or suggestions? We're here to help make your journey better."
      />

      <div className={styles.contentContainer}>
        <div className={classNames(styles.contactGrid, animations.fadeUp, animations.delay1)}>
          <div className={styles.contactCard}>
            <FaEnvelope className={styles.contactIcon} />
            <h3>Email Us</h3>
            <p>For general inquiries and support</p>
            <a href="mailto:info@twostopper.co.uk" className={styles.contactLink}>
              info@twostopper.co.uk
            </a>
          </div>

          <div className={styles.contactCard}>
            <FaInstagram className={styles.contactIcon} />
            <h3>Follow Us</h3>
            <p>Stay updated with latest news</p>
            <a href="https://instagram.com/twostopper" target="_blank" rel="noopener noreferrer" className={styles.contactLink}>
              @twostopper
            </a>
          </div>

          <div className={styles.contactCard}>
            <FaMapMarkerAlt className={styles.contactIcon} />
            <h3>Location</h3>
            <p>Based in Bristol, UK</p>
            <span className={styles.location}>Serving UWE and University of Bristol</span>
          </div>
        </div>

        <div className={classNames(styles.faqSection, animations.fadeUp, animations.delay2)}>
          <h2>Frequently Asked Questions</h2>
          <div className={styles.faqGrid}>
            <div className={styles.faqItem}>
              <h3>How do I report a bug?</h3>
              <p>Email us at support@twostopper.co.uk with details about the issue you're experiencing. Screenshots are always helpful!</p>
            </div>
            <div className={styles.faqItem}>
              <h3>Is Two Stopper free?</h3>
              <p>Yes! Two Stopper is completely free for all Bristol university students.</p>
            </div>
            <div className={styles.faqItem}>
              <h3>How accurate is the tracking?</h3>
              <p>Our real-time tracking uses First Bus's official data, providing accurate arrival times and live bus locations.</p>
            </div>
            <div className={styles.faqItem}>
              <h3>Which bus routes do you cover?</h3>
              <p>We cover all First Bus routes in Bristol, with special focus on university routes like the U1, U2, and U3.</p>
            </div>
          </div>
        </div>

        <div className={classNames(styles.feedbackSection, animations.fadeUp, animations.delay3)}>
          <h2>Help Us Improve</h2>
          <p>Your feedback helps us make Two Stopper better. Share your thoughts, suggestions, or report issues - we're always listening!</p>
          <a href="mailto:feedback@twostopper.co.uk" className={styles.feedbackButton}>
            Send Feedback
          </a>
        </div>
      </div>

      <PublicFooter />
    </div>
  );
}

export default Contact;
