// BusJourneyModal.js
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { motion, AnimatePresence } from 'framer-motion';
import { format } from 'date-fns';
import axiosInstance from '../utils/axiosInstance';
import { getCurrentPosition, findNearestBusStop } from '../utils/locationUtils';
import { trackNavigation, trackFeature, trackError, trackInteraction } from '../utils/analytics';
import {
  IoClose,
  IoBusOutline,
  IoWalkOutline,
  IoTimeOutline,
  IoCheckmarkCircleOutline,
  IoSwapHorizontalOutline,
  IoArrowBack,
  IoFlagOutline,
  IoLocateOutline,
  IoEllipseOutline,
  IoFilterOutline,
  IoArrowForwardOutline,
  IoNavigateOutline,
  IoLocationOutline,
  IoNotificationsOutline
} from 'react-icons/io5';
import styles from './BusJourneyModal.module.css';
import modalStyles from '../styles/Modal.module.css';

const customModalStyles = {
  overlay: {
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'flex-end',
    padding: 0
  },
  content: {
    position: 'relative',
    width: '100%',
    maxHeight: `calc(90vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))`,
    margin: `env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)`,
    padding: 0,
    border: 'none',
    borderRadius: '12px 12px 0 0',
    background: 'var(--background-color)',
    overflow: 'hidden',
    bottom: 'env(safe-area-inset-bottom)'
  }
};

const BusJourneyModal = ({
  isOpen,
  onClose,
  busData = [],
  direction = 'outbound',
  homeBusData = [],
  campus,
  stopId,
  stopName,
  authToken,
  settings
}) => {
  const [journeys, setJourneys] = useState([]);
  const [selectedJourney, setSelectedJourney] = useState(null);
  const [view, setView] = useState('list'); // 'list' or 'detail'
  const [busStopInfo, setBusStopInfo] = useState(null);
  const [isNotifying, setIsNotifying] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState(null);

  useEffect(() => {
    trackNavigation.modalOpen('Bus Journey Details');
    const fetchBusStopInfo = async () => {
      try {
        const response = await axiosInstance.get('/api/timetable/current');
        console.log('API Response:', response.data);
        
        if (response.data?.campusBusStops) {
          const campusStops = response.data.campusBusStops.find(
            stops => stops.campus === campus
          );
          console.log('Found campus stops:', campusStops);
          
          if (campusStops) {
            console.log('Setting busStopInfo:', campusStops);
            setBusStopInfo(campusStops);
          }
        }
      } catch (error) {
        console.error('Error fetching bus stop info:', error);
        trackError.apiError('bus-journey/fetch-stops', error.message);
      }
    };

    if (isOpen) {
      fetchBusStopInfo();
    }
  }, [isOpen, campus]);

  useEffect(() => {
    // Update journeys when props change
    const currentJourneys = direction === 'outbound' ? busData : homeBusData;
    setJourneys(currentJourneys || []);
  }, [direction, busData, homeBusData]);

  const getLocationName = (type, direction) => {
    if (!busStopInfo) return '';

    if (direction === 'outbound') {
      if (type === 'from' && settings?.useCurrentLocation) {
        return 'Current Location';
      }
      return type === 'from' ? busStopInfo.outboundStop.name : campus;
    } else {
      return type === 'from' ? campus : 'Home';
    }
  };

  const handleJourneyClick = (journey) => {
    trackInteraction.buttonClick('Select Journey');
    setSelectedJourney(journey);
    setView('detail');
  };

  const handleBackClick = () => {
    trackInteraction.buttonClick('Back to Journey List');
    setView('list');
    setSelectedJourney(null);
  };

  const formatTime = (timeString) => {
    if (!timeString) return '';
    try {
      // Check if timeString is already in HH:mm format
      if (/^\d{2}:\d{2}$/.test(timeString)) {
        return timeString;
      }
      // Try to parse the date
      const date = new Date(timeString);
      if (isNaN(date.getTime())) {
        return '';
      }
      return format(date, 'HH:mm');
    } catch (error) {
      console.error('Error formatting time:', error);
      trackError.apiError('bus-journey/format-time', error.message);
      return '';
    }
  };

  const handleLocationClick = (type) => {
    // Only handle clicks for the 'from' location when it's not current location
    if (type !== 'from' || !busStopInfo || settings?.useCurrentLocation) return;
    
    const stopLocation = busStopInfo.outboundStop?.location;

    // Handle GeoJSON format where coordinates are [longitude, latitude]
    if (stopLocation?.type === 'Point' && Array.isArray(stopLocation.coordinates)) {
      const [longitude, latitude] = stopLocation.coordinates;
      const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
      window.open(url, '_blank');
      trackInteraction.buttonClick('View Bus Stop on Map');
    }
  };

  const handleNotificationClick = async () => {
    if (!selectedJourney || isNotifying) return;
    
    setIsNotifying(true);
    setNotificationStatus(null);
    
    try {
      const response = await axiosInstance.post('/api/notifications', {
        journey: {
          ...selectedJourney,
          origin: getLocationName('from', direction),
          destination: getLocationName('to', direction)
        }
      });
      
      setNotificationStatus({ type: 'success', message: 'Journey notification set!' });
      trackInteraction.buttonClick('Set Journey Notification');
    } catch (error) {
      console.error('Error setting notification:', error);
      setNotificationStatus({ 
        type: 'error', 
        message: error.response?.data?.message || 'Failed to set notification'
      });
      trackError.apiError('journey-notification', error.message);
    } finally {
      setIsNotifying(false);
    }
  };

  const formatJourneySteps = (journey) => {
    const steps = [];
    
    // Calculate total walking time from all FOOT/WALK segments
    const walkingTime = journey.routeParts
      .filter(part => part.mode === 'FOOT' || part.mode === 'WALK')
      .reduce((total, part) => total + parseInt(part.duration || 0), 0);

    // Calculate total journey time
    const totalTime = parseInt(journey.duration || 0);
    
    // Calculate number of changes (bus segments - 1)
    const busSegments = journey.routeParts.filter(part => part.mode === 'BUS');
    const changes = Math.max(0, busSegments.length - 1);
    
    // Add journey summary
    steps.push({
      time: journey.departureTime,
      location: direction === 'outbound' ? (stopName || journey.routeParts[0].from) : campus,
      coordinates: journey.routeParts[0].coordinates,
      instruction: direction === 'outbound' 
        ? `Start from ${stopName || journey.routeParts[0].from} bus stop`
        : `Start from ${campus}`,
      summary: {
        totalTime: totalTime || 0,
        walkingTime: walkingTime || 0,
        changes: changes
      }
    });

    // Add steps from route parts
    journey.routeParts.forEach((part, index) => {
      if (part.mode === 'FOOT' || part.mode === 'WALK') {
        const destination = direction === 'outbound' 
          ? (index === journey.routeParts.length - 1 ? campus : part.to)
          : (index === journey.routeParts.length - 1 ? 'Home' : part.to);
        steps.push({
          time: part.departureTime,
          arrivalTime: part.arrivalTime,
          location: destination,
          coordinates: part.coordinates,
          instruction: `Walk ${parseInt(part.duration || 0)} mins to ${destination}`,
          duration: parseInt(part.duration || 0),
          mode: 'WALK',
          isWalking: true
        });
      } else if (part.mode === 'BUS') {
        // Add boarding step
        steps.push({
          time: part.departureTime,
          arrivalTime: part.arrivalTime,
          location: part.from,
          coordinates: part.coordinates,
          instruction: `Board service ${part.lineName || part.line}`,
          service: part.lineName || part.line,
          duration: parseInt(part.duration || 0),
          mode: 'BUS',
          isBus: true,
          to: part.to
        });

        // Add alighting step
        const nextPart = journey.routeParts[index + 1];
        let instruction = `Get off at ${part.to}`;
        if (nextPart && (nextPart.mode === 'FOOT' || nextPart.mode === 'WALK')) {
          const destination = direction === 'outbound'
            ? (index === journey.routeParts.length - 2 ? campus : nextPart.to)
            : (index === journey.routeParts.length - 2 ? 'Home' : nextPart.to);
          instruction += ` and walk ${parseInt(nextPart.duration || 0)} mins to ${destination}`;
        }
        
        steps.push({
          time: part.arrivalTime,
          location: part.to,
          coordinates: part.coordinates,
          instruction: instruction,
          mode: 'BUS_STOP',
          from: part.from,
          service: part.lineName || part.line
        });
      }
    });

    // Add final destination if not already added
    const lastStep = steps[steps.length - 1];
    const finalDestination = direction === 'outbound' ? campus : 'Home';
    if (lastStep && lastStep.location !== finalDestination) {
      steps.push({
        time: journey.arrivalTime,
        location: finalDestination,
        coordinates: journey.routeParts[journey.routeParts.length - 1].coordinates,
        instruction: direction === 'outbound' 
          ? `Arrive at ${campus}`
          : 'Arrive at Home',
        mode: 'DESTINATION'
      });
    }

    return steps;
  };

  const renderJourneyStep = (step, idx, steps) => {
    const isFirstStep = idx === 0;
    const isLastStep = idx === steps.length - 1;
    const isWalking = step.mode === 'WALK';
    const isBus = step.mode === 'BUS';

    return (
      <div key={idx} className={styles.journeyStep}>
        <div className={styles.stepIcon}>
          {isWalking ? (
            <IoWalkOutline className={styles.icon} />
          ) : isBus ? (
            <IoBusOutline className={styles.icon} />
          ) : (
            <IoEllipseOutline className={styles.icon} />
          )}
        </div>
        <div className={styles.stepContent}>
          <div className={styles.stepHeader}>
            <div className={styles.stepTimes}>
              <div className={styles.stepTime}>{formatTime(step.time)}</div>
              {step.arrivalTime && (
                <>
                  <div className={styles.stepTimeArrow}>→</div>
                  <div className={styles.stepTime}>{formatTime(step.arrivalTime)}</div>
                </>
              )}
            </div>
            <div className={styles.stepInstruction}>{step.instruction}</div>
          </div>
          {step.location && !isLastStep && (
            <div className={styles.busStopName}>
              <IoLocationOutline className={styles.locationIcon} />
              {step.location}
            </div>
          )}
          {step.service && step.mode === 'BUS' && (
            <div className={styles.busInfo}>
              <span className={styles.busNumber}>Service {step.service}</span>
              {step.to && (
                <>
                  <IoArrowForwardOutline className={styles.icon} />
                  <span className={styles.busDirection}>to {step.to}</span>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderJourneyCard = (journey) => {
    const isDirect = journey.changes === 0;
    const walkingTime = journey.routeParts
      .filter(part => part.mode === 'FOOT' || part.mode === 'WALK')
      .reduce((total, part) => total + parseInt(part.duration || 0), 0);
    
    return (
      <motion.div
        className={styles.journeyCard}
        onClick={() => handleJourneyClick(journey)}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
      >
        <div className={styles.journeyMain}>
          <div className={styles.journeyService}>
            <div className={styles.serviceBadge}>
              {journey.service}
            </div>
            <div className={styles.departureTime}>
              {formatTime(journey.departureTime)}
            </div>
          </div>
          <div className={styles.journeyMeta}>
            <div className={styles.duration}>
              <IoTimeOutline />
              {journey.duration} mins
            </div>
            {isDirect ? (
              <div className={styles.directBadge}>
                Direct
              </div>
            ) : (
              <div className={styles.changesBadge}>
                <IoSwapHorizontalOutline />
                {journey.changes} {journey.changes === 1 ? 'change' : 'changes'}
              </div>
            )}
          </div>
        </div>
        {walkingTime > 0 && (
          <div className={styles.journeyDetails}>
            <div className={styles.walkingTime}>
              <IoWalkOutline />
              {walkingTime} mins walking
            </div>
          </div>
        )}
      </motion.div>
    );
  };

  const renderJourneyList = () => (
    <motion.div
      key="list"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ 
        type: "spring",
        stiffness: 300,
        damping: 30,
        duration: 0.3
      }}
      className={styles.journeyList}
    >
      <div className={styles.locationSelector}>
        <div 
          className={`${styles.locationItem} ${busStopInfo && !settings?.useCurrentLocation ? styles.clickable : ''}`}
          onClick={() => handleLocationClick('from')}
        >
          <div className={styles.locationIcon}>
            <div className={styles.dotIcon} />
          </div>
          <div className={styles.locationText}>
            {getLocationName('from', direction)}
          </div>
        </div>
        <div className={styles.locationDivider} />
        <div className={styles.locationItem}>
          <div className={styles.locationIcon}>
            <div className={styles.pinIcon} />
          </div>
          <div className={styles.locationText}>
            {getLocationName('to', direction)}
          </div>
        </div>
      </div>

      {journeys.map((journey, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.05 }}
          className={styles.journeyCardWrapper}
        >
          {renderJourneyCard(journey)}
        </motion.div>
      ))}
    </motion.div>
  );

  const renderJourneyDetail = (journey) => {
    const steps = formatJourneySteps(journey);
    const firstStep = steps[0];
    const lastStep = steps[steps.length - 1];

    return (
      <div className={styles.journeyDetail}>
        <div className={styles.journeyOverview}>
          <div className={styles.journeyTimes}>
            <div className={styles.timeColumn}>
              <span className={styles.timeLabel}>Departure</span>
              <span className={styles.timeValue}>{formatTime(journey.departureTime)}</span>
            </div>
            <div className={styles.timeColumn}>
              <span className={styles.timeLabel}>Arrival</span>
              <span className={styles.timeValue}>{formatTime(journey.arrivalTime)}</span>
            </div>
          </div>

          <div className={styles.journeyStats}>
            <div className={styles.statItem}>
              <IoTimeOutline className={styles.statIcon} />
              <span className={styles.statValue}>{firstStep.summary.totalTime} mins</span>
            </div>
            <div className={styles.statItem}>
              <IoWalkOutline className={styles.statIcon} />
              <span className={styles.statValue}>{firstStep.summary.walkingTime} mins</span>
            </div>
            <div className={styles.statItem}>
              <IoSwapHorizontalOutline className={styles.statIcon} />
              <span className={styles.statValue}>
                {firstStep.summary.changes === 0 ? 'Direct' : `${firstStep.summary.changes} ${firstStep.summary.changes === 1 ? 'change' : 'changes'}`}
              </span>
            </div>
          </div>

          <button
            className={`${styles.sendToPhoneButton} ${isNotifying ? styles.loading : ''} ${
              notificationStatus?.type === 'success' ? styles.success : 
              notificationStatus?.type === 'error' ? styles.error : ''
            }`}
            onClick={handleNotificationClick}
            disabled={isNotifying}
          >
            <div className={styles.buttonContent}>
              {isNotifying ? (
                <div className={styles.spinner} />
              ) : notificationStatus?.type === 'success' ? (
                <IoCheckmarkCircleOutline className={styles.icon} />
              ) : (
                <IoNotificationsOutline className={styles.icon} />
              )}
              <span>
                {isNotifying ? 'Sending...' : 
                 notificationStatus?.type === 'success' ? 'Sent!' : 
                 'Send to Phone'}
              </span>
            </div>
          </button>
          {notificationStatus && (
            <div className={`${styles.notificationStatus} ${styles[notificationStatus.type]}`}>
              {notificationStatus.message}
            </div>
          )}
        </div>

        <div className={styles.timeline}>
          {steps.map((step, idx) => (
            <div key={idx} className={styles.journeyStep}>
              <div className={styles.stepIcon}>
                {step.mode === 'WALK' && <IoWalkOutline className={styles.icon} />}
                {step.mode === 'BUS' && <IoBusOutline className={styles.icon} />}
                {step.mode === 'BUS_STOP' && <IoEllipseOutline className={styles.icon} />}
                {step.mode === 'DESTINATION' && <IoFlagOutline className={styles.icon} />}
              </div>
              <div className={styles.stepContent}>
                <div className={styles.stepHeader}>
                  <div className={styles.stepTimes}>
                    <div className={styles.stepTime}>{formatTime(step.time)}</div>
                    {step.arrivalTime && (
                      <>
                        <div className={styles.stepTimeArrow}>→</div>
                        <div className={styles.stepTime}>{formatTime(step.arrivalTime)}</div>
                      </>
                    )}
                  </div>
                  <div className={styles.stepInstruction}>{step.instruction}</div>
                </div>
                {step.service && step.mode === 'BUS' && (
                  <div className={styles.busInfo}>
                    <span className={styles.busNumber}>Service {step.service}</span>
                    {step.to && (
                      <>
                        <IoArrowForwardOutline className={styles.icon} />
                        <span className={styles.busDirection}>to {step.to}</span>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderJourneyDetailWrapper = () => {
    if (!selectedJourney) return null;

    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className={styles.journeyDetailWrapper}
      >
        {renderJourneyDetail(selectedJourney)}
      </motion.div>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customModalStyles}
      contentLabel="Bus Journey Details"
    >
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <div className={styles.headerContent}>
            <button 
              className={styles.closeButton} 
              onClick={view === 'detail' ? handleBackClick : onClose}
              aria-label={view === 'detail' ? 'Back' : 'Close'}
            >
              {view === 'detail' ? (
                <IoArrowBack className={styles.icon} />
              ) : (
                <IoClose className={styles.icon} />
              )}
            </button>
            <div className={styles.headerText}>
              <h2>{view === 'detail' ? 'Journey Details' : 'Available Journeys'}</h2>
            </div>
          </div>
          {settings && (Object.values(settings).some(setting => setting)) && (
            <div className={styles.settingsBadges}>
              {settings.directBusesOnly && (
                <div className={styles.settingBadge} title="Direct buses only">
                  <IoFilterOutline className={styles.settingIcon} />
                  <span>Direct only</span>
                </div>
              )}
              {settings.prioritiseEarliestArrival && (
                <div className={styles.settingBadge} title="Prioritising earliest arrival">
                  <IoTimeOutline className={styles.settingIcon} />
                  <span>Earliest arrival</span>
                </div>
              )}
            </div>
          )}
        </div>
        <div className={styles.content}>
          <AnimatePresence mode="wait">
            {view === 'list' ? renderJourneyList() : renderJourneyDetailWrapper()}
          </AnimatePresence>
        </div>
      </div>
    </Modal>
  );
};

export default BusJourneyModal;