// src/EditTimetable.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from './utils/axiosInstance';
import TimetableForm from './components/TimetableForm';
import { toast } from 'react-toastify';
import Sidebar from './components/Sidebar';
import './Home.css';
import LoadingScreen from './components/LoadingScreen';

function EditTimetable() {
  const [timetable, setTimetable] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchTimetable = async () => {
      try {
        const response = await axiosInstance.get(`/api/timetable/${id}`);
        console.log('Timetable response:', response);

        if (response.data && response.data.success !== false) {
          const timetableData = response.data.timetable || response.data;
          
          // Transform the data to match the form structure
          const formattedTimetable = {
            name: timetableData.name || '',
            university: timetableData.university ? { value: timetableData.university, label: timetableData.university } : null,
            selectedCampuses: timetableData.campuses ? timetableData.campuses.map(campus => ({ value: campus, label: campus })) : [],
            lectures: (timetableData.lectures || []).map(lecture => ({
              ...lecture,
              campus: lecture.campus || '',
              day: lecture.day || '',
              time: lecture.time || '',
              name: lecture.name || ''
            })),
            route: timetableData.route || null,
            timetable: timetableData,
            outboundBusStop: timetableData.busStop ? {
              value: timetableData.busStop.NaptanCode,
              label: timetableData.busStop.Name,
              Name: timetableData.busStop.Name,
            } : null,
            returnBusStop: timetableData.returnBusStop ? {
              value: timetableData.returnBusStop.NaptanCode,
              label: timetableData.returnBusStop.Name,
              Name: timetableData.returnBusStop.Name,
            } : null,
            walkTime: timetableData.walkTime || '',
            // Add optimized results if they exist
            optimisedResults: timetableData.optimisedResults || [],
            selectedRoute: timetableData.selectedRoute || null
          };
          
          setTimetable(formattedTimetable);
          setError('');
        } else {
          throw new Error(response.data?.message || 'Timetable not found');
        }
      } catch (err) {
        const errorMessage = err.response?.data?.message || err.message || 'Failed to fetch timetable';
        toast.error(errorMessage);
        setError(errorMessage);
        console.error('Error fetching timetable:', err);
        if (err.response?.status === 404) {
          navigate('/timetable', { replace: true });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchTimetable();
  }, [id]);

  return (
    <div className="home-layout">
      <Sidebar />
      <div className="main-content">
        <div className="container">
          {timetable && (
            <TimetableForm 
              isOpen={isModalOpen}
              onClose={() => {
                setIsModalOpen(false);
                navigate('/timetable');
              }}
              onSubmit={async (formData) => {
                try {
                  console.log('Submitting update to timetable:', id);
                  
                  // Format the data for the API
                  const apiData = {
                    name: formData.timetableName,
                    university: formData.university?.value,
                    campuses: formData.selectedCampuses.map(campus => campus.value),
                    lectures: formData.lectures.map(lecture => ({
                      name: lecture.name,
                      day: lecture.day,
                      time: lecture.time,
                      campus: lecture.campus
                    })),
                    busStop: formData.outboundBusStop ? {
                      NaptanCode: formData.outboundBusStop.value,
                      Name: formData.outboundBusStop.Name
                    } : null,
                    returnBusStop: formData.returnBusStop ? {
                      NaptanCode: formData.returnBusStop.value,
                      Name: formData.returnBusStop.Name
                    } : null,
                    walkTime: formData.walkTime || 0
                  };

                  await axiosInstance.put(`/api/timetable/${id}`, apiData);
                  toast.success('Timetable updated successfully!');
                  setIsModalOpen(false);
                  navigate('/timetable');
                } catch (error) {
                  console.error('Error updating timetable:', error);
                  toast.error(error.response?.data?.msg || 'Failed to update timetable');
                  throw error;
                }
              }}
              initialData={timetable}
              isEditing={true}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default EditTimetable;