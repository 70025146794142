import React from 'react';
import PropTypes from 'prop-types';
import styles from './PublicHero.module.css';

const PublicHero = ({ title, description }) => {
  return (
    <div className={styles.heroContainer}>
      <div className={styles.heroBackground}></div>
      <div className={styles.heroContent}>
        <h1 className={styles.heroTitle}>{title}</h1>
        {description && (
          <p className={styles.heroDescription}>{description}</p>
        )}
      </div>
    </div>
  );
};

PublicHero.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string
};

export default PublicHero;
