import React from 'react';
import { IoSchoolOutline, IoLocationOutline, IoTodayOutline } from 'react-icons/io5';
import styles from './LectureCard.module.css';

const LectureCard = ({ lecture }) => {
  if (!lecture) {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <IoSchoolOutline className={styles.icon} />
          <h3>Next Lecture</h3>
        </div>
        <div className={styles.noData}>
          No upcoming lectures
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <IoSchoolOutline className={styles.icon} />
        <h3>Next Lecture</h3>
      </div>
      <div className={styles.content}>
        <div className={styles.timeDisplay}>
          <div className={styles.timeInfo}>
            <span className={styles.time}>{lecture.time}</span>
            {lecture.isNextDay && (
              <span className={styles.badge}>
                {lecture.daysUntil === 1 ? 'Tomorrow' : lecture.day}
              </span>
            )}
            {lecture.isNextWeek && (
              <span className={styles.badge}>Next {lecture.day}</span>
            )}
          </div>
          <span className={styles.lectureName}>{lecture.name}</span>
        </div>
        <div className={styles.details}>
          <div className={styles.detail}>
            <IoLocationOutline className={styles.detailIcon} />
            <span>Campus: {lecture.campus}</span>
          </div>
          <div className={styles.detail}>
            <IoTodayOutline className={styles.detailIcon} />
            <span>Day: {lecture.day}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LectureCard;
