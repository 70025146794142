import React, { createContext, useState, useEffect, useCallback } from 'react';
import axiosInstance from '../utils/axiosInstance';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({ isAuthenticated: false, loading: true, user: null });
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);

  // Function to check authentication status
  const checkAuth = useCallback(async () => {
    if (isUpdatingProfile) return;
    
    console.log('AuthContext: Running checkAuth...');
    const token = localStorage.getItem('token');
    
    if (!token) {
      console.log('AuthContext: No token found');
      setAuth({ isAuthenticated: false, loading: false, user: null });
      return;
    }

    try {
      // First verify the token is valid
      const tokenParts = token.split('.');
      if (tokenParts.length !== 3) {
        console.log('AuthContext: Invalid token format');
        localStorage.removeItem('token');
        setAuth({ isAuthenticated: false, loading: false, user: null });
        return;
      }

      // Check token expiration
      const payload = JSON.parse(atob(tokenParts[1]));
      if (payload.exp * 1000 < Date.now()) {
        console.log('AuthContext: Token expired');
        localStorage.removeItem('token');
        setAuth({ isAuthenticated: false, loading: false, user: null });
        return;
      }

      const res = await axiosInstance.get('/api/user/profile');
      console.log('AuthContext: Authentication successful:', res.data);
      
      if (!isUpdatingProfile) {
        setAuth({ isAuthenticated: true, loading: false, user: res.data });
      }
    } catch (err) {
      console.error('AuthContext: Authentication check failed:', err.response?.data || err);
      localStorage.removeItem('token');
      setAuth({ isAuthenticated: false, loading: false, user: null });
    }
  }, [isUpdatingProfile]);

  // Function to log out the user
  const logout = async () => {
    console.log('AuthContext: Logging out...');
    try {
      await axiosInstance.post('/api/auth/logout');
      console.log('AuthContext: Logout successful.');
    } catch (err) {
      console.error('AuthContext: Logout failed:', err.response?.data || err);
    } finally {
      localStorage.removeItem('token');
      setAuth({ isAuthenticated: false, loading: false, user: null });
    }
  };

  // Function to toggle notifications
  const toggleNotifications = useCallback(async () => {
    if (!auth.user || isUpdatingProfile) return false;
    
    try {
      setIsUpdatingProfile(true);
      const newNotificationState = !auth.user.notifications;
      
      // Make API call to update user preferences
      const response = await axiosInstance.put('/api/user/profile', {
        notifications: newNotificationState
      });

      if (!response.data) {
        throw new Error('No response data received');
      }

      // Update the auth context with the response data
      setAuth(prev => ({
        ...prev,
        user: response.data
      }));
      
      setIsUpdatingProfile(false);
      return true;
    } catch (error) {
      console.error('Failed to toggle notifications:', error);
      setIsUpdatingProfile(false);
      return false;
    }
  }, [auth.user, isUpdatingProfile]);

  // Function to update journey planner settings
  const updateJourneySettings = useCallback(async (settings) => {
    if (!auth.user || isUpdatingProfile) return false;
    
    try {
      setIsUpdatingProfile(true);
      
      // Convert settings to expected format
      const settingsPayload = {
        directBuses: settings.directBusesOnly,
        earliestArrival: settings.prioritiseEarliestArrival
      };
      
      // Make API call to update journey planner settings
      const response = await axiosInstance.post('/api/settings/journey-planner', settingsPayload);

      if (!response.data) {
        setIsUpdatingProfile(false);
        throw new Error('No response data received');
      }

      // Update the auth context with the new settings
      setAuth(prev => ({
        ...prev,
        user: {
          ...prev.user,
          settings: {
            ...prev.user.settings,
            ...settings
          }
        }
      }));
      
      // Trigger a global event to notify components about settings change
      window.dispatchEvent(new CustomEvent('journeySettingsChanged', { detail: settings }));
      
      setIsUpdatingProfile(false);
      return true;
    } catch (error) {
      // Only log out if it's an authentication error
      if (error.response?.status === 401) {
        console.error('Authentication error while updating journey settings:', error);
        localStorage.removeItem('token');
        setAuth({ isAuthenticated: false, loading: false, user: null });
      } else {
        console.error('Failed to update journey settings:', error);
      }
      setIsUpdatingProfile(false);
      return false;
    }
  }, [auth.user, isUpdatingProfile]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      setAuth({ isAuthenticated: false, loading: false, user: null });
    } else {
      checkAuth();
    }
  }, [checkAuth]);

  return (
    <AuthContext.Provider value={{
      isAuthenticated: auth.isAuthenticated,
      loading: auth.loading,
      user: auth.user,
      toggleNotifications,
      updateJourneySettings,
      logout,
      checkAuth
    }}>
      {children}
    </AuthContext.Provider>
  );
};