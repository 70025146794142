import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';
import { toast } from 'react-toastify';
import { 
  IoCalendarOutline, 
  IoArrowForwardOutline, 
  IoDownloadOutline,
  IoCheckmarkCircleOutline 
} from 'react-icons/io5';
import styles from './WelcomeModal.module.css';

Modal.setAppElement('#root');

const WelcomeModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [contactSaved, setContactSaved] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleContactSave = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setContactSaved(true);
      setIsAnimating(false);
    }, 300);
  };

  const handleCreateTimetable = () => {
    onClose();
    // Navigate to /timetable and set state to open form
    navigate('/timetable', { state: { openTimetableForm: true } });
  };

  const handleSkip = () => {
    if (!contactSaved) {
      setIsAnimating(true);
      setTimeout(() => {
        setContactSaved(true);
        setIsAnimating(false);
      }, 300);
    } else {
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={{
        base: styles.modal,
        afterOpen: styles.modalAfterOpen,
        beforeClose: styles.modalBeforeClose
      }}
      overlayClassName={{
        base: styles.overlay,
        afterOpen: styles.overlayAfterOpen,
        beforeClose: styles.overlayBeforeClose
      }}
      closeTimeoutMS={300}
    >
      <div className={styles.content}>
        <div className={`${styles.icon} ${isAnimating ? styles.iconExit : ''}`}>
          {contactSaved ? <IoCheckmarkCircleOutline /> : <IoCalendarOutline />}
        </div>
        <h2 className={styles.title}>Welcome to Two Stopper</h2>
        <p className={styles.description}>Let's get you set up with everything you need for a smooth journey.</p>
        
        <div className={styles.stepsContainer}>
          {!contactSaved ? (
            <div className={`${styles.step} ${isAnimating ? styles.stepExit : ''}`}>
              <h3 className={styles.subtitle}>Step 1: Save Our Contact</h3>
              <p className={styles.stepDescription}>Add us to your contacts to receive SMS notifications about your bus.</p>
              <a 
                href="/contact.vcf" 
                download="contact.vcf" 
                className={styles.button}
                onClick={handleContactSave}
              >
                <IoDownloadOutline /> Save Contact
              </a>
              <button onClick={handleSkip} className={styles.skipButton}>
                Skip this step
              </button>
            </div>
          ) : (
            <div className={styles.step}>
              <h3 className={styles.subtitle}>Step 2: Create Your Timetable</h3>
              <p className={styles.stepDescription}>Set up your timetable to get personalized bus recommendations.</p>
              <button onClick={handleCreateTimetable} className={styles.button}>
                <IoArrowForwardOutline /> Create Timetable
              </button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default WelcomeModal;
