import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FaBus, FaBell, FaExclamationTriangle, FaClock, FaFilter } from 'react-icons/fa';
import PublicFooter from '../components/PublicFooter';
import PublicHero from '../components/PublicHero';
import styles from './PublicBusAlerts.module.css';
import animations from '../styles/animations.module.css';
import classNames from 'classnames';

function PublicBusAlerts() {
  const [activeFilter, setActiveFilter] = useState('all');
  
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Bristol Bus Service Updates",
    "description": "Real-time Bristol bus service updates and alerts",
    "publisher": {
      "@type": "Organization",
      "name": "Two Stopper",
      "url": "https://twostopper.co.uk"
    },
    "mainEntity": {
      "@type": "ItemList",
      "itemListElement": [
        {
          "@type": "Article",
          "name": "Service Updates",
          "description": "Latest First Bus service updates in Bristol"
        },
        {
          "@type": "Article",
          "name": "Route Alerts",
          "description": "Current route diversions and changes"
        }
      ]
    },
    "dateModified": "2025-01-12T18:15:54Z"
  };

  const alerts = [
    {
      id: 1,
      route: "U3",
      severity: "high",
      title: "Service Disruption",
      message: "Service temporarily diverted via Gloucester Road due to roadworks on Whiteladies Road",
      timestamp: "10 minutes ago",
      category: "uwe"
    },
    {
      id: 2,
      route: "48",
      severity: "medium",
      title: "Minor Delays",
      message: "Delays of up to 10 minutes due to heavy traffic around Cabot Circus",
      timestamp: "15 minutes ago",
      category: "uob"
    },
    {
      id: 3,
      route: "U1",
      severity: "low",
      title: "Service Update",
      message: "Service running on schedule. Next departure from Frenchay Campus in 5 minutes",
      timestamp: "2 minutes ago",
      category: "uwe"
    },
    {
      id: 4,
      route: "U2",
      severity: "medium",
      title: "Weather Advisory",
      message: "Possible delays due to adverse weather conditions affecting all services",
      timestamp: "20 minutes ago",
      category: "uwe"
    },
    {
      id: 5,
      route: "9",
      severity: "low",
      title: "Service Running",
      message: "All services operating normally on this route",
      timestamp: "5 minutes ago",
      category: "uob"
    }
  ];

  const filteredAlerts = alerts.filter(alert => 
    activeFilter === 'all' || alert.category === activeFilter
  );

  const getSeverityIcon = (severity) => {
    switch (severity) {
      case 'high':
        return <FaExclamationTriangle className={styles.severityIconHigh} />;
      case 'medium':
        return <FaExclamationTriangle className={styles.severityIconMedium} />;
      case 'low':
        return <FaClock className={styles.severityIconLow} />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.pageContainer}>
      <Helmet>
        <title>Bristol Bus Service Updates | Two Stopper Live Alerts</title>
        <meta name="description" content="Get real-time Bristol bus service updates and alerts. Stay informed about delays, diversions, and changes to First Bus services through Two Stopper." />
        <meta name="keywords" content="bristol bus alerts, first bus updates, student transport news, bristol bus service status" />
        <link rel="canonical" href="https://twostopper.co.uk/bus-alerts" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <PublicHero 
        title="Live Bus Alerts"
        description="Stay updated with real-time alerts and service changes for Bristol student bus routes."
      />

      <div className={classNames(styles.contentContainer, animations.fadeUp, animations.delay1)}>
        <div className={classNames(styles.alertsHeader, animations.fadeUp, animations.delay2)}>
          <div className={styles.lastUpdated}>
            <FaClock className={styles.clockIcon} />
            Last updated: 2 minutes ago
          </div>
          <div className={styles.filterButtons}>
            <FaFilter className={styles.filterIcon} />
            <button 
              className={`${styles.filterButton} ${activeFilter === 'all' ? styles.active : ''}`}
              onClick={() => setActiveFilter('all')}
            >
              All Routes
            </button>
            <button 
              className={`${styles.filterButton} ${activeFilter === 'uwe' ? styles.active : ''}`}
              onClick={() => setActiveFilter('uwe')}
            >
              UWE Routes
            </button>
            <button 
              className={`${styles.filterButton} ${activeFilter === 'uob' ? styles.active : ''}`}
              onClick={() => setActiveFilter('uob')}
            >
              UoB Routes
            </button>
          </div>
        </div>

        <div className={classNames(styles.alertsGrid, animations.fadeUp, animations.delay3)}>
          {filteredAlerts.map((alert, index) => (
            <div 
              key={alert.id} 
              className={classNames(
                styles.alertCard,
                styles[alert.severity],
                animations.fadeUp,
                animations[`delay${index + 4}`]
              )}
            >
              <div className={styles.alertHeader}>
                <div className={styles.routeBadge}>
                  <FaBus className={styles.busIcon} />
                  {alert.route}
                </div>
                <div className={styles.timestamp}>{alert.timestamp}</div>
              </div>
              <div className={styles.alertContent}>
                <div className={styles.severityIndicator}>
                  {getSeverityIcon(alert.severity)}
                  <span className={styles.severityText}>{alert.title}</span>
                </div>
                <p className={styles.message}>{alert.message}</p>
              </div>
            </div>
          ))}
        </div>

        <div className={classNames(styles.subscribeCard, animations.fadeUp, animations.delay5)}>
          <div className={styles.subscribeContent}>
            <FaBell className={styles.bellIcon} />
            <h2>Never Miss an Update</h2>
            <p>Get instant notifications for your regular bus routes</p>
            <button className={styles.subscribeButton} onClick={() => window.location.href = '/register'}>
              Sign Up for Alerts
            </button>
          </div>
        </div>
      </div>

      <PublicFooter />
    </div>
  );
}

export default PublicBusAlerts;
