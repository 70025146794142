import React from 'react';
import { Helmet } from 'react-helmet-async';
import styles from './Safety.module.css';
import PublicNavbar from '../../components/PublicNavbar';
import PublicFooter from '../../components/PublicFooter';

export default function Safety() {
  return (
    <div className={styles.pageContainer}>
      <Helmet>
        <title>Safety Tips - Two Stopper</title>
        <meta name="description" content="Important safety guidelines and tips for using Two Stopper." />
      </Helmet>

      <PublicNavbar />

      <div className={styles.safetyHero}>
        <div className={styles.safetyHeroContent}>
          <h1 className={styles.safetyHeroTitle}>Safety Tips</h1>
          <p className={styles.safetyHeroSubtitle}>
            Your safety is our top priority
          </p>
        </div>
      </div>

      <main className={styles.mainContent}>
        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>Bus Safety Guidelines</h2>
          <div className={styles.sectionContent}>
            <p>When using public transport:</p>
            <ul>
              <li>Wait at designated bus stops</li>
              <li>Board and exit safely</li>
              <li>Hold handrails when standing</li>
            </ul>
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>Personal Safety</h2>
          <div className={styles.sectionContent}>
            <p>To ensure your safety:</p>
            <ul>
              <li>Stay aware of your surroundings</li>
              <li>Keep valuables secure</li>
              <li>Travel in well-lit areas</li>
            </ul>
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>Emergency Procedures</h2>
          <div className={styles.sectionContent}>
            <p>In case of emergency:</p>
            <ul>
              <li>Contact emergency services</li>
              <li>Alert the bus driver</li>
              <li>Use emergency exits if necessary</li>
            </ul>
          </div>
        </section>

        <p className={styles.lastUpdated}>Last updated: January 12, 2025</p>
      </main>

      <PublicFooter />
    </div>
  );
}
