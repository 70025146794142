// Utility function to track events with Google Analytics
export const trackEvent = (category, action, label = null, value = null) => {
  if (window.gtag) {
    const eventParams = {
      event_category: category,
      event_label: label,
    };
    if (value !== null) {
      eventParams.value = value;
    }
    window.gtag('event', action, eventParams);
  }
};

// Authentication Events
export const trackAuth = {
  login: (method = 'email') => trackEvent('Authentication', 'Login', method),
  register: (method = 'email') => trackEvent('Authentication', 'Register', method),
  logout: () => trackEvent('Authentication', 'Logout'),
  forgotPassword: () => trackEvent('Authentication', 'Forgot Password'),
  resetPassword: () => trackEvent('Authentication', 'Reset Password'),
};

// Navigation Events
export const trackNavigation = {
  pageView: (pageName) => trackEvent('Navigation', 'Page View', pageName),
  modalOpen: (modalName) => trackEvent('Navigation', 'Modal Open', modalName),
  modalClose: (modalName) => trackEvent('Navigation', 'Modal Close', modalName),
  tabChange: (tabName) => trackEvent('Navigation', 'Tab Change', tabName),
};

// Feature Usage Events
export const trackFeature = {
  timetableView: () => trackEvent('Feature', 'View Timetable'),
  timetableEdit: () => trackEvent('Feature', 'Edit Timetable'),
  timetableUpload: () => trackEvent('Feature', 'Upload Timetable'),
  profileView: () => trackEvent('Feature', 'View Profile'),
  profileEdit: () => trackEvent('Feature', 'Edit Profile'),
  settingsChange: (setting) => trackEvent('Feature', 'Change Setting', setting),
  busSearch: (route) => trackEvent('Feature', 'Search Bus Route', route),
  busAlert: (action, route) => trackEvent('Feature', `Bus Alert ${action}`, route),
};

// Error Events
export const trackError = {
  apiError: (endpoint, error) => trackEvent('Error', 'API Error', `${endpoint}: ${error}`),
  validationError: (form, error) => trackEvent('Error', 'Validation Error', `${form}: ${error}`),
  authError: (action, error) => trackEvent('Error', 'Auth Error', `${action}: ${error}`),
};

// User Interaction Events
export const trackInteraction = {
  buttonClick: (buttonName) => trackEvent('Interaction', 'Button Click', buttonName),
  linkClick: (linkName) => trackEvent('Interaction', 'Link Click', linkName),
  formSubmit: (formName) => trackEvent('Interaction', 'Form Submit', formName),
  search: (searchTerm) => trackEvent('Interaction', 'Search', searchTerm),
  filter: (filterName, value) => trackEvent('Interaction', 'Filter', `${filterName}: ${value}`),
  sort: (sortType) => trackEvent('Interaction', 'Sort', sortType),
};

// Performance Events
export const trackPerformance = {
  apiLatency: (endpoint, duration) => trackEvent('Performance', 'API Latency', endpoint, Math.round(duration)),
  pageLoad: (page, duration) => trackEvent('Performance', 'Page Load', page, Math.round(duration)),
  resourceLoad: (resource, duration) => trackEvent('Performance', 'Resource Load', resource, Math.round(duration)),
};
