import React, { useState, useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './contexts/AuthContext';
import axiosInstance from './utils/axiosInstance';
import styles from './Home.module.css';
import useMediaQuery from './hooks/useMediaQuery';
import WelcomeModal from './components/WelcomeModal';
import BusJourneyModal from './components/BusJourneyModal';
import LoadingScreen from './components/LoadingScreen';
import BusCard from './components/Home/BusCard';
import LectureCard from './components/Home/LectureCard';
import WeeklySchedule from './components/Home/WeeklySchedule';
import { 
  IoNotifications, 
  IoNotificationsOff,
  IoCalendarOutline,
  IoAddCircleOutline
} from 'react-icons/io5';
import { Helmet } from 'react-helmet-async';
import { trackNavigation, trackFeature, trackError } from './utils/analytics';

function Home() {
  const { user, toggleNotifications } = useContext(AuthContext);
  const [currentTimetable, setCurrentTimetable] = useState(null);
  const [loading, setLoading] = useState(true);
  const [nextBusTime, setNextBusTime] = useState(null);
  const [nextHomeBusTime, setNextHomeBusTime] = useState(null);
  const [nextBusData, setNextBusData] = useState([]);
  const [nextHomeBusData, setNextHomeBusData] = useState([]);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [isBusModalOpen, setIsBusModalOpen] = useState(false);
  const [isReturnBusModalOpen, setIsReturnBusModalOpen] = useState(false);
  const [isLoadingJourneys, setIsLoadingJourneys] = useState(false);
  const navigate = useNavigate();
  const BUS_UPDATE_INTERVAL = 15000;

  useEffect(() => {
    trackNavigation.pageView('Home');
    
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get('/api/timetable/current');
        console.log('[Home] Timetable full response:', response.data);
        
        if (response.data) {
          const timetableData = response.data;
          console.log('[Home] Bus stop info from response:', { 
            busStopName: timetableData.busStopName, 
            returnBusStopName: timetableData.returnBusStopName,
            walkTime: timetableData.walkTime,
            campusBusStops: timetableData.campusBusStops
          });

          // Ensure we have the campusBusStops array
          const updatedTimetable = {
            ...timetableData,
            campusBusStops: timetableData.campusBusStops || []
          };

          console.log('[Home] Setting current timetable with bus stops:', {
            campusBusStops: updatedTimetable.campusBusStops
          });
          setCurrentTimetable(updatedTimetable);
          trackFeature.timetableView();
        }
      } catch (error) {
        console.error('[Home] Error fetching timetable:', error);
        trackError.apiError('home/fetch-data', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Keep existing helper functions
  const getNextLecture = useCallback((lectures = currentTimetable?.lectures) => {
    if (!lectures || !lectures.length) return null;
    
    const now = new Date();
    const currentDay = now.toLocaleString('en-US', { weekday: 'long' });
    const currentTime = now.toLocaleTimeString('en-US', { hour12: false });
    
    const todayLectures = lectures
      .filter(lecture => {
        if (lecture.day !== currentDay) return false;
        const lectureTime = lecture.time;
        return lectureTime > currentTime;
      })
      .sort((a, b) => {
        const timeA = new Date(`1970/01/01 ${a.time}`);
        const timeB = new Date(`1970/01/01 ${b.time}`);
        return timeA - timeB;
      });

    if (todayLectures.length === 0) {
      const daysOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
      const currentDayIndex = daysOrder.indexOf(currentDay);
      
      // If it's weekend or Friday, look for Monday's lectures
      if (currentDayIndex === -1 || currentDayIndex === daysOrder.length - 1) {
        const mondayLectures = lectures
          .filter(lecture => lecture.day === 'Monday')
          .sort((a, b) => {
            const timeA = new Date(`1970/01/01 ${a.time}`);
            const timeB = new Date(`1970/01/01 ${b.time}`);
            return timeA - timeB;
          });
          
        if (mondayLectures.length > 0) {
          return { 
            ...mondayLectures[0], 
            isNextDay: true,
            daysUntil: currentDayIndex === -1 ? 
              (currentDay === 'Saturday' ? 2 : 1) : // Handle Saturday and Sunday
              3 // Handle Friday
          };
        }
      }
      
      // For weekdays, look for next day with lectures
      for (let i = 1; i < daysOrder.length; i++) {
        const nextDayIndex = (currentDayIndex + i) % daysOrder.length;
        const nextDayLectures = lectures
          .filter(lecture => lecture.day === daysOrder[nextDayIndex])
          .sort((a, b) => {
            const timeA = new Date(`1970/01/01 ${a.time}`);
            const timeB = new Date(`1970/01/01 ${b.time}`);
            return timeA - timeB;
          });
          
        if (nextDayLectures.length > 0) {
          return { ...nextDayLectures[0], isNextDay: true, daysUntil: i };
        }
      }
      
      // If no lectures found in current week, get first lecture of next week
      const allLectures = lectures
        .sort((a, b) => {
          const dayDiff = daysOrder.indexOf(a.day) - daysOrder.indexOf(b.day);
          if (dayDiff === 0) {
            const timeA = new Date(`1970/01/01 ${a.time}`);
            const timeB = new Date(`1970/01/01 ${b.time}`);
            return timeA - timeB;
          }
          return dayDiff;
        });
        
      return { ...allLectures[0], isNextWeek: true };
    }

    return todayLectures[0];
  }, [currentTimetable]);

  const processBusJourneys = useCallback((journeys) => {
    if (!journeys || !Array.isArray(journeys)) {
      console.log('[Home] Invalid journeys data:', journeys);
      return [];
    }

    console.log('[Home] Processing journeys:', journeys);

    const processedJourneys = journeys
      .map(journey => {
        if (!journey || !journey.routeParts) {
          console.log('[Home] Invalid journey:', journey);
          return null;
        }

        // Get all bus parts of the journey
        const busParts = journey.routeParts.filter(part => part.mode === 'BUS');
        
        // Calculate number of changes
        const changes = Math.max(0, busParts.length - 1);
        
        // Get the first bus part for main service info
        const firstBusPart = busParts[0];
        if (!firstBusPart) {
          console.log('[Home] No bus parts in journey:', journey);
          return null;
        }

        // Calculate status based on delay
        let status = 'On Time';
        if (firstBusPart.delay) {
          const delayMins = Math.round(firstBusPart.delay / 60);
          status = `Delayed by ${delayMins} mins`;
        }

        // Extract all stops
        const stops = journey.routeParts
          .filter(part => part.mode === 'BUS')
          .flatMap(part => part.stops || [])
          .map(stop => ({
            name: stop.name,
            time: stop.time,
            isCurrent: stop.isCurrent
          }));

        return {
          service: firstBusPart.lineName,
          destination: firstBusPart.to,
          departureTime: journey.departureTime,
          arrivalTime: journey.arrivalTime,
          duration: journey.duration,
          status,
          stops,
          changes,
          isDirect: changes === 0,
          routeParts: journey.routeParts.map(part => ({
            mode: part.mode,
            from: part.from,
            to: part.to,
            line: part.lineName,
            departure: part.departureTime,
            duration: part.duration
          }))
        };
      })
      .filter(journey => journey !== null);

    // Apply user preferences
    let filteredJourneys = processedJourneys;
    
    if (user?.settings?.directBusesOnly) {
      console.log('[Home] Filtering for direct buses only');
      filteredJourneys = filteredJourneys.filter(journey => journey.isDirect);
    }

    if (user?.settings?.prioritiseEarliestArrival) {
      console.log('[Home] Sorting by earliest arrival');
      filteredJourneys.sort((a, b) => new Date(a.arrivalTime) - new Date(b.arrivalTime));
    }

    console.log('[Home] Processed journeys:', filteredJourneys);
    return filteredJourneys;
  }, [user?.settings]);

  const getMinutesUntilDeparture = useCallback((departureTime) => {
    if (!departureTime) return 'No times';
    
    const now = new Date();
    const departure = new Date(departureTime);
    const diffMs = departure - now;
    const diffMins = Math.round(diffMs / 60000);
    
    if (diffMins < 0) return 'Departed';
    if (diffMins === 0) return 'Due';
    return `${diffMins} mins`;
  }, []);

  useEffect(() => {
    const updateDisplayTimes = () => {
      if (nextBusData && nextBusData.length > 0) {
        const nextBus = nextBusData[0];
        const timeUntilDeparture = getMinutesUntilDeparture(nextBus.departureTime);
        setNextBusTime(timeUntilDeparture);
      }
      
      if (nextHomeBusData && nextHomeBusData.length > 0) {
        const nextHomeBus = nextHomeBusData[0];
        const timeUntilDeparture = getMinutesUntilDeparture(nextHomeBus.departureTime);
        setNextHomeBusTime(timeUntilDeparture);
      }
    };

    updateDisplayTimes();
    const interval = setInterval(updateDisplayTimes, 10000); // Update every 10 seconds

    return () => clearInterval(interval);
  }, [nextBusData, nextHomeBusData, getMinutesUntilDeparture]);

  useEffect(() => {
    const fetchTimetable = async () => {
      try {
        const response = await axiosInstance.get('/api/timetable/current');
        console.log('[Home] Timetable full response:', response.data);
        
        if (response.data) {
          const timetableData = response.data;
          console.log('[Home] Bus stop info from response:', { 
            busStopName: timetableData.busStopName, 
            returnBusStopName: timetableData.returnBusStopName,
            walkTime: timetableData.walkTime,
            campusBusStops: timetableData.campusBusStops
          });

          // Ensure we have the campusBusStops array
          const updatedTimetable = {
            ...timetableData,
            campusBusStops: timetableData.campusBusStops || []
          };

          console.log('[Home] Setting current timetable with bus stops:', {
            campusBusStops: updatedTimetable.campusBusStops
          });
          setCurrentTimetable(updatedTimetable);
        }
      } catch (error) {
        console.error('[Home] Error fetching timetable:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTimetable();
  }, []);

  useEffect(() => {
    if (!loading && !currentTimetable) {
      setShowWelcomeModal(true);
    }
  }, [loading, currentTimetable]);

  const getCurrentCampus = useCallback(() => {
    if (!currentTimetable) {
      console.log('No current timetable available');
      return null;
    }

    const nextLecture = getNextLecture();
    const campus = nextLecture?.campus || currentTimetable?.campuses?.[0] || 'Frenchay';
    
    console.log('[Home] Current campus:', {
      fromLecture: nextLecture?.campus,
      fromTimetable: currentTimetable?.campuses?.[0],
      selected: campus
    });
    
    return campus;
  }, [currentTimetable, getNextLecture]);

  const getCurrentCampusBusStops = useCallback(() => {
    console.log('[Home] Getting bus stops with timetable:', {
      currentTimetable,
      campusBusStops: currentTimetable?.campusBusStops,
      campus: getCurrentCampus()
    });
    
    if (!currentTimetable?.campusBusStops || !getCurrentCampus()) {
      console.log('[Home] Missing timetable or campus data');
      return null;
    }
    
    const busStops = currentTimetable.campusBusStops.find(
      busStopConfig => busStopConfig.campus === getCurrentCampus()
    );
    
    console.log('[Home] Found bus stops for campus:', busStops);
    return busStops;
  }, [currentTimetable, getCurrentCampus]);

  const getBusStopInfo = useCallback(() => {
    const campusBusStops = getCurrentCampusBusStops();
    return {
      busStopName: campusBusStops?.outboundStop?.name || 'No outbound stop set',
      returnBusStopName: campusBusStops?.inboundStop?.name || 'No return stop set',
      walkTime: campusBusStops?.walkTime || 0
    };
  }, [getCurrentCampusBusStops]);

  const fetchBusTimes = useCallback(async () => {
    if (!currentTimetable?.campusBusStops || !currentTimetable?.campusBusStops.length) {
      console.log('[Home] No bus stops configured');
      setNextBusTime('Please set a bus stop');
      setNextHomeBusTime('Please set a bus stop');
      return;
    }

    setIsLoadingJourneys(true);
    try {
      const now = new Date();
      const campus = getCurrentCampus();
      const busStopInfo = getBusStopInfo();

      console.log('[Home] Fetching bus times with:', {
        campus,
        busStopInfo,
        currentTime: now.toISOString()
      });

      // Fetch both outbound and return journeys in parallel
      const [toUniResponse, fromUniResponse] = await Promise.allSettled([
        axiosInstance.get('/api/journeyPlanner/plan', {
          params: {
            fromBusStop: busStopInfo.busStopName,
            targetTime: now.toISOString(),
            direction: 'to-uni',
            university: currentTimetable.university,
            campus: campus,
            directBusesOnly: user?.settings?.directBusesOnly || false,
            prioritiseEarliestArrival: user?.settings?.prioritiseEarliestArrival || false
          }
        }),
        axiosInstance.get('/api/journeyPlanner/plan', {
          params: {
            fromBusStop: busStopInfo.returnBusStopName,
            targetTime: now.toISOString(),
            direction: 'from-uni',
            university: currentTimetable.university,
            campus: campus,
            directBusesOnly: user?.settings?.directBusesOnly || false,
            prioritiseEarliestArrival: user?.settings?.prioritiseEarliestArrival || false
          }
        })
      ]);

      let success = false;

      // Handle outbound journey (to university)
      if (toUniResponse.status === 'fulfilled' && toUniResponse.value?.data) {
        const journeyData = toUniResponse.value.data;
        console.log('[Home] Outbound journey data:', journeyData);
        
        if (journeyData?.journeys?.length > 0) {
          success = true;
          const busJourneys = processBusJourneys(journeyData.journeys);
          console.log('[Home] Processed outbound journeys:', busJourneys);
          setNextBusData(busJourneys);
        }
      }

      // Handle return journey (from university)
      if (fromUniResponse.status === 'fulfilled' && fromUniResponse.value?.data) {
        const journeyData = fromUniResponse.value.data;
        console.log('[Home] Return journey data:', journeyData);
        
        if (journeyData?.journeys?.length > 0) {
          success = true;
          const busJourneys = processBusJourneys(journeyData.journeys);
          console.log('[Home] Processed return journeys:', busJourneys);
          setNextHomeBusData(busJourneys);
        }
      }

      if (!success) {
        console.log('[Home] No journeys found');
        setNextBusTime('No journeys found');
        setNextHomeBusTime('No journeys found');
      } else {
        setNextBusTime('Journeys found');
        setNextHomeBusTime('Journeys found');
      }

    } catch (error) {
      console.error('[Home] Error fetching bus times:', error);
      setNextBusTime('Error fetching times');
      setNextHomeBusTime('Error fetching times');
      trackError.apiError('home/fetch-bus-times', error.message);
    } finally {
      setIsLoadingJourneys(false);
    }
  }, [currentTimetable, getCurrentCampus, processBusJourneys, user?.settings, getBusStopInfo]);

  useEffect(() => {
    const updateBusTimes = async () => {
      await fetchBusTimes();
    };

    updateBusTimes();
    const interval = setInterval(updateBusTimes, BUS_UPDATE_INTERVAL);

    return () => clearInterval(interval);
  }, [fetchBusTimes]);

  const isMobile = useMediaQuery('(max-width: 768px)');

  const refreshTimetableData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/api/timetable/current');
      if (response.data) {
        const timetableData = response.data;
        const updatedTimetable = {
          ...timetableData,
          campusBusStops: timetableData.campusBusStops || []
        };
        setCurrentTimetable(updatedTimetable);
      }
    } catch (error) {
      console.error('[Home] Error refreshing timetable:', error);
      trackError.apiError('home/refresh-timetable', error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleBusSearch = (route) => {
    trackFeature.busSearch(route);
    // Your existing bus search logic
  };

  const handleAlertToggle = (route, enabled) => {
    trackFeature.busAlert(enabled ? 'Enable' : 'Disable', route);
    // Your existing alert toggle logic
  };

  return (
    <div className={styles.container}>
      <Helmet>
        <title>Home</title>
      </Helmet>
      {showWelcomeModal && (
        <WelcomeModal
          isOpen={showWelcomeModal}
          onClose={() => setShowWelcomeModal(false)}
        />
      )}
      <div className={styles.main}>
        {/* Hero Section */}
        <section className={styles.hero}>
          <div className={styles.heroBackground} />
          <div className={styles.heroContent}>
            <div className={styles.welcomeGroup}>
              <h1 className={styles.title}>
                Welcome back
                {user?.name && <div className={styles.userName}>{user.name}</div>}
              </h1>
              <p className={styles.subtitle}>
                Your personalised journey planner for university lectures and bus schedules
              </p>
            </div>
            <button
              className={`${styles.notificationButton} ${user?.notifications ? styles.active : ''}`}
              onClick={toggleNotifications}
            >
              {user?.notifications ? (
                <>
                  <IoNotifications className={styles.icon} />
                  <span>Notifications On</span>
                </>
              ) : (
                <>
                  <IoNotificationsOff className={styles.icon} />
                  <span>Notifications Off</span>
                </>
              )}
            </button>
          </div>
        </section>

        {/* Main Content */}
        <div className={styles.mainContent}>
          {loading ? (
            <LoadingScreen />
          ) : currentTimetable ? (
            <div className={styles.grid}>
              {/* Left Column - Bus Information */}
              <div className={styles.mainCards}>
                <BusCard
                  title={`Next Bus to ${getCurrentCampus()}`}
                  busData={nextBusData}
                  busTime={nextBusTime}
                  busStopName={getCurrentCampusBusStops()?.outboundStop?.name}
                  walkTime={getCurrentCampusBusStops()?.walkTime}
                  onClick={() => setIsBusModalOpen(true)}
                  destination={getCurrentCampus()}
                  settings={{
                    directBusesOnly: user?.settings?.directBusesOnly,
                    prioritiseEarliestArrival: user?.settings?.prioritiseEarliestArrival
                  }}
                  isLoading={isLoadingJourneys}
                />
                <BusCard
                  title="Next Bus Home"
                  busData={nextHomeBusData}
                  busTime={nextHomeBusTime}
                  busStopName={getCurrentCampusBusStops()?.inboundStop?.name}
                  walkTime={getCurrentCampusBusStops()?.walkTime}
                  onClick={() => setIsReturnBusModalOpen(true)}
                  destination="Home"
                  settings={{
                    directBusesOnly: user?.settings?.directBusesOnly,
                    prioritiseEarliestArrival: user?.settings?.prioritiseEarliestArrival
                  }}
                  isLoading={isLoadingJourneys}
                />
              </div>
              
              {/* Right Column - Lecture Information */}
              <div className={styles.mainCards}>
                <LectureCard lecture={getNextLecture()} />
                <WeeklySchedule timetable={currentTimetable} />
              </div>
            </div>
          ) : (
            <div className={styles.welcomeCard}>
              <div className={styles.welcomeIcon}>
                <IoCalendarOutline />
              </div>
              <h2 className={styles.welcomeTitle}>Create Your Timetable</h2>
              <p className={styles.welcomeText}>Set up your lecture schedule to get personalised bus times and notifications.</p>
              <button 
                className={styles.welcomeButton}
                onClick={() => navigate('/timetable', { state: { openTimetableForm: true } })}
              >
                <IoAddCircleOutline className={styles.buttonIcon} />
                Create Timetable
              </button>
            </div>
          )}
        </div>

        {/* Modals */}
        {isBusModalOpen && (
          <BusJourneyModal
            isOpen={isBusModalOpen}
            onClose={() => {
              document.body.style.overflow = 'unset';
              setIsBusModalOpen(false);
            }}
            busData={nextBusData}
            direction="outbound"
            homeBusData={[]}
            campus={getCurrentCampus()}
            stopId={getCurrentCampusBusStops()?.outboundStop?.id}
            stopName={getCurrentCampusBusStops()?.outboundStop?.name}
            authToken={user?.token}
            settings={{
              directBusesOnly: user?.settings?.directBusesOnly,
              prioritiseEarliestArrival: user?.settings?.prioritiseEarliestArrival
            }}
          />
        )}
        {isReturnBusModalOpen && (
          <BusJourneyModal
            isOpen={isReturnBusModalOpen}
            onClose={() => {
              document.body.style.overflow = 'unset';
              setIsReturnBusModalOpen(false);
            }}
            busData={[]}
            direction="inbound"
            homeBusData={nextHomeBusData}
            campus={getCurrentCampus()}
            stopId={getCurrentCampusBusStops()?.inboundStop?.id}
            stopName={getCurrentCampusBusStops()?.inboundStop?.name}
            authToken={user?.token}
            settings={{
              directBusesOnly: user?.settings?.directBusesOnly,
              prioritiseEarliestArrival: user?.settings?.prioritiseEarliestArrival
            }}
          />
        )}
      </div>
    </div>
  );
}

export default Home;