import React from 'react';
import './LoadingScreen.css';

function LoadingScreen({ message = "Loading..." }) {
  return (
    <div className="loader-container">
      <div className="loader">
        <div className="loader-circle"></div>
        <div className="loader-circle"></div>
        <div className="loader-circle"></div>
      </div>
      <div className="loader-text">{message}</div>
    </div>
  );
}

export default LoadingScreen;
