import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FaEnvelope, FaLock, FaEye, FaEyeSlash, FaUser, FaArrowLeft, FaExclamationCircle } from 'react-icons/fa';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import axiosInstance from './utils/axiosInstance';
import { AuthContext } from './contexts/AuthContext';
import { toast } from 'react-toastify';
import styles from './styles/Register.module.css';

function Register() {
  const navigate = useNavigate();
  const { checkAuth } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
    agreeToTerms: false
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handlePhoneChange = (value) => {
    setFormData(prev => ({
      ...prev,
      phone: value
    }));
  };

  const validateForm = () => {
    if (!formData.name || !formData.email || !formData.password || !formData.confirmPassword) {
      setError('All fields are required');
      return false;
    }

    if (!formData.phone || formData.phone.length < 11) {
      setError('Please enter a valid UK mobile number');
      return false;
    }

    if (!formData.agreeToTerms) {
      setError('Please agree to the Terms and Conditions');
      return false;
    }

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return false;
    }

    if (formData.password.length < 8) {
      setError('Password must be at least 8 characters long');
      return false;
    }

    // Remove the +44 prefix for validation
    const phoneWithoutPrefix = formData.phone.replace(/^44/, '');
    const phoneRegex = /^7[0-9]{9}$/;
    if (!phoneRegex.test(phoneWithoutPrefix)) {
      setError('Please enter a valid UK mobile number starting with 7');
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!validateForm()) return;

    setIsLoading(true);

    try {
      const response = await axiosInstance.post('/api/auth/register', {
        name: formData.name,
        email: formData.email,
        phoneNumber: formData.phone,
        password: formData.password
      });

      if (response.data && response.data.msg === 'Registration successful') {
        if (response.data.token) {
          localStorage.setItem('token', response.data.token);
          await checkAuth();
          toast.success('Registration successful!');
          navigate('/home');
        }
      }
    } catch (err) {
      console.error('Registration error:', err.response?.data || err);
      const errorMessage = err.response?.data?.msg || 'Registration failed. Please try again.';
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`${styles.container} ${isLoading ? styles.loading : ''}`}>
      <div className={`${styles.card} ${styles.fadeIn}`}>
        <button onClick={() => navigate(-1)} className={styles.backButton}>
          <FaArrowLeft />
        </button>
        
        <div className={styles.content}>
          <img src="/2Slogo.png" alt="Two Stopper Logo" className={styles.logo} />
          <h1 className={styles.title}>Create Account</h1>
          
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.inputGroup}>
              <FaUser className={styles.icon} />
              <input
                type="text"
                name="name"
                placeholder="Full Name"
                value={formData.name}
                onChange={handleChange}
                className={styles.input}
                required
              />
            </div>

            <div className={styles.inputGroup}>
              <FaEnvelope className={styles.icon} />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                className={styles.input}
                required
              />
            </div>

            <div className={styles.inputGroup}>
              <PhoneInput
                country={'gb'}
                value={formData.phone}
                onChange={handlePhoneChange}
                onlyCountries={['gb']}
                countryCodeEditable={false}
                placeholder="7123456789"
                inputClass={styles.phoneInput}
                containerClass={styles.phoneContainer}
                buttonClass={styles.phoneButton}
                dropdownClass={styles.phoneDropdown}
              />
            </div>

            <div className={styles.inputGroup}>
              <FaLock className={styles.icon} />
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                className={styles.input}
                required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className={styles.passwordToggle}
                aria-label={showPassword ? "Hide password" : "Show password"}
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </button>
            </div>

            <div className={styles.inputGroup}>
              <FaLock className={styles.icon} />
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                placeholder="Confirm Password"
                value={formData.confirmPassword}
                onChange={handleChange}
                className={styles.input}
                required
              />
              <button
                type="button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className={styles.passwordToggle}
                aria-label={showConfirmPassword ? "Hide password" : "Show password"}
              >
                {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
              </button>
            </div>

            {error && (
              <div className={styles.error}>
                <FaExclamationCircle />
                {error}
              </div>
            )}

            <div className={styles.termsGroup}>
              <input
                type="checkbox"
                name="agreeToTerms"
                checked={formData.agreeToTerms}
                onChange={handleChange}
                className={styles.checkbox}
                id="terms"
              />
              <label htmlFor="terms" className={styles.termsText}>
                I agree to the <Link to="/terms" className={styles.termsLink}>Terms and Conditions</Link>
              </label>
            </div>

            <button 
              type="submit" 
              className={styles.registerButton}
              disabled={isLoading}
            >
              {isLoading ? 'Creating Account...' : 'Create Account'}
            </button>
          </form>

          <div className={styles.footer}>
            <div>
              Already have an account?
              <Link to="/login" className={styles.link}>Sign In</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;