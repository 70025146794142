import React from 'react';
import Modal from 'react-modal';
import { 
  HiX as CloseIcon,
  HiCalendar as CalendarIcon,
  HiLocationMarker as LocationIcon,
  HiOutlineAcademicCap as LectureIcon,
  HiOutlineClock as ClockIcon,
  HiArrowRight as ArrowRightIcon,
  HiArrowLeft as ArrowLeftIcon,
  HiOfficeBuilding as BuildingIcon,
  HiLibrary as UniversityIcon
} from 'react-icons/hi';
import { IoBus as BusIcon } from 'react-icons/io5';
import { FaWalking } from 'react-icons/fa';
import styles from './TimetableModal.module.css';

Modal.setAppElement('#root');

const TimetableModal = ({ isOpen, onClose, timetable }) => {
  if (!timetable) return null;

  const formatTime = (time) => {
    if (!time) return '';
    try {
      const date = new Date(`1970-01-01T${time}`);
      return date.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      });
    } catch (error) {
      console.error('Error formatting time:', error);
      return time;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={`${styles.modal} ReactModal__Content--timetable`}
      overlayClassName={`${styles.overlay} ReactModal__Overlay--timetable`}
      closeTimeoutMS={300}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerContent}>
            <CalendarIcon className={styles.headerIcon} />
            <div className={styles.headerText}>
              <h2>{timetable.name}</h2>
              <span className={styles.subtitle}>
                {timetable.active ? 'Current Timetable' : 'Timetable Details'}
              </span>
            </div>
          </div>
          <button className={styles.closeButton} onClick={onClose}>
            <CloseIcon />
          </button>
        </div>

        <div className={styles.content}>
          <div className={styles.section}>
            <h3 className={styles.sectionTitle}>
              <UniversityIcon /> University Details
            </h3>
            <div className={styles.detailsGrid}>
              <div className={styles.detailItem}>
                <BuildingIcon />
                <div>
                  <h4>University</h4>
                  <p>{timetable.university || 'Not specified'}</p>
                </div>
              </div>
              {timetable.campus && (
                <div className={styles.detailItem}>
                  <LocationIcon />
                  <div>
                    <h4>Campus</h4>
                    <p>{timetable.campus}</p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={styles.section}>
            <h3 className={styles.sectionTitle}>
              <LectureIcon /> Lectures
            </h3>
            <div className={styles.lecturesList}>
              {timetable.lectures && timetable.lectures.map((lecture, index) => (
                <div key={index} className={styles.lectureItem}>
                  <div className={styles.lectureHeader}>
                    <div className={styles.lectureName}>
                      <LectureIcon />
                      <h4>{lecture.name || 'Untitled Lecture'}</h4>
                    </div>
                    <div className={styles.lectureTime}>
                      <ClockIcon />
                      <span>{formatTime(lecture.time)}</span>
                    </div>
                  </div>
                  {lecture.campus && (
                    <div className={styles.lectureLocation}>
                      <LocationIcon />
                      <span>{lecture.campus}</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className={styles.section}>
            <h3 className={styles.sectionTitle}>
              <BusIcon /> Bus Journey Details
            </h3>
            {timetable.campusBusStops && timetable.campusBusStops.map((busConfig, index) => (
              <div key={index} className={styles.journeyDetails}>
                <div className={styles.journeyStop}>
                  <div className={styles.stopDetails}>
                    <h4>Outbound Stop</h4>
                    <p>{busConfig.outboundStop?.name || 'Not specified'}</p>
                    {busConfig.outboundStop?.walkingTime && (
                      <div className={styles.walkingTime}>
                        <FaWalking />
                        <span>{busConfig.outboundStop.walkingTime} min walk</span>
                      </div>
                    )}
                  </div>
                </div>
                <ArrowRightIcon className={styles.journeyArrow} />
                <div className={styles.journeyStop}>
                  <div className={styles.stopDetails}>
                    <h4>Return Stop</h4>
                    <p>{busConfig.inboundStop?.name || 'Not specified'}</p>
                    {busConfig.inboundStop?.walkingTime && (
                      <div className={styles.walkingTime}>
                        <FaWalking />
                        <span>{busConfig.inboundStop.walkingTime} min walk</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
            {timetable.busRoute && (
              <div className={styles.routeInfo}>
                <div className={styles.busRoute}>
                  <BusIcon />
                  <span>Bus Route: {timetable.busRoute}</span>
                </div>
                {timetable.journeyTime && (
                  <div className={styles.journeyTime}>
                    <ClockIcon />
                    <span>Journey Time: {timetable.journeyTime} min</span>
                  </div>
                )}
              </div>
            )}
          </div>

          {timetable.notes && (
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>Notes</h3>
              <p className={styles.notes}>{timetable.notes}</p>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default TimetableModal;
