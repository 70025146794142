import React from 'react';
import { trackNavigation, trackInteraction } from '../utils/analytics';
import styles from './DeleteConfirmationModal.module.css';

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, itemName }) => {
  React.useEffect(() => {
    if (isOpen) {
      trackNavigation.modalOpen(`Delete ${itemName}`);
    }
  }, [isOpen, itemName]);

  if (!isOpen) return null;

  const handleConfirm = () => {
    trackInteraction.buttonClick(`Confirm Delete ${itemName}`);
    onConfirm();
    onClose();
  };

  const handleClose = () => {
    trackNavigation.modalClose(`Delete ${itemName}`);
    onClose();
  };

  const getModalContent = () => {
    if (itemName === 'account') {
      return {
        title: 'Delete Account',
        message: 'Are you sure you want to delete your account? This action cannot be undone.',
        confirmText: 'Delete Account'
      };
    }
    return {
      title: 'Delete Timetable',
      message: `Are you sure you want to delete timetable "${itemName}"? This action cannot be undone.`,
      confirmText: 'Delete'
    };
  };

  const { title, message, confirmText } = getModalContent();

  return (
    <div className={styles.modalOverlay} onClick={handleClose}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.message}>{message}</p>
        <div className={styles.buttonContainer}>
          <button className={`${styles.button} ${styles.cancelButton}`} onClick={handleClose}>
            Cancel
          </button>
          <button className={`${styles.button} ${styles.deleteButton}`} onClick={handleConfirm}>
            {confirmText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;
