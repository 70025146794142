// src/App.js
import React, { useContext, useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Modal from 'react-modal';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from './contexts/AuthContext';
import { AnimatePresence } from 'framer-motion';
import PageTransition from './components/PageTransition';
import Sidebar from './components/Sidebar';
import Analytics from './components/Analytics';
import './styles/global.css';
import { toastConfig } from './utils/toastConfig';
import { ModalProvider } from './contexts/ModalContext';
import styles from './styles/SafeArea.module.css';
import DeleteConfirmationModal from './components/DeleteConfirmationModal';

// Page Imports
import Login from './Login';
import Register from './Register';
import Home from './Home';
import Settings from './Settings';
import Profile from './Profile';
import EditProfile from './EditProfile';
import Timetable from './Timetable';
import EditTimetable from './EditTimetable';
import ProtectedRoute from './components/ProtectedRoute';
import ErrorBoundary from './components/ErrorBoundary';
import NotFound from './components/NotFound';
import LandingPage from './LandingPage';
import ForgotPassword from './components/ForgotPassword';
import About from './pages/About';
import Contact from './pages/Contact';
import Terms from './pages/legal/Terms';
import Privacy from './pages/legal/Privacy';
import Cookies from './pages/legal/Cookies';
import Safety from './pages/legal/Safety';
import PublicBusAlerts from './pages/PublicBusAlerts';
import PublicTimetables from './pages/PublicTimetables';
import PublicPricing from './pages/PublicPricing';
import PublicNavbar from './components/PublicNavbar';
import ModernLandingPage from './ModernLandingPage';
import HowItWorks from './pages/HowItWorks';

// Initialize Modal for the entire app
if (typeof window !== 'undefined') {
  Modal.setAppElement('#root');
}

let globalShowDeleteConfirmation = null;

export const useDeleteConfirmation = () => {
  if (!globalShowDeleteConfirmation) {
    throw new Error('Delete confirmation not initialized');
  }
  return globalShowDeleteConfirmation;
};

function App() {
  const location = useLocation();
  const { isAuthenticated, loading } = useContext(AuthContext);
  
  console.log('Auth State:', { isAuthenticated, loading, pathname: location.pathname });

  // Check if current route is public or auth-related
  const isPublicOrAuthRoute = [
    '/', '/login', '/register', '/forgot-password',
    '/about', '/contact', '/terms', '/privacy',
    '/cookies', '/safety', '/bus-alerts',
    '/timetables', '/pricing', '/how-it-works'
  ].includes(location.pathname);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteModalConfig, setDeleteModalConfig] = useState({ itemName: '', onConfirm: null });

  const showDeleteConfirmation = (itemName, onConfirm) => {
    setDeleteModalConfig({ itemName, onConfirm });
    setIsDeleteModalOpen(true);
  };

  useEffect(() => {
    globalShowDeleteConfirmation = showDeleteConfirmation;
    return () => {
      globalShowDeleteConfirmation = null;
    };
  }, []);

  return (
    <ErrorBoundary>
      <ModalProvider>
        <Analytics />
        {isPublicOrAuthRoute && !location.pathname.match(/^\/(login|register|forgot-password)/) ? <PublicNavbar /> : null}
        {!isPublicOrAuthRoute && isAuthenticated ? <Sidebar /> : null}
        <div className={`app-container ${!isPublicOrAuthRoute && isAuthenticated ? styles.contentWithSidebar : ''}`}>
          <div className="status-bar-area top"></div>
          <div className="app-content">
            <div className="App">
              <AnimatePresence mode="wait">
                <Routes location={location} key={location.pathname}>
                  {/* Public Routes */}
                  <Route path="/" element={<PageTransition><ModernLandingPage /></PageTransition>} />
                  <Route path="/about" element={<PageTransition><About /></PageTransition>} />
                  <Route path="/contact" element={<PageTransition><Contact /></PageTransition>} />
                  <Route path="/terms" element={<PageTransition><Terms /></PageTransition>} />
                  <Route path="/privacy" element={<PageTransition><Privacy /></PageTransition>} />
                  <Route path="/cookies" element={<PageTransition><Cookies /></PageTransition>} />
                  <Route path="/safety-tips" element={<PageTransition><Safety /></PageTransition>} />
                  <Route path="/bus-alerts" element={<PageTransition><PublicBusAlerts /></PageTransition>} />
                  <Route path="/timetables" element={<PageTransition><PublicTimetables /></PageTransition>} />
                  <Route path="/pricing" element={<PageTransition><PublicPricing /></PageTransition>} />
                  <Route path="/how-it-works" element={<PageTransition><HowItWorks /></PageTransition>} />
                  <Route path="/login" element={<PageTransition><Login /></PageTransition>} />
                  <Route path="/register" element={<PageTransition><Register /></PageTransition>} />
                  <Route path="/forgot-password" element={<PageTransition><ForgotPassword /></PageTransition>} />

                  {/* Protected Routes with PageTransition */}
                  <Route path="/home" element={<ProtectedRoute><PageTransition><Home /></PageTransition></ProtectedRoute>} />
                  <Route path="/settings" element={<ProtectedRoute><PageTransition><Settings /></PageTransition></ProtectedRoute>} />
                  <Route path="/profile" element={<ProtectedRoute><PageTransition><Profile /></PageTransition></ProtectedRoute>} />
                  <Route path="/timetable" element={<ProtectedRoute><PageTransition><Timetable /></PageTransition></ProtectedRoute>} />
                  <Route path="/edit-profile" element={<ProtectedRoute><PageTransition><EditProfile /></PageTransition></ProtectedRoute>} />
                  <Route path="/edit-timetable/:id" element={<ProtectedRoute><PageTransition><EditTimetable /></PageTransition></ProtectedRoute>} />

                  {/* 404 Route */}
                  <Route path="*" element={isAuthenticated ? (
                    <Navigate to="/home" replace />
                  ) : (
                    <PageTransition><NotFound /></PageTransition>
                  )} />
                </Routes>
              </AnimatePresence>
            </div>
          </div>
        </div>
        <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={() => {
            deleteModalConfig.onConfirm?.();
            setIsDeleteModalOpen(false);
          }}
          itemName={deleteModalConfig.itemName}
        />
        <ToastContainer 
          position={toastConfig.position}
          autoClose={toastConfig.autoClose}
          hideProgressBar={toastConfig.hideProgressBar}
          newestOnTop
          closeOnClick={toastConfig.closeOnClick}
          rtl={false}
          pauseOnFocusLoss
          draggable={toastConfig.draggable}
          pauseOnHover={toastConfig.pauseOnHover}
          theme={toastConfig.theme}
        />
      </ModalProvider>
    </ErrorBoundary>
  );
}

export default App;