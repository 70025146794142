// src/EditProfile.js

import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './contexts/AuthContext';
import axiosInstance from './utils/axiosInstance';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import styles from './EditProfile.module.css';
import LoadingScreen from './components/LoadingScreen';
import PageTransition from './components/PageTransition';
import { showToast, handleApiError } from './utils/toastConfig';

function EditProfile() {
  const navigate = useNavigate();
  const { user, checkAuth } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    name: user?.name || '',
    email: user?.email || '',
    phone: user?.phone || ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [phoneValid, setPhoneValid] = useState(true);

  const handleChange = (e) => {
    if (typeof e === 'string') {
      // This is from the phone input
      // Ensure the number starts with 44 (country code)
      const phoneNumber = e.startsWith('44') ? e : `44${e}`;
      setFormData(prev => ({
        ...prev,
        phone: phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`
      }));
      
      // Validate UK mobile number (should start with 7 after country code)
      const numberAfterCode = phoneNumber.replace(/^\+?44/, '');
      setPhoneValid(numberAfterCode.startsWith('7'));
    } else {
      setFormData(prev => ({
        ...prev,
        [e.target.name]: e.target.value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!phoneValid) {
      showToast.error('Please enter a valid UK mobile number starting with 07');
      return;
    }
    
    setIsLoading(true);

    try {
      await axiosInstance.put('/api/user/profile', formData);
      await checkAuth();
      showToast.success('Profile updated successfully');
      navigate('/profile');
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    navigate('/profile');
  };

  if (!user) {
    return <LoadingScreen message="Loading profile..." />;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <PageTransition isAuth={true}>
      <div className={styles.container}>
        <div className={styles.main}>
          {/* Hero Section */}
          <section className={styles.hero}>
            <div className={styles.heroBackground} />
            <div className={styles.heroContent}>
              <div className={styles.welcomeGroup}>
                <h1 className={styles.title}>Edit Profile</h1>
                <p className={styles.subtitle}>Update your personal information and contact details</p>
              </div>
            </div>
          </section>

          <div className={styles.formContent}>
            <div className={styles.formCard}>
              <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.formSection}>
                  <div className={styles.formGroup}>
                    <label htmlFor="name" className={styles.label}>Name</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter your name"
                      autoComplete="name"
                      className={styles.input}
                    />
                  </div>

                  <div className={styles.formGroup}>
                    <label htmlFor="email" className={styles.label}>Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      disabled
                      className={styles.input}
                    />
                    <span className={styles.inputNote}>Email cannot be changed</span>
                  </div>

                  <div className={styles.formGroup}>
                    <label htmlFor="phone" className={styles.label}>Phone</label>
                    <div className={styles.phoneInputContainer}>
                      <PhoneInput
                        country={'gb'}
                        onlyCountries={['gb']}
                        countryCodeEditable={false}
                        value={formData.phone}
                        onChange={handleChange}
                        inputProps={{
                          id: 'phone',
                          name: 'phone',
                          required: true,
                        }}
                        containerClass={styles.phoneInputContainer}
                        inputClass={`${styles.phoneInput} ${!phoneValid ? styles.invalid : ''}`}
                        buttonClass={styles.phoneButton}
                        dropdownClass={styles.phoneDropdown}
                        prefix="+"
                        placeholder="447XXXXXXXXX"
                      />
                    </div>
                    {!phoneValid && (
                      <span className={styles.errorMessage}>Please enter a valid UK mobile number starting with 07</span>
                    )}
                    <span className={styles.inputNote}>UK mobile number (starts with +44)</span>
                  </div>
                </div>

                <div className={styles.buttonGroup}>
                  <button 
                    type="button" 
                    className={styles.cancelButton}
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <button 
                    type="submit" 
                    className={styles.saveButton}
                    disabled={isLoading}
                  >
                    {isLoading ? 'Saving...' : 'Save Changes'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </PageTransition>
  );
}

export default EditProfile;
