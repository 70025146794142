import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import { FaBus, FaUniversity, FaHome, FaMapMarkerAlt } from 'react-icons/fa';
import PublicFooter from '../components/PublicFooter';
import PublicHero from '../components/PublicHero';
import styles from './PublicTimetables.module.css';
import animations from '../styles/animations.module.css';
import classNames from 'classnames';

function PublicTimetables() {
  const navigate = useNavigate();
  const [activeFilter, setActiveFilter] = useState('all');

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Bristol Bus Timetables | Two Stopper Student Transport",
    "description": "View Bristol bus timetables for all major student routes. Easy-to-read schedules for University of Bristol and UWE campus services.",
    "mainEntity": {
      "@type": "Dataset",
      "name": "Bristol Student Bus Timetables",
      "description": "Comprehensive bus timetables for Bristol student routes"
    }
  };

  const busRoutes = {
    "UWE Routes": [
      { number: "U3", description: "Frenchay Campus - Bower Ashton", operator: "First" },
      { number: "U3X", description: "Frenchay Campus - Bower Ashton (Express)", operator: "First" },
      { number: "15", description: "City Centre - UWE Frenchay Campus", operator: "First" },
      { number: "19", description: "Bath - UWE Frenchay Campus", operator: "First" },
      { number: "48/49", description: "City Centre - UWE Frenchay Campus", operator: "First" },
      { number: "70", description: "UWE Frenchay Campus - Bristol Temple Meads", operator: "First" },
      { number: "71", description: "UWE Frenchay Campus - UWE Bower Ashton", operator: "First" },
      { number: "72", description: "City Centre - UWE Frenchay Campus", operator: "First" },
      { number: "73", description: "City Centre - Cribbs Causeway via UWE", operator: "First" },
      { number: "77", description: "Bristol City Centre - UWE Frenchay Campus", operator: "First" },
      { number: "82", description: "Southmead Hospital - UWE Frenchay Campus", operator: "First" },
      { number: "94", description: "City Centre - UWE Frenchay Campus", operator: "First" },
      { number: "Y6", description: "Chipping Sodbury - UWE Frenchay Campus", operator: "First" }
    ],
    "University of Bristol Routes": [
      { number: "8", description: "Temple Meads - Bristol Zoo via University", operator: "First" },
      { number: "9", description: "Temple Meads - Redland via University", operator: "First" },
      { number: "1", description: "Cribbs Causeway - Broomhill via University", operator: "First" },
      { number: "2", description: "Cribbs Causeway - Stockwood via University", operator: "First" },
      { number: "3", description: "Aztec West - City Centre via University", operator: "First" },
      { number: "4", description: "City Centre - Sea Mills via University", operator: "First" },
      { number: "72", description: "Temple Meads - UWE via University", operator: "First" }
    ],
    "Student Accommodation Routes": [
      { number: "16", description: "City Centre - UWE via Fishponds", operator: "First" },
      { number: "48A", description: "City Centre - Stoke Bishop Halls", operator: "First" },
      { number: "505", description: "Long Ashton P&R - Clifton Triangle", operator: "First" }
    ]
  };

  const filterRoutes = (category) => {
    if (category === 'all') return busRoutes;
    
    const filteredRoutes = {};
    if (category === 'uwe' && busRoutes['UWE Routes']) {
      filteredRoutes['UWE Routes'] = busRoutes['UWE Routes'];
    } else if (category === 'uob' && busRoutes['University of Bristol Routes']) {
      filteredRoutes['University of Bristol Routes'] = busRoutes['University of Bristol Routes'];
    } else if (category === 'accommodation' && busRoutes['Student Accommodation Routes']) {
      filteredRoutes['Student Accommodation Routes'] = busRoutes['Student Accommodation Routes'];
    }
    return filteredRoutes;
  };

  const displayedRoutes = filterRoutes(activeFilter);

  return (
    <>
      <Helmet>
        <title>Bristol Bus Timetables | Two Stopper Student Transport</title>
        <meta name="description" content="Access comprehensive Bristol bus timetables. View schedules for all major student routes, including UWE Frenchay, Bower Ashton, and University of Bristol services." />
        <meta name="keywords" content="bristol bus timetables, student transport schedule, first bus times, uwe bus schedule, university of bristol bus times" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className={styles.pageContainer}>
        <PublicHero 
          title="Bristol Bus Timetables"
          description="Find timetables for all university bus routes in Bristol. Click on a route to view detailed times and stops."
        />

        <div className={styles.contentContainer}>
          <div className={classNames(styles.filterSection, animations.fadeUp, animations.delay1)}>
            <button 
              className={classNames(styles.filterButton, { [styles.active]: activeFilter === 'all' })}
              onClick={() => setActiveFilter('all')}
            >
              <FaBus className={styles.filterIcon} />
              All Routes
            </button>
            <button 
              className={classNames(styles.filterButton, { [styles.active]: activeFilter === 'uwe' })}
              onClick={() => setActiveFilter('uwe')}
            >
              <FaUniversity className={styles.filterIcon} />
              UWE Routes
            </button>
            <button 
              className={classNames(styles.filterButton, { [styles.active]: activeFilter === 'uob' })}
              onClick={() => setActiveFilter('uob')}
            >
              <FaUniversity className={styles.filterIcon} />
              University of Bristol
            </button>
            <button 
              className={classNames(styles.filterButton, { [styles.active]: activeFilter === 'accommodation' })}
              onClick={() => setActiveFilter('accommodation')}
            >
              <FaHome className={styles.filterIcon} />
              Student Accommodation
            </button>
          </div>

          <div className={styles.routesContainer}>
            {Object.entries(displayedRoutes).map(([category, routes], categoryIndex) => (
              <div 
                key={category}
                className={classNames(
                  styles.categorySection,
                  animations.fadeUp,
                  animations[`delay${categoryIndex + 2}`]
                )}
              >
                <h2 className={styles.categoryTitle}>{category}</h2>
                <div className={styles.routesGrid}>
                  {routes.map((route, index) => (
                    <div 
                      key={`${route.number}-${index}`}
                      className={classNames(
                        styles.routeCard,
                        animations.fadeUp,
                        animations[`delay${index % 3 + 2}`]
                      )}
                      onClick={() => navigate('/register')}
                    >
                      <div className={styles.routeNumber}>{route.number}</div>
                      <div className={styles.routeDetails}>
                        <h3>{route.description}</h3>
                        <p className={styles.operator}>{route.operator}</p>
                      </div>
                      <FaMapMarkerAlt className={styles.routeIcon} />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        <PublicFooter />
      </div>
    </>
  );
}

export default PublicTimetables;
