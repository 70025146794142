import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  IoCalendarClearOutline,
  IoPencilOutline,
  IoListOutline,
  IoTimeOutline,
  IoLocationOutline
} from 'react-icons/io5';
import styles from './WeeklySchedule.module.css';

const WeeklySchedule = ({ timetable }) => {
  const navigate = useNavigate();

  if (!timetable || !timetable.lectures) return null;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.titleGroup}>
          <IoCalendarClearOutline className={styles.icon} />
          <h3>Weekly Schedule</h3>
        </div>
        <div className={styles.actions}>
          <button 
            className={`${styles.button} ${styles.editButton}`}
            onClick={() => navigate(`/edit-timetable/${timetable._id}`)}
          >
            <IoPencilOutline className={styles.buttonIcon} />
            <span>Edit</span>
          </button>
          <button 
            className={`${styles.button} ${styles.viewButton}`}
            onClick={() => navigate('/timetable')}
          >
            <IoListOutline className={styles.buttonIcon} />
            <span>View All</span>
          </button>
        </div>
      </div>

      <div className={styles.schedule}>
        {timetable.lectures
          .sort((a, b) => {
            const dayOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
            const dayDiff = dayOrder.indexOf(a.day) - dayOrder.indexOf(b.day);
            if (dayDiff === 0) {
              return a.time.localeCompare(b.time);
            }
            return dayDiff;
          })
          .map((lecture, idx) => (
            <div 
              key={idx} 
              className={styles.lecture}
            >
              <div className={styles.day}>
                {lecture.day.slice(0, 3)}
              </div>
              <div className={styles.details}>
                <div className={styles.name}>
                  {lecture.name}
                </div>
                <div className={styles.info}>
                  <div className={styles.time}>
                    <IoTimeOutline className={styles.infoIcon} />
                    <span>{lecture.time}</span>
                  </div>
                  <div className={styles.location}>
                    <IoLocationOutline className={styles.infoIcon} />
                    <span>{lecture.campus}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default WeeklySchedule;
