// Frontend logger utility that matches the backend logger format
class Logger {
  static indicators = {
    error: '❌',
    success: '✅',
    info: 'ℹ️',
    warning: '⚠️',
    debug: '🔍'
  };

  static formatMessage(type, message, data = null) {
    const indicator = this.indicators[type] || '•';
    
    // Truncate message if too long
    const truncatedMessage = message.length > 100 ? message.substring(0, 97) + '...' : message;
    let formattedMessage = `${indicator} ${truncatedMessage}`;

    // Only add data for errors or if explicitly needed
    if (data) {
      if (type === 'error') {
        // For errors, include full data
        formattedMessage += ' ' + (typeof data === 'string' ? data : JSON.stringify(data, null, 2));
      } else {
        // For other types, just show essential info
        const summarizedData = this.summarizeData(data);
        if (summarizedData) {
          formattedMessage += ': ' + summarizedData;
        }
      }
    }

    return formattedMessage;
  }

  static summarizeData(data) {
    if (!data) return '';
    if (typeof data === 'string') return data;
    if (Array.isArray(data)) {
      return `[${data.length} items]`;
    }
    if (typeof data === 'object') {
      // Only include essential fields
      const summary = {};
      const essentialFields = ['id', 'name', 'type', 'count'];
      Object.keys(data).forEach(key => {
        if (essentialFields.includes(key)) {
          summary[key] = data[key];
        }
      });
      return Object.keys(summary).length ? JSON.stringify(summary) : '';
    }
    return '';
  }

  static log(type, message, data = null) {
    const formattedMessage = this.formatMessage(type, message, data);
    switch (type) {
      case 'error':
        console.error(formattedMessage);
        break;
      case 'warning':
        console.warn(formattedMessage);
        break;
      default:
        console.log(formattedMessage);
    }
  }

  static error(message, data = null) {
    this.log('error', message, data);
  }

  static success(message, data = null) {
    this.log('success', message, data);
  }

  static info(message, data = null) {
    this.log('info', message, data);
  }

  static warning(message, data = null) {
    this.log('warning', message, data);
  }

  static debug(message, data = null) {
    this.log('debug', message, data);
  }
}

export default Logger;
