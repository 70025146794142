import React from 'react';
import { Helmet } from 'react-helmet-async';
import styles from './Privacy.module.css';
import PublicNavbar from '../../components/PublicNavbar';
import PublicFooter from '../../components/PublicFooter';

export default function Privacy() {
  return (
    <div className={styles.pageContainer}>
      <Helmet>
        <title>Privacy Policy - Two Stopper | Bristol Student Bus App</title>
        <meta name="description" content="Read Two Stopper's privacy policy to understand how we protect and handle your data while using our Bristol bus tracking and journey planning service." />
        <meta name="keywords" content="two stopper privacy, bristol bus app privacy, student transport data protection, bus tracking privacy policy" />
        <link rel="canonical" href="https://twostopper.co.uk/privacy" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Privacy Policy - Two Stopper",
            "description": "Read Two Stopper's privacy policy to understand how we protect and handle your data while using our Bristol bus tracking and journey planning service.",
            "publisher": {
              "@type": "Organization",
              "name": "Two Stopper",
              "url": "https://twostopper.co.uk"
            },
            "dateModified": "2025-01-12T18:15:07Z"
          })}
        </script>
      </Helmet>

      <PublicNavbar />

      <div className={styles.privacyHero}>
        <div className={styles.privacyHeroPattern} />
        <div className={styles.privacyHeroBackground} />
        <div className={styles.privacyHeroContent}>
          <h1 className={styles.privacyHeroTitle}>Privacy Policy</h1>
          <p className={styles.privacyHeroSubtitle}>
            Learn about how we protect and handle your data
          </p>
        </div>
      </div>

      <main className={styles.mainContent}>
        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>1. Introduction</h2>
          <div className={styles.sectionContent}>
            <p>At Two Stopper, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our website, mobile application, and services. Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access our services.</p>
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>2. Information We Collect</h2>
          <div className={styles.sectionContent}>
            <p>2.1. Personal Information</p>
            <p>We collect information that you provide directly to us, including:</p>
            <ul>
              <li>Name and contact information</li>
              <li>Account credentials</li>
              <li>Profile information</li>
              <li>Payment information</li>
              <li>Communication preferences</li>
            </ul>

            <p>2.2. Usage Information</p>
            <p>We automatically collect certain information when you use our Services:</p>
            <ul>
              <li>Device information (type, model, operating system)</li>
              <li>IP address and location data</li>
              <li>Browser type and version</li>
              <li>Usage patterns and preferences</li>
              <li>Journey history and saved routes</li>
            </ul>
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>3. How We Use Your Information</h2>
          <div className={styles.sectionContent}>
            <p>We use the information we collect to:</p>
            <ul>
              <li>Provide and maintain our Services</li>
              <li>Process your transactions</li>
              <li>Send you service-related notifications</li>
              <li>Improve our Services</li>
              <li>Respond to your enquiries</li>
              <li>Prevent fraud and misuse</li>
              <li>Comply with legal obligations</li>
            </ul>
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>4. Data Sharing and Disclosure</h2>
          <div className={styles.sectionContent}>
            <p>4.1. Third-Party Service Providers</p>
            <p>We may share your information with trusted third parties who assist us in operating our Services, including:</p>
            <ul>
              <li>Payment processors</li>
              <li>Cloud storage providers</li>
              <li>Analytics services</li>
              <li>Customer support services</li>
              <li>Marketing and advertising partners</li>
            </ul>

            <p>4.2. Legal Requirements</p>
            <p>We may disclose your information if required by law or:</p>
            <ul>
              <li>To comply with legal obligations</li>
              <li>To protect our rights and property</li>
              <li>To prevent or investigate possible wrongdoing</li>
              <li>To protect the personal safety of users or the public</li>
            </ul>
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>5. Data Security</h2>
          <div className={styles.sectionContent}>
            <p>We implement appropriate technical and organisational measures to protect your data:</p>
            <ul>
              <li>Encryption of data in transit and at rest</li>
              <li>Regular security assessments</li>
              <li>Access controls and authentication</li>
              <li>Secure data centres</li>
              <li>Employee training and confidentiality agreements</li>
            </ul>
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>6. Your Rights and Choices</h2>
          <div className={styles.sectionContent}>
            <p>Under applicable data protection laws, you have the right to:</p>
            <ul>
              <li>Access your personal information</li>
              <li>Correct inaccurate data</li>
              <li>Request deletion of your data</li>
              <li>Object to processing of your data</li>
              <li>Restrict processing of your data</li>
              <li>Request data portability</li>
              <li>Withdraw consent at any time</li>
            </ul>
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>7. International Data Transfers</h2>
          <div className={styles.sectionContent}>
            <p>Your information may be transferred to and processed in countries other than your country of residence. We ensure appropriate safeguards are in place to protect your information when transferred internationally.</p>
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>8. Children's Privacy</h2>
          <div className={styles.sectionContent}>
            <p>Our Services are not intended for children under 13 years of age. We do not knowingly collect personal information from children under 13. If you become aware that a child has provided us with personal information, please contact us.</p>
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>9. Changes to This Policy</h2>
          <div className={styles.sectionContent}>
            <p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page and updating the "Last Updated" date. You are advised to review this privacy policy periodically for any changes.</p>
          </div>
        </section>

        <p className={styles.lastUpdated}>Last updated: 12 January 2025</p>
      </main>

      <PublicFooter />
    </div>
  );
}
