import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { FaGraduationCap, FaMapMarkerAlt, FaRoute, FaCalendarAlt, FaWalking, FaBus, FaClock } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import axiosInstance from '../utils/axiosInstance';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './TimetableForm.css';
import { useNavigate } from 'react-router-dom';

const universityOptions = [
  { 
    value: 'UWE', 
    label: 'UWE', 
    logo: '/uwe-logo.svg',
    fullName: 'University of the West of England'
  },
  { 
    value: 'UOB', 
    label: 'UOB', 
    logo: '/uob-logo.png',
    fullName: 'University of Bristol'
  },
];

const TimetableForm = ({ initialData, onSubmit, onSave, onClose, isOpen, isEditing, startOnLectures }) => {
  const [currentStep, setCurrentStep] = useState(startOnLectures ? 3 : 1);
  console.log('Current Step:', currentStep);
  const [formData, setFormData] = useState({
    university: initialData?.university || null,
    selectedCampuses: initialData?.selectedCampuses || [],
    route: initialData?.route || null,
    timetable: initialData?.timetable || null,
    selectedLectureCampus: '',
    selectedDay: '',
    startTime: '',
    endTime: '',
    lectureName: '',
    lectures: initialData?.lectures || [],
    timetableName: initialData?.name || ''
  });
  console.log('Form Data:', formData);
  const [campuses, setCampuses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [locationError, setLocationError] = useState(null);
  const [optimizationStatus, setOptimizationStatus] = useState({
    optimised: false,
    currentCampus: '',
    completedCampuses: []
  });
  const [optimisedResults, setOptimisedResults] = useState([]);
  const [showInfoText, setShowInfoText] = useState(true);
  const [stepOutput, setStepOutput] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const cliContentRef = useRef(null);
  const reviewFormRef = useRef(null);
  const navigate = useNavigate();
  const modalRef = useRef(null);

  useEffect(() => {
    if (formData.university) {
      fetchCampuses(formData.university.value);
    }
  }, [formData.university]);

  useEffect(() => {
    // Get user's location when component mounts
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
          setLocationError(null);
        },
        (error) => {
          console.error('Error getting location:', error);
          setLocationError('Unable to get your location. Please enable location services.');
          toast.error('Unable to get your location. Please enable location services.');
        }
      );
    } else {
      setLocationError('Geolocation is not supported by your browser');
      toast.error('Geolocation is not supported by your browser');
    }
  }, []);

  useEffect(() => {
    console.log('Selected Campuses:', formData.selectedCampuses);
    if (formData.selectedCampuses.length === 1) {
      setFormData(prev => ({
        ...prev,
        selectedLectureCampus: formData.selectedCampuses[0].name
      }));
    }
  }, [formData.selectedCampuses]);

  useEffect(() => {
    if (currentStep === 5 && reviewFormRef.current) {
      reviewFormRef.current.scrollTop = 0;
    }
  }, [currentStep]);

  const fetchCampuses = async (universityId) => {
    try {
      const response = await axiosInstance.get(`/api/university/${universityId}/campuses`);
      setCampuses(response.data.campuses);
    } catch (error) {
      console.error('Error fetching campuses:', error);
      toast.error('Failed to fetch campuses. Please try again.');
    }
  };

  const handleUniversitySelect = async (university) => {
    console.log('University selected:', university);
    try {
      const response = await axiosInstance.post('/api/university/select', {
        university
      });
      console.log('API response for university selection:', response);
      if (response.data.success) {
        setFormData(prev => ({
          ...prev,
          university,
          selectedCampuses: [] // Reset selected campuses when university changes
        }));
      } else {
        toast.error('Failed to select university. Please try again.');
      }
    } catch (error) {
      console.error('Error selecting university:', error);
      toast.error('An error occurred. Please try again later.');
    }
  };

  const handleCampusSelect = (campus) => {
    const isSelected = formData.selectedCampuses.some(
      (selected) => selected.name === campus.name
    );
    let updatedCampuses;

    if (isSelected) {
      // Remove campus if already selected
      updatedCampuses = formData.selectedCampuses.filter(
        (c) => c.name !== campus.name
      );
    } else {
      // Add campus if not selected
      updatedCampuses = [...formData.selectedCampuses, {
        name: campus.name,
        latitude: campus.latitude,
        longitude: campus.longitude,
        address: campus.address
      }];
    }

    setFormData(prev => ({
      ...prev,
      selectedCampuses: updatedCampuses,
    }));
  };

  const handleContinueToRoute = () => {
    if (formData.selectedCampuses.length === 0) {
      toast.error('Please select at least one campus to continue.');
      return;
    }
    setCurrentStep(3);
  };

  const scrollToBottom = () => {
    if (cliContentRef.current) {
      cliContentRef.current.scrollTop = cliContentRef.current.scrollHeight;
    }
  };

  // Optimized typing animation function
  const typeText = async (text, type = 'info', speed = 10) => {
    if (!text) {
      setStepOutput(prev => [...prev, { text: '', type: 'info', incomplete: false }]);
      return;
    }

    setIsTyping(true);
    const chars = text.split('');
    let currentText = '';

    // Batch updates for better performance
    const batchSize = Math.max(1, Math.floor(chars.length / 10));
    
    for (let i = 0; i < chars.length; i += batchSize) {
      currentText += chars.slice(i, i + batchSize).join('');
      setStepOutput(prev => {
        const newOutput = [...prev];
        if (newOutput.length === 0 || !newOutput[newOutput.length - 1].incomplete) {
          newOutput.push({ text: '', type, incomplete: true });
        }
        newOutput[newOutput.length - 1] = { text: currentText, type, incomplete: true };
        return newOutput;
      });
      scrollToBottom();
      await new Promise(resolve => setTimeout(resolve, speed));
    }
    
    setStepOutput(prev => {
      const newOutput = [...prev];
      newOutput[newOutput.length - 1] = { text, type, incomplete: false };
      return newOutput;
    });
    scrollToBottom();
    setIsTyping(false);
  };

  const addOutput = async (text, type = 'info') => {
    setStepOutput(prev => [...prev, { text: '', type, incomplete: true }]);
    scrollToBottom();
    await typeText(text, type);
  };

  useEffect(() => {
    scrollToBottom();
  }, [stepOutput]);

  const handleOptimise = async () => {
    setShowInfoText(false);
    if (!userLocation && !locationError) {
      toast.error('Still getting your location. Please wait a moment.');
      return;
    }

    if (locationError) {
      toast.error(locationError);
      return;
    }

    setLoading(true);
    setStepOutput([]);
    const results = [];

    // Faster boot sequence (3ms)
    const bootSequence = [
      '$ Starting Two Stopper Route Optimiser v1.0.0',
      '> Initializing system...',
      '> Loading transport modules...',
      '> Connecting to FirstBus network...',
      '> Cache initialized',
      '> System ready',
      ''
    ];

    // Run boot sequence faster
    for (const line of bootSequence) {
      await typeText(line, 'info', 3);
    }
    
    for (const campus of formData.selectedCampuses) {
      // Campus processing messages - slightly faster (8ms)
      await typeText(`$ Processing campus: ${campus.name}`, 'campus', 8);
      await typeText('> Scanning local transport nodes...', 'info', 8);
      await typeText('> Computing optimal routes...', 'info', 8);
      
      try {
        const response = await axiosInstance.post('/api/timetableJourneyPlanner/optimise', {
          university: formData.university.value,
          campuses: [{
            name: campus.name,
            latitude: campus.latitude,
            longitude: campus.longitude
          }],
          userLocation,
        });

        if (response.data.success && response.data.results) {
          const result = response.data.results[0];
          console.log('API Response Result:', JSON.stringify(result, null, 2));
          
          if (result.toUni && result.fromUni) {
            // Get journey parts from the response
            const toUniBusParts = result.toUni.route_parts?.filter(part => part.mode === 'bus') || [];
            const toUniWalkParts = result.toUni.route_parts?.filter(part => part.mode === 'foot') || [];
            const fromUniBusParts = result.fromUni.route_parts?.filter(part => part.mode === 'bus') || [];
            const fromUniWalkParts = result.fromUni.route_parts?.filter(part => part.mode === 'foot') || [];

            console.log('Journey Parts Analysis:', {
              toUni: {
                walkParts: toUniWalkParts.map(part => ({
                  from: part.from_point_name,
                  to: part.to_point_name,
                  duration: part.duration
                })),
                busParts: toUniBusParts.map(part => ({
                  from: part.from_point_name,
                  to: part.to_point_name,
                  service: part.service,
                  duration: part.duration
                }))
              },
              fromUni: {
                walkParts: fromUniWalkParts.map(part => ({
                  from: part.from_point_name,
                  to: part.to_point_name,
                  duration: part.duration
                })),
                busParts: fromUniBusParts.map(part => ({
                  from: part.from_point_name,
                  to: part.to_point_name,
                  service: part.service,
                  duration: part.duration
                }))
              }
            });

            // Get the route details
            const toUniScore = result.toUni.score || {};
            const fromUniScore = result.fromUni.score || {};

            console.log('Route Scores:', {
              toUniScore,
              fromUniScore
            });
            
            // Display outbound route
            await typeText('> Found potential routes:', 'info', 8);
            await typeText('');
            
            // Group outbound route information
            const outboundInfo = [
              '  TO UNIVERSITY:',
              `    Start: Your Location`
            ];

            // Track the current bus journey part
            let currentBusJourney = [];
            let lastMode = null;

            // Process each route part in sequence
            result.toUni.route_parts.forEach((part, index) => {
              if (part.mode === 'foot') {
                // If we have a bus journey to output, do it now
                if (currentBusJourney.length > 0) {
                  currentBusJourney.forEach(busSegment => {
                    outboundInfo.push(busSegment);
                  });
                  currentBusJourney = [];
                }
                
                // Add walking segment
                if (index === 0) {
                  outboundInfo.push(`    Walk to ${part.to_point_name === 'Journey Destination' ? 
                    'campus' : part.to_point_name} (${part.duration})`);
                } else if (index === result.toUni.route_parts.length - 1) {
                  outboundInfo.push(`    Walk to campus (${part.duration})`);
                } else {
                  outboundInfo.push(`    Walk to ${part.to_point_name} (${part.duration})`);
                }
              } else if (part.mode === 'bus') {
                // Add bus segment
                const busInfo = `    Bus ${part.service} (${part.duration}): ${part.from_point_name === 'Journey Origin' ? 
                  'your location' : part.from_point_name} → ${part.to_point_name === 'Journey Destination' ? 
                  'campus' : part.to_point_name}`;
                currentBusJourney.push(busInfo);
              }
              lastMode = part.mode;
            });

            // Output any remaining bus journey
            if (currentBusJourney.length > 0) {
              currentBusJourney.forEach(busSegment => {
                outboundInfo.push(busSegment);
              });
            }

            // Display outbound info with faster typing
            for (const line of outboundInfo) {
              await typeText(line, 'info', 15);
            }
            await typeText('');

            // Return journey
            const returnInfo = [
              '  FROM UNIVERSITY:',
              `    Start: Campus`
            ];

            // Reset for return journey
            currentBusJourney = [];
            lastMode = null;

            // Process each route part in sequence for return journey
            result.fromUni.route_parts.forEach((part, index) => {
              if (part.mode === 'foot') {
                // If we have a bus journey to output, do it now
                if (currentBusJourney.length > 0) {
                  currentBusJourney.forEach(busSegment => {
                    returnInfo.push(busSegment);
                  });
                  currentBusJourney = [];
                }
                
                // Add walking segment
                if (index === 0) {
                  const nextPart = result.fromUni.route_parts[index + 1];
                  const destination = nextPart && nextPart.mode === 'bus' ? nextPart.from_point_name : part.to_point_name;
                  returnInfo.push(`    Walk to ${destination} (${part.duration})`);
                } else if (index === result.fromUni.route_parts.length - 1) {
                  returnInfo.push(`    Walk to your location (${part.duration})`);
                } else {
                  const nextPart = result.fromUni.route_parts[index + 1];
                  const destination = nextPart && nextPart.mode === 'bus' ? nextPart.from_point_name : part.to_point_name;
                  returnInfo.push(`    Walk to ${destination} (${part.duration})`);
                }
              } else if (part.mode === 'bus') {
                // Add bus segment
                const busInfo = `    Bus ${part.service} (${part.duration}): ${part.from_point_name === 'Journey Origin' ? 
                  'campus' : part.from_point_name} → ${part.to_point_name === 'Journey Destination' ? 
                  'your location' : part.to_point_name}`;
                currentBusJourney.push(busInfo);
              }
              lastMode = part.mode;
            });

            // Output any remaining bus journey
            if (currentBusJourney.length > 0) {
              currentBusJourney.forEach(busSegment => {
                returnInfo.push(busSegment);
              });
            }

            // Display return info with faster typing
            for (const line of returnInfo) {
              await typeText(line, 'info', 15);
            }
            await typeText('');

            // Calculate combined score
            const totalScore = (toUniScore.score || 0) + (fromUniScore.score || 0);

            // Group and display scoring information
            const scoringInfo = [
              '  ROUTE SCORING:',
              '    TO UNIVERSITY:',
              `      Total Time: ${toUniScore.totalDuration || result.toUni.duration || 'Unknown'} minutes`,
              `      Walking Time: ${toUniScore.walkingTime || 'Unknown'} minutes`,
              `      Bus Time: ${toUniScore.busDuration || 'Unknown'} minutes`,
              `      Route Type: ${toUniScore.directRoute ? 'Direct' : 'Multiple Buses'}`,
              `      Score: ${toUniScore.score || 0}`,
              '',
              '    FROM UNIVERSITY:',
              `      Total Time: ${fromUniScore.totalDuration || result.fromUni.duration || 'Unknown'} minutes`,
              `      Walking Time: ${fromUniScore.walkingTime || 'Unknown'} minutes`,
              `      Bus Time: ${fromUniScore.busDuration || 'Unknown'} minutes`,
              `      Route Type: ${fromUniScore.directRoute ? 'Direct' : 'Multiple Buses'}`,
              `      Score: ${fromUniScore.score || 0}`,
              '',
              `    Combined Route Score: ${totalScore}`,
              ''
            ];

            // Display scoring info with consistent timing
            for (const line of scoringInfo) {
              await typeText(line, 'success', 12);
            }

            results.push({
              campus: campus.name,
              score: totalScore,
              toUni: result.toUni,
              fromUni: result.fromUni,
              outboundStop: toUniBusParts[0]?.from_point_name || 'Unknown stop',
              inboundStop: fromUniBusParts[fromUniBusParts.length - 1]?.from_point_name || 'Unknown stop',
              walkTime: parseInt((toUniWalkParts[0]?.duration || '0').replace(/[^0-9]/g, '')) || 0
            });

            console.log('Stored Results:', results);
          } else {
            await typeText(`> No valid route found for ${campus.name}`, 'error', 8);
            if (result.toUni?.rejected_reason) {
              await typeText(`> To Uni: ${result.toUni.rejected_reason}`, 'error', 8);
            }
            if (result.fromUni?.rejected_reason) {
              await typeText(`> From Uni: ${result.fromUni.rejected_reason}`, 'error', 8);
            }
          }
        } else {
          await typeText(`> Failed to process ${campus.name}`, 'error', 8);
          await typeText(`> Reason: No valid response from server`, 'error', 8);
        }
      } catch (error) {
        await typeText(`> Error processing ${campus.name}: ${error.message}`, 'error', 8);
        console.error('Error optimising route:', error);
        toast.error(`Failed to optimise route for ${campus.name}. Please try again.`);
      }
      await typeText('');
    }

    // Transform optimised results into campusBusStops format
    const campusBusStops = optimisedResults.map(result => {
      // Find the first bus part in the outbound journey (to uni)
      const firstBusPart = result.toUni.route_parts.find(part => part.mode === 'bus');
      // Find the first walking part (to get walking time to first bus stop)
      const firstWalkPart = result.toUni.route_parts.find(part => part.mode === 'foot');
      // Find the last bus part in the inbound journey (from uni)
      const lastBusPart = [...result.fromUni.route_parts].reverse().find(part => part.mode === 'bus');

      return {
        campus: result.campus,
        outboundStop: {
          name: firstBusPart ? firstBusPart.from_point_name : 'Unknown',
          location: {
            type: 'Point',
            coordinates: firstBusPart && firstBusPart.from_point && firstBusPart.from_point.place ? 
              [firstBusPart.from_point.place.longitude, firstBusPart.from_point.place.latitude] : 
              [0, 0]
          }
        },
        inboundStop: {
          name: lastBusPart ? lastBusPart.to_point_name : 'Unknown',
          location: {
            type: 'Point',
            coordinates: lastBusPart && lastBusPart.to_point && lastBusPart.to_point.place ?
              [lastBusPart.to_point.place.longitude, lastBusPart.to_point.place.latitude] :
              [0, 0]
          }
        },
        walkTime: firstWalkPart ? parseInt(firstWalkPart.duration.replace(/[^0-9]/g, '')) || 0 : 0
      };
    });

    // Final messages - fast speed (5ms)
    if (results.length > 0) {
      await typeText('$ Route optimisation complete', 'success', 5);
      await typeText(`> Found ${results.length} optimal routes`, 'success', 5);
      await typeText('');
      
      // Rankings - medium speed (15ms)
      await typeText('FINAL ROUTE RANKINGS:', 'success', 15);
      results
        .sort((a, b) => a.score - b.score)
        .forEach((result, index) => {
          typeText(`${index + 1}. ${result.campus} (Score: ${result.score})`, 'success', 15);
        });
      
      await typeText('> Saving results to memory...', 'info', 5);
      await typeText('> Done!', 'success', 5);
      setOptimizationStatus({
        optimised: true,
        currentCampus: '',
        completedCampuses: [],
      });
      console.log('Setting optimised results:', results);
      setOptimisedResults(results);
    } else {
      await typeText('$ No valid routes found', 'error', 10);
      await typeText('> Please try again with different parameters', 'error', 10);
      toast.error('No valid routes found for any campus. Please try again.');
    }
    setLoading(false);
  };

  const formatBusJourneyPart = (part, isReturn) => {
    const fromStop = part.from_point_name || 'Unknown';
    const toStop = part.to_point_name || 'Unknown';
    return `Bus ${part.service} (${part.duration}): ${fromStop} → ${toStop}`;
  };

  const handleConfirmRoute = () => {
    if (optimizationStatus.optimised) {
      setCurrentStep(4);
    }
  };

  const calculateDuration = (startTime, endTime) => {
    console.log('[calculateDuration] Input:', { startTime, endTime });
    
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [endHours, endMinutes] = endTime.split(':').map(Number);
    
    console.log('[calculateDuration] Parsed times:', {
      start: { hours: startHours, minutes: startMinutes },
      end: { hours: endHours, minutes: endMinutes }
    });
    
    let durationMinutes = (endHours * 60 + endMinutes) - (startHours * 60 + startMinutes);
    if (durationMinutes < 0) {
      console.log('[calculateDuration] Negative duration detected, adding 24 hours');
      durationMinutes += 24 * 60; // Handle case where end time is next day
    }
    
    console.log('[calculateDuration] Calculated duration:', durationMinutes, 'minutes');
    return durationMinutes;
  };

  const convertToUTC = (timeStr) => {
    console.log('[convertToUTC] Converting time:', timeStr);
    
    // Create a date object with a fixed date to avoid daylight saving issues
    const date = new Date('2025-01-12'); // Using a fixed date for consistency
    console.log('[convertToUTC] Using reference date:', date.toISOString());
    
    const [hours, minutes] = timeStr.split(':');
    date.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
    
    console.log('[convertToUTC] Local time set:', date.toLocaleTimeString());
    
    // Convert to UTC time string in HH:mm format
    const utcHours = date.getUTCHours().toString().padStart(2, '0');
    const utcMinutes = date.getUTCMinutes().toString().padStart(2, '0');
    const result = `${utcHours}:${utcMinutes}`;
    
    console.log('[convertToUTC] Converted to UTC:', result);
    return result;
  };

  const validateLectureTimes = (startTime, endTime) => {
    console.log('[validateLectureTimes] Validating times:', { startTime, endTime });
    
    if (!startTime || !endTime) {
      console.warn('[validateLectureTimes] Missing start or end time');
      return false;
    }

    const start = new Date(`1970-01-01T${startTime}`);
    const end = new Date(`1970-01-01T${endTime}`);
    
    console.log('[validateLectureTimes] Parsed dates:', {
      start: start.toLocaleTimeString(),
      end: end.toLocaleTimeString()
    });
    
    const isValid = end > start;
    console.log('[validateLectureTimes] Validation result:', isValid);
    return isValid;
  };

  const handleAddLecture = () => {
    console.log('[handleAddLecture] Starting with formData:', {
      startTime: formData.startTime,
      endTime: formData.endTime,
      lectureName: formData.lectureName,
      selectedDay: formData.selectedDay,
      selectedCampus: formData.selectedLectureCampus || formData.selectedCampuses[0]?.name
    });

    if (!validateLectureTimes(formData.startTime, formData.endTime)) {
      console.warn('[handleAddLecture] Invalid lecture times');
      toast.error('End time must be after start time');
      return;
    }

    // Get the campus - either selected or the only one available
    const campus = formData.selectedLectureCampus || formData.selectedCampuses[0]?.name;
    
    if (!campus) {
      console.warn('[handleAddLecture] No campus selected');
      toast.error('Please select a campus');
      return;
    }

    if (!formData.lectureName.trim()) {
      console.warn('[handleAddLecture] No lecture name provided');
      toast.error('Please enter a lecture name');
      return;
    }

    // Convert start time to UTC and calculate duration
    const utcStartTime = convertToUTC(formData.startTime);
    const duration = calculateDuration(formData.startTime, formData.endTime);

    console.log('[handleAddLecture] Converted times:', {
      localStartTime: formData.startTime,
      utcStartTime,
      duration
    });

    const newLecture = {
      name: formData.lectureName.trim(),
      campus: campus,
      day: formData.selectedDay,
      time: utcStartTime,
      duration: duration
    };

    console.log('[handleAddLecture] Created new lecture:', newLecture);
    // Keep the selected campus if there's only one
    const shouldKeepCampus = formData.selectedCampuses.length === 1;

    setFormData(prev => ({
      ...prev,
      lectures: [...prev.lectures, newLecture],
      // Only reset the campus if there are multiple campuses
      selectedLectureCampus: shouldKeepCampus ? prev.selectedLectureCampus : '',
      selectedDay: '',
      startTime: '',
      endTime: '',
      lectureName: '',
    }));

    // Show success message
    toast.success('Lecture added to schedule');
  };

  const handleRemoveLecture = (index) => {
    setFormData(prev => ({
      ...prev,
      lectures: prev.lectures.filter((lecture, i) => i !== index),
    }));
  };

  const handleTimetableUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('timetable', file);

    try {
      setLoading(true);
      const response = await axiosInstance.post('/api/timetable/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        setFormData(prev => ({
          ...prev,
          timetable: response.data.timetable,
        }));
        onSubmit(formData);
      }
    } catch (error) {
      console.error('Error uploading timetable:', error);
      toast.error('Failed to upload timetable. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleNext = async () => {
    if (currentStep === 4) {
      if (formData.lectures && formData.lectures.length > 0) {
        setCurrentStep(5);
        // Scroll to top when reaching the review step
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        toast.error('Please add at least one lecture');
      }
    } else if (currentStep === 5) {
      try {
        setLoading(true);
        
        // Validate timetable data before submission
        if (!formData.university || !formData.selectedCampuses.length || !formData.lectures.length || !formData.timetableName) {
          toast.error('Please fill in all required fields');
          return;
        }

        // Transform optimised results into campusBusStops format
        const campusBusStops = optimisedResults.map(result => {
          // Find the first bus part in the outbound journey (to uni)
          const firstBusPart = result.toUni.route_parts.find(part => part.mode === 'bus');
          // Find the first walking part (to get walking time to first bus stop)
          const firstWalkPart = result.toUni.route_parts.find(part => part.mode === 'foot');
          // Find the last bus part in the inbound journey (from uni)
          const lastBusPart = [...result.fromUni.route_parts].reverse().find(part => part.mode === 'bus');

          return {
            campus: result.campus,
            outboundStop: {
              name: firstBusPart ? firstBusPart.from_point_name : 'Unknown',
              location: {
                type: 'Point',
                coordinates: firstBusPart && firstBusPart.from_point && firstBusPart.from_point.place ? 
                [firstBusPart.from_point.place.longitude, firstBusPart.from_point.place.latitude] : 
                [0, 0]
              }
            },
            inboundStop: {
              name: lastBusPart ? lastBusPart.to_point_name : 'Unknown',
              location: {
                type: 'Point',
                coordinates: lastBusPart && lastBusPart.to_point && lastBusPart.to_point.place ?
                [lastBusPart.to_point.place.longitude, lastBusPart.to_point.place.latitude] :
                [0, 0]
              }
            },
            walkTime: firstWalkPart ? parseInt(firstWalkPart.duration.replace(/[^0-9]/g, '')) || 0 : 0
          };
        });

        const timetableData = {
          name: formData.timetableName,
          university: formData.university.value,
          campuses: formData.selectedCampuses.map(campus => campus.name),
          lectures: formData.lectures.map(lecture => ({
            name: lecture.name,
            campus: lecture.campus,
            day: lecture.day,
            time: lecture.time,
            duration: lecture.duration
          })),
          campusBusStops
        };

        console.log('Submitting timetable data:', JSON.stringify(timetableData, null, 2));
        const response = await axiosInstance.post('/api/timetable', timetableData);
        
        if (response.data && response.data.success) {
          toast.success('Timetable created successfully!');
          if (onSubmit) {
            onSubmit(response.data.timetable); // Pass the created timetable from response
            // Close the modal after successful submission
            onClose();
          }
          setCurrentStep(1); // Reset form
          setFormData({
            university: null,
            selectedCampuses: [],
            route: null,
            timetable: null,
            selectedLectureCampus: '',
            selectedDay: '',
            startTime: '',
            endTime: '',
            lectureName: '',
            lectures: [],
            timetableName: ''
          });
        } else {
          throw new Error('Failed to create timetable. Please try again.');
        }
      } catch (error) {
        console.error('Error creating timetable:', error);
        if (error.response?.data?.details) {
          const details = Object.entries(error.response.data.details)
            .map(([key, value]) => `${key}: ${value}`)
            .join('\n');
          toast.error(`Validation error:\n${details}`);
        } else {
          toast.error('An error occurred while creating the timetable.');
        }
      } finally {
        setLoading(false);
      }
    } else {
      setCurrentStep(prev => prev + 1);
    }
  };

  const canContinue = () => {
    switch (currentStep) {
      case 1:
        return formData.university !== '';
      case 2:
        return formData.selectedCampuses.length > 0;
      case 3:
        return optimizationStatus.optimised && optimisedResults && optimisedResults.length > 0;
      case 4:
        // Allow continuing if at least one lecture is added
        return formData.lectures && formData.lectures.length > 0;
      case 5:
        return formData.timetableName !== '';
      default:
        return false;
    }
  };

  const renderOptimizationStatus = () => {
    return (
      <div className="cli-container">
        <div className="cli-window">
          <div className="cli-header">
            <span className="cli-title">Two Stopper Route Optimiser</span>
            <div className="cli-buttons">
              <span className="cli-button"></span>
              <span className="cli-button"></span>
              <span className="cli-button"></span>
            </div>
          </div>
          <div className="cli-content" ref={cliContentRef}>
            {stepOutput.map((output, index) => (
              <div 
                key={index} 
                className={`cli-line ${output.type} ${output.incomplete ? 'typing' : ''}`}
              >
                {output.text}
                {index === stepOutput.length - 1 && !isTyping && (
                  <span className="cli-cursor">_</span>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const handleRouteSelect = (result, campus) => {
    setSelectedRoute({
      campus,
      toUni: result.toUni,
      fromUni: result.fromUni
    });
    
    // Move to the next step after selecting a route
    setCurrentStep(4);
    
    // Pre-fill the campus in the lecture form if it's not already set
    if (!formData.selectedLectureCampus) {
      setFormData(prev => ({
        ...prev,
        selectedLectureCampus: campus
      }));
    }
  };

  const renderJourneyOption = (result, campus) => {
    const { toUni, fromUni } = result;
    
    return (
      <div className="timetable-journey-option" onClick={() => handleRouteSelect(result, campus)}>
        {/* To University Journey */}
        <div className="journey-direction">
          <div className="journey-direction-header">
            <FaBus /> To {campus}
          </div>
          <div className="journey-parts">
            {toUni.route_parts.map((part, index) => (
              <div key={`to-${index}`} className="journey-part">
                <div className="journey-part-icon">
                  {part.mode === 'foot' ? <FaWalking /> : <FaBus />}
                </div>
                <div className="journey-part-details">
                  {part.mode === 'foot' ? (
                    index === 0 ? 
                      `Walk to ${part.to_point_name === 'Journey Destination' ? 
                        'campus' : part.to_point_name}` :
                      index === toUni.route_parts.length - 1 ?
                        `Walk to campus` :
                        `Walk to ${part.to_point_name}`
                  ) : (
                    `Bus ${part.service} (${part.duration}): ${part.from_point_name === 'Journey Origin' ? 
                      'your location' : part.from_point_name} → ${part.to_point_name === 'Journey Destination' ? 
                      'campus' : part.to_point_name}`
                  )}
                </div>
                <div className="journey-part-duration">{part.duration}</div>
              </div>
            ))}
          </div>
        </div>

        {/* From University Journey */}
        <div className="journey-direction">
          <div className="journey-direction-header">
            <FaBus /> From {campus}
          </div>
          <div className="journey-parts">
            {fromUni.route_parts.slice().reverse().map((part, index) => (
              <div key={`from-${index}`} className="journey-part">
                <div className="journey-part-icon">
                  {part.mode === 'foot' ? <FaWalking /> : <FaBus />}
                </div>
                <div className="journey-part-details">
                  {part.mode === 'foot' ? (
                    index === fromUni.route_parts.length - 1 ? 
                      `Walk to your location` :
                      // For return journey walking, use the next bus part's from_point_name
                      `Walk to ${fromUni.route_parts[index + 1]?.mode === 'bus' ? 
                        fromUni.route_parts[index + 1].from_point_name : part.to_point_name}`
                  ) : (
                    `Bus ${part.service}: ${part.from_point_name === 'Journey Origin' ? 
                      'campus' : part.from_point_name} → ${part.to_point_name === 'Journey Destination' ? 
                      'your location' : part.to_point_name}`
                  )}
                </div>
                <div className="journey-part-duration">{part.duration}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderOptimizedResults = () => {
    if (!optimisedResults || optimisedResults.length === 0) {
      return null;
    }

    return (
      <div className="journey-options-container">
        {optimisedResults.map((result, index) => (
          <div key={`journey-${index}`}>
            {renderJourneyOption(result, optimizationStatus.currentCampus)}
          </div>
        ))}
      </div>
    );
  };

  const renderCampusStep = () => {
    return (
      <>
        <div className="step-header fade-up-animation">
          <div className="step-icon">
            <FaMapMarkerAlt />
          </div>
          <h2>Select Your Campuses</h2>
          <p className="step-description">Choose the campuses you'll be attending</p>
        </div>

        <div className="campus-grid fade-up-animation" style={{ animationDelay: '0.1s' }}>
          {campuses.map((campus, index) => {
            const isSelected = formData.selectedCampuses.some(
              (selected) => selected.name === campus.name
            );
            return (
              <div
                key={campus.name}
                className={`campus-card fade-up-animation ${isSelected ? 'selected' : ''}`}
                onClick={() => handleCampusSelect(campus)}
                style={{ animationDelay: `${index * 0.1}s` }}
              >
                <div className="campus-icon">
                  <FaMapMarkerAlt />
                </div>
                <div className="campus-info">
                  <div className="campus-name">{campus.name}</div>
                  <div className="campus-address">{campus.address}</div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const renderLectureForm = () => {
    return (
      <div className="lecture-form-container">
        <div className="form-card">
          <h3>Lecture Details</h3>
          {formData.selectedCampuses.length === 1 ? (
            <div className="form-group">
              <label>Campus</label>
              <div className="locked-input">
                <FaMapMarkerAlt />
                <span>{formData.selectedCampuses[0].name}</span>
              </div>
            </div>
          ) : (
            <div className="form-group">
              <label>Campus</label>
              <div className="select-wrapper">
                <select
                  value={formData.selectedLectureCampus}
                  onChange={(e) => setFormData(prev => ({ ...prev, selectedLectureCampus: e.target.value }))}
                  className="form-input"
                >
                  <option value="">Select Campus</option>
                  {formData.selectedCampuses.map(campus => (
                    <option key={campus.name} value={campus.name}>
                      {campus.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}

          <div className="form-group">
            <label>Lecture Name</label>
            <input
              type="text"
              value={formData.lectureName}
              onChange={(e) => setFormData(prev => ({ ...prev, lectureName: e.target.value }))}
              className="form-input"
              placeholder="Business Law"
            />
          </div>

          <div className="form-group">
            <label>Day of Week</label>
            <div className="day-selector">
              {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].map((day) => (
                <button
                  key={day}
                  type="button"
                  className={`day-button ${formData.selectedDay === day ? 'selected' : ''}`}
                  onClick={() => setFormData(prev => ({ ...prev, selectedDay: day }))}
                >
                  {day.slice(0, 3)}
                </button>
              ))}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>Start Time</label>
              <div className="input-wrapper">
                <input
                  type="time"
                  value={formData.startTime}
                  onChange={(e) => setFormData(prev => ({ ...prev, startTime: e.target.value }))}
                  className="form-input"
                />
              </div>
            </div>

            <div className="form-group">
              <label>End Time</label>
              <div className="input-wrapper">
                <input
                  type="time"
                  value={formData.endTime}
                  onChange={(e) => setFormData(prev => ({ ...prev, endTime: e.target.value }))}
                  className="form-input"
                />
              </div>
            </div>
          </div>

          <button 
            onClick={handleAddLecture}
            disabled={
              (formData.selectedCampuses.length > 1 && !formData.selectedLectureCampus) || 
              !formData.selectedDay || 
              !formData.startTime || 
              !formData.endTime || 
              !formData.lectureName
            }
            className="add-lecture-button"
          >
            <span className="button-content">
              <FaCalendarAlt />
              Add to Schedule
            </span>
          </button>
        </div>

        <div className="lectures-card">
          <h3>Your Schedule</h3>
          {formData.lectures && formData.lectures.length > 0 ? (
            <>
              <div className="lectures-grid">
                {formData.lectures.map((lecture, index) => (
                  <div key={index} className="lecture-card">
                    <div className="lecture-card-header">
                      <span className="lecture-day">{lecture.day}</span>
                      <button 
                        className="remove-lecture"
                        onClick={() => handleRemoveLecture(index)}
                        aria-label="Remove lecture"
                      >
                        <IoClose />
                      </button>
                    </div>
                    <h4 className="lecture-name">{lecture.name}</h4>
                    <div className="lecture-details">
                      <div className="detail-item">
                        <FaMapMarkerAlt />
                        <span>{lecture.campus}</span>
                      </div>
                      <div className="detail-item">
                        <FaCalendarAlt />
                        <span>{lecture.time}</span>
                      </div>
                      <div className="detail-item">
                        <FaClock />
                        <span>{lecture.duration} minutes</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="button-group">
                <button
                  className="form-button primary"
                  onClick={() => {
                    if (formData.lectures.length > 0) {
                      setCurrentStep(5);
                    } else {
                      toast.error('Please add at least one lecture');
                    }
                  }}
                >
                  Continue to Review
                </button>
              </div>
            </>
          ) : (
            <div className="empty-schedule">
              <div className="empty-icon">
                <FaCalendarAlt />
              </div>
              <p>No lectures added yet</p>
              <span>Add your first lecture using the form above</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <div className="step-header">
              <div className="step-icon">
                <FaGraduationCap />
              </div>
              <h2>Select Your University</h2>
              <p className="step-description">Choose your university to get started</p>
            </div>

            <div className="university-grid">
              {universityOptions.map((university, index) => (
                <div
                  key={university.value}
                  className={`university-card fade-up-animation ${formData.university?.value === university.value ? 'selected' : ''}`}
                  onClick={() => handleUniversitySelect(university)}
                  style={{ animationDelay: `${index * 0.1}s` }}
                >
                  <div className="university-logo">
                    <img src={university.logo} alt={`${university.label} logo`} />
                  </div>
                  <div className="university-info">
                    <div className="university-name">{university.label}</div>
                    <div className="university-full-name">{university.fullName}</div>
                  </div>
                </div>
              ))}
            </div>
          </>
        );

      case 2:
        return renderCampusStep();

      case 3:
        return (
          <>
            <div className="step-header fade-up-animation">
              <div className="step-icon">
                <FaRoute />
              </div>
              <h2>Optimise Your Route</h2>
              <p className="step-description">Let us find the best route for you</p>
            </div>
            {!optimizationStatus.optimised ? (
              <>
                <div className="form-button-container">
                  {!loading ? (
                    <button
                      className="form-button primary"
                      onClick={handleOptimise}
                      disabled={formData.selectedCampuses.length === 0}
                    >
                      Find Optimised Route
                    </button>
                  ) : (
                    renderOptimizationStatus()
                  )}
                </div>
                {showInfoText && (
                  <div className="route-info-box">
                    <p>
                      This will find the best bus stops near your home address. Please note that route optimisation only works:
                    </p>
                    <ul>
                      <li>From your home address (using your current location)</li>
                      <li>During normal bus operating hours (6am - 8pm)</li>
                    </ul>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="journey-options-container">
                  {optimisedResults.map((result, index) => (
                    <div key={`journey-${index}`}>
                      {renderJourneyOption(result, optimizationStatus.currentCampus)}
                    </div>
                  ))}
                </div>
              </>
            )}
          </>
        );

      case 4:
        return (
          <>
            <div className="step-header">
              <div className="step-icon">
                <FaCalendarAlt />
              </div>
              <h2>Add Your Lectures</h2>
              <p className="step-description">Create your weekly schedule by adding your lectures</p>
            </div>

            {renderLectureForm()}
          </>
        );

      case 5:
        return (
          <>
            <div className="step-header">
              <div className="step-icon">
                <FaCalendarAlt />
              </div>
              <h2>Review Your Timetable</h2>
              <p className="step-description">Review and name your timetable</p>
            </div>

            <div className="review-form" ref={reviewFormRef}>
              <div className="timetable-name-field">
                <label htmlFor="timetableName">Timetable Name</label>
                <input
                  type="text"
                  id="timetableName"
                  value={formData.timetableName || ''}
                  onChange={(e) => setFormData(prev => ({ ...prev, timetableName: e.target.value }))}
                  placeholder="Enter a name for your timetable"
                  required
                />
              </div>

              <div className="review-section">
                <h3>Selected University</h3>
                <div className="review-item">
                  <img src={formData.university.logo} alt={formData.university.label} className="small-logo" />
                  <span>{formData.university.fullName}</span>
                </div>
              </div>

              <div className="review-section">
                <h3>Selected Campuses</h3>
                {formData.selectedCampuses.map((campus, index) => (
                  <div key={index} className="review-item">
                    <FaMapMarkerAlt />
                    <span>{campus.name}</span>
                  </div>
                ))}
              </div>

              <div className="review-section">
                <h3>Your Lectures</h3>
                {formData.lectures.map((lecture, index) => (
                  <div key={index} className="review-item">
                    <FaGraduationCap />
                    <span>{lecture.name} - {lecture.campus} - {lecture.day} {lecture.time} ({lecture.duration} minutes)</span>
                  </div>
                ))}
              </div>

              <div className="button-group">
                <button className="secondary-button" onClick={() => setCurrentStep(4)}>
                  Back
                </button>
                <button className="primary-button" onClick={onFormSubmit}>
                  Create Timetable
                </button>
              </div>
            </div>
          </>
        );

      default:
        return null;
    }
  };

  const parseDuration = (duration) => {
    if (!duration) return null;
    const match = duration.match(/(\d+)min/);
    return match ? parseInt(match[1]) : null;
  };

  const onFormSubmit = async () => {
    if (!formData.university) {
      console.warn('[onFormSubmit] No university selected');
      toast.error('Please select a university');
      return;
    }

    if (!formData.selectedCampuses || formData.selectedCampuses.length === 0) {
      console.warn('[onFormSubmit] No campuses selected');
      toast.error('Please select at least one campus');
      return;
    }

    if (!optimisedResults || optimisedResults.length === 0) {
      console.warn('[onFormSubmit] No optimised results available');
      toast.error('Please optimise your journey first');
      return;
    }

    try {
      console.log('[onFormSubmit] Preparing submission data');
      const submitData = prepareSubmitData();
      console.log('[onFormSubmit] Final submission data:', submitData);

      // Call either onSubmit or onSave callback
      if (onSubmit) {
        await onSubmit(submitData);
      } else if (onSave) {
        await onSave(submitData);
      }
      
      console.log('[onFormSubmit] Form submitted successfully');
      
      // Reset form state after successful submission
      setFormData({
        university: null,
        selectedCampuses: [],
        route: null,
        timetable: null,
        selectedLectureCampus: '',
        selectedDay: '',
        startTime: '',
        endTime: '',
        lectureName: '',
        lectures: [],
        timetableName: ''
      });
      setCurrentStep(1);
      
      // Close the modal if onClose is provided
      if (onClose) {
        onClose();
      }
    } catch (error) {
      console.error('[onFormSubmit] Error submitting form:', error);
      toast.error('Failed to submit timetable');
    }
  };

  const prepareSubmitData = () => {
    if (!formData.university) {
      toast.error('Please select a university');
      return null;
    }

    if (!formData.selectedCampuses || formData.selectedCampuses.length === 0) {
      toast.error('Please select at least one campus');
      return null;
    }

    return {
      name: formData.timetableName,
      university: formData.university.value,
      campuses: formData.selectedCampuses.map(campus => campus.name),
      lectures: formData.lectures.map(lecture => ({
        name: lecture.name,
        campus: lecture.campus,
        day: lecture.day,
        time: lecture.time,
        duration: lecture.duration
      }))
    };
  };

  const styles = {
    modalContent: {
      maxWidth: '800px',
      margin: '40px auto',
      padding: '20px',
      backgroundColor: '#fff',
      borderRadius: '10px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    },
    journeyItem: {
      marginBottom: '10px',
      padding: '8px',
      borderRadius: '4px',
      backgroundColor: '#f5f5f5',
      display: 'flex',
      flexDirection: 'column',
      gap: '4px'
    },
    itemLabel: {
      fontWeight: 'bold',
      color: '#2c3e50'
    },
    itemValue: {
      color: '#34495e'
    },
    journeyTimes: {
      display: 'flex',
      gap: '20px',
      fontSize: '0.9em',
      color: '#7f8c8d',
      marginTop: '4px'
    },
    button: {
      backgroundColor: '#4CAF50',
      color: '#fff',
      padding: '10px 20px',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
    },
  };

  const handleClose = () => {
    setCurrentStep(startOnLectures ? 3 : 1);
    setFormData({
      university: null,
      selectedCampuses: [],
      route: null,
      timetable: null,
      selectedLectureCampus: '',
      selectedDay: '',
      startTime: '',
      endTime: '',
      lectureName: '',
      lectures: [],
      timetableName: ''
    });
    onClose?.();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="timetable-modal"
      overlayClassName="ReactModal__Overlay"
      shouldCloseOnEsc={true}
      style={styles.modalContent}
      contentRef={(node) => (modalRef.current = node)}
    >
      <div className="modal-header">
        <button 
          type="button" 
          className="modal-close-button" 
          onClick={handleClose} 
          aria-label="Close modal"
        >
          <IoClose />
        </button>
      </div>
      {renderStepContent()}
      <div className="journey-modal-buttons">
        <div className="left-button">
          {currentStep === 1 ? (
            <button 
              type="button"
              className="form-button cancel"
              onClick={handleClose}
            >
              Cancel
            </button>
          ) : (
            <button 
              type="button"
              className="form-button neutral"
              onClick={() => setCurrentStep(prev => prev - 1)}
            >
              Back
            </button>
          )}
        </div>
        <div className="right-button">
          {currentStep <= 5 && (
            <button
              type="button"
              className="form-button primary"
              onClick={handleNext}
              disabled={!canContinue()}
            >
              {currentStep === 5 ? 'Save Timetable' : currentStep === 4 ? 'Review' : 'Next'}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default TimetableForm;