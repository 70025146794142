import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiUser, FiMail, FiPhone, FiChevronRight } from 'react-icons/fi';
import axiosInstance from './utils/axiosInstance';
import { toast } from 'react-toastify';
import styles from './Profile.module.css';
import LoadingScreen from './components/LoadingScreen';
import PageTransition from './components/PageTransition';
import { trackNavigation, trackFeature, trackError, trackInteraction } from './utils/analytics';

const Profile = () => {
  const [user, setUser] = useState({
    name: '',
    email: '',
    phone: '',
    settings: { notifications: false, theme: 'light' }
  });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    trackNavigation.pageView('Profile');
    const fetchUserProfile = async () => {
      try {
        const response = await axiosInstance.get('/api/user/profile');
        console.log('Profile response:', response.data); // Debug log
        
        if (response.data && response.data.email) {
          setUser(response.data);
          trackFeature.profileView();
        } else {
          console.warn('Invalid user data received:', response.data);
          toast.error('Failed to load profile data');
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
        trackError.apiError('profile/fetch', error.message);
        if (error.response?.status === 401) {
          navigate('/login');
        } else {
          toast.error('Failed to load profile data');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [navigate]);

  const getInitials = () => {
    const name = user?.name || '';
    if (!name) return 'U';
    const nameParts = name.split(' ');
    return nameParts.map(part => part.charAt(0)).join('').toUpperCase() || 'U';
  };

  const handleEditProfile = () => {
    trackInteraction.buttonClick('Edit Profile');
    navigate('/edit-profile');
  };

  return (
    <PageTransition isAuth={true}>
      <div className={styles.container}>
        {loading ? (
          <LoadingScreen message="Loading profile..." />
        ) : (
          <div className={styles.main}>
            {/* Hero Section */}
            <section className={styles.hero}>
              <div className={styles.heroBackground} />
              <div className={styles.heroContent}>
                <div className={styles.welcomeGroup}>
                  <h1 className={styles.title}>Profile<span className={styles.highlight}></span></h1>
                  <p className={styles.subtitle}>
                    Manage your personal information and account preferences
                  </p>
                </div>
              </div>
            </section>

            <div className={styles.profileContent}>
              <div className={styles.profileGroup}>
                <div className={styles.profileAvatar}>
                  <div className={styles.avatarCircle}>
                    {getInitials()}
                  </div>
                  <div className={styles.avatarInfo}>
                    <h2>{user.name || 'User'}</h2>
                    <p>Personal Account</p>
                  </div>
                </div>
                <button className={styles.editProfileBtn} onClick={handleEditProfile}>
                  Edit Profile
                </button>
              </div>

              <div className={styles.profileGroup}>
                <div className={styles.sectionTitle}>Personal Information</div>
                <div className={styles.profileItem}>
                  <div className={styles.itemContent}>
                    <div className={styles.itemIcon}>
                      <FiUser />
                    </div>
                    <div className={styles.itemDetails}>
                      <div className={styles.itemLabel}>Name</div>
                      <div className={styles.itemValue}>{user.name || 'Not set'}</div>
                    </div>
                  </div>
                  <FiChevronRight className={styles.chevronIcon} />
                </div>
                <div className={styles.profileItem}>
                  <div className={styles.itemContent}>
                    <div className={styles.itemIcon}>
                      <FiMail />
                    </div>
                    <div className={styles.itemDetails}>
                      <div className={styles.itemLabel}>Email</div>
                      <div className={styles.itemValue}>{user.email}</div>
                    </div>
                  </div>
                  <FiChevronRight className={styles.chevronIcon} />
                </div>
                <div className={styles.profileItem}>
                  <div className={styles.itemContent}>
                    <div className={styles.itemIcon}>
                      <FiPhone />
                    </div>
                    <div className={styles.itemDetails}>
                      <div className={styles.itemLabel}>Phone</div>
                      <div className={styles.itemValue}>{user.phone || 'Add phone number'}</div>
                    </div>
                  </div>
                  <FiChevronRight className={styles.chevronIcon} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </PageTransition>
  );
};

export default Profile;