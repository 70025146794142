import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { trackNavigation, trackInteraction } from '../utils/analytics';
import styles from './PublicNavbar.module.css';

const PublicNavbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isOverHero, setIsOverHero] = useState(true);
  const [isMinimized, setIsMinimized] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);
  const isLandingPage = location.pathname === '/';

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setIsMenuOpen(false);
      }
    };

    let lastScroll = window.scrollY;
    let ticking = false;

    const handleScroll = () => {
      const currentScroll = window.scrollY;
      
      if (!ticking) {
        window.requestAnimationFrame(() => {
          if (isLandingPage) {
            if (currentScroll < 100) {
              setShowNavbar(true);
              setIsMinimized(false);
            } else {
              setShowNavbar(false);
              setIsMinimized(true);
            }
          } else {
            if (currentScroll < 100) {
              setShowNavbar(true);
              setIsMinimized(false);
            } else {
              if (currentScroll > lastScroll) {
                setShowNavbar(false);
                setIsMinimized(true);
              } else {
                setShowNavbar(true);
                setIsMinimized(true);
              }
            }
          }
          lastScroll = currentScroll;
          ticking = false;
        });
      }
      ticking = true;
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    handleScroll(); // Initial check

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isLandingPage]);

  useEffect(() => {
    // Track when menu is opened/closed
    if (isMenuOpen) {
      trackInteraction.buttonClick('Open Mobile Menu');
    }
  }, [isMenuOpen]);

  const isActive = (path) => {
    return location.pathname === path ? styles.active : '';
  };

  const handleNavigation = (path, label) => {
    trackInteraction.buttonClick(`Nav: ${label}`);
    navigate(path);
    setIsMenuOpen(false);
  };

  const handleLoginClick = () => {
    trackInteraction.buttonClick('Nav: Login');
    navigate('/login');
    setIsMenuOpen(false);
  };

  const handleRegisterClick = () => {
    trackInteraction.buttonClick('Nav: Register');
    navigate('/register');
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    // Prevent body scroll when menu is open
    document.body.style.overflow = !isMenuOpen ? 'hidden' : '';
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    document.body.style.overflow = '';
  };

  return (
    <>
      {isMobile && (
        <button 
          className={`${styles.menu_button} ${isMenuOpen ? styles.menu_open : ''} ${isOverHero ? styles.over_hero : ''}`}
          onClick={toggleMenu}
          aria-label="Toggle menu"
        >
          <span className={styles.menu_icon}></span>
        </button>
      )}
      
      <nav className={`
        ${styles.navbar_container} 
        ${isMenuOpen ? styles.mobile_show : ''} 
        ${!isMobile && ((isLandingPage && !showNavbar) || (!isLandingPage && isMinimized)) ? styles.desktop_minimized : ''} 
        ${!isMobile && ((isLandingPage && showNavbar) || (!isLandingPage && showNavbar)) ? styles.desktop_show : ''}
      `}>
        <div className={styles.navbar_inner}>
          <Link 
            to="/" 
            className={styles.logo}
            onClick={() => trackInteraction.buttonClick('Nav: Logo')}
          >
            Two Stopper
          </Link>
          <ul className={styles.nav_list}>
            <li className={styles.nav_item}>
              <Link to="/" className={`${styles.nav_link} ${isActive('/')}`} onClick={() => { closeMenu(); trackInteraction.buttonClick('Nav: Home'); }}>
                Home
              </Link>
            </li>
            <li className={styles.nav_item}>
              <Link to="/timetables" className={`${styles.nav_link} ${isActive('/timetables')}`} onClick={() => { closeMenu(); trackInteraction.buttonClick('Nav: Timetables'); }}>
                Timetables
              </Link>
            </li>
            <li className={styles.nav_item}>
              <Link to="/bus-alerts" className={`${styles.nav_link} ${isActive('/bus-alerts')}`} onClick={() => { closeMenu(); trackInteraction.buttonClick('Nav: Bus Alerts'); }}>
                Bus Alerts
              </Link>
            </li>
            <li className={styles.nav_item}>
              <Link to="/pricing" className={`${styles.nav_link} ${isActive('/pricing')}`} onClick={() => { closeMenu(); trackInteraction.buttonClick('Nav: Pricing'); }}>
                Pricing
              </Link>
            </li>
            <li className={styles.nav_item}>
              <Link to="/about" className={`${styles.nav_link} ${isActive('/about')}`} onClick={() => { closeMenu(); trackInteraction.buttonClick('Nav: About'); }}>
                About
              </Link>
            </li>
            <li className={styles.nav_item}>
              <Link to="/contact" className={`${styles.nav_link} ${isActive('/contact')}`} onClick={() => { closeMenu(); trackInteraction.buttonClick('Nav: Contact'); }}>
                Contact
              </Link>
            </li>
          </ul>
          <div className={styles.auth_buttons}>
            <button onClick={() => { 
              if (isAuthenticated) {
                navigate('/home');
              } else {
                navigate('/login');
              }
              closeMenu(); 
              trackInteraction.buttonClick('Nav: Sign In');
            }} className={styles.sign_in_button}>
              Sign In
            </button>
            <button onClick={() => { navigate('/register'); closeMenu(); trackInteraction.buttonClick('Nav: Register'); }} className={styles.register_button}>
              Register
            </button>
          </div>
        </div>
      </nav>
    </>
  );
};

export default PublicNavbar;
